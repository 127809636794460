import React from "react";
import { Box, IconButton } from "@mui/material";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";

const SocialMediaIcons = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        gap: 2,
        backgroundColor: 'rgb(247, 245, 255)',
        padding: '10px',
        borderTop: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        maxWidth: '1100px',
        width: '100%',
        margin: '0 auto'
      }}
    >
      <IconButton href="https://www.facebook.com/people/Restaverse/100095145087012/" target="_blank" sx={{ color: 'black' }}>
        <Facebook />
      </IconButton>
      <IconButton href="https://www.linkedin.com/company/restaverse?originalSubdomain=in" target="_blank" sx={{ color: 'black' }}>
        <LinkedIn />
      </IconButton>
      <IconButton href="https://www.instagram.com/restaverse.in/" target="_blank" sx={{ color: 'black' }}>
        <Instagram />
      </IconButton>
    </Box>
  );
};

export default SocialMediaIcons;
