import PageTitle from "../../Components/PageTitle/pagetitles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faPlay,
  faHouseSignal,
  faHouseLock,
  faMobile,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import useTheme from "@mui/system/useTheme";
import { useMediaQuery } from "@mui/material";
import { Container, Grid, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
// import { Tooltip } from "react-tooltip";
import CToolTip from "../../Components/Tooltip/ctooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/material";
import { useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import styles from "./services.css";
import map from "../../images/services/map.webp";

import { useEffect } from "react";
import SuceessStory from "../../Components/SuccessStory/successstory";
import { StyledButton } from "../home2/home2";
import { Link } from "react-router-dom";
import {
  faLink,
  faRankingStar,
  faGroupArrowsRotate,
  faMagnifyingGlassChart,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
// import MyComponent from "../../Components/map/map";
import ServiceCarousel from "../../Components/servicecarousel/servicecarousel";
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { blue } from "@mui/material/colors";

const svgPaths = {
  one: "M353.8 54.1L330.2 6.3c-3.9-8.3-16.1-8.6-20.4 0L286.2 54.1l-52.3 7.5c-9.3 1.4-13.3 12.9-6.4 19.8l38 37-9 52.1c-1.4 9.3 8.2 16.5 16.8 12.2l46.9-24.8 46.6 24.4c8.6 4.3 18.3-2.9 16.8-12.2l-9-52.1 38-36.6c6.8-6.8 2.9-18.3-6.4-19.8l-52.3-7.5zM256 256c-17.7 0-32 14.3-32 32l0 192c0 17.7 14.3 32 32 32l128 0c17.7 0 32-14.3 32-32l0-192c0-17.7-14.3-32-32-32l-128 0zM32 320c-17.7 0-32 14.3-32 32L0 480c0 17.7 14.3 32 32 32l128 0c17.7 0 32-14.3 32-32l0-128c0-17.7-14.3-32-32-32L32 320zm416 96l0 64c0 17.7 14.3 32 32 32l128 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-128 0c-17.7 0-32 14.3-32 32z",
  two: "M64 32C28.7 32 0 60.7 0 96l0 64c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-64c0-35.3-28.7-64-64-64L64 32zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64l0 64c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-64c0-35.3-28.7-64-64-64L64 288zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z", // Add the actual path
  three: "M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z", // Add the actual path
  four: "M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z", // Add the actual path
  five: "M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z", // Add the actual path
  six: "M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c0 0 0 0 0 0s0 0 0 0s0 0 0 0c0 0 0 0 0 0l.3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z", // Add the actual path
  seven:"M128 0C110.3 0 96 14.3 96 32l0 192 96 0 0-32c0-35.3 28.7-64 64-64l224 0 0-96c0-17.7-14.3-32-32-32L128 0zM256 160c-17.7 0-32 14.3-32 32l0 32 96 0c35.3 0 64 28.7 64 64l0 128 192 0c17.7 0 32-14.3 32-32l0-192c0-17.7-14.3-32-32-32l-320 0zm240 64l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zM64 256c-17.7 0-32 14.3-32 32l0 13L187.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L352 301l0-13c0-17.7-14.3-32-32-32L64 256zm288 84.8L216 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L32 340.8 32 480c0 17.7 14.3 32 32 32l256 0c17.7 0 32-14.3 32-32l0-139.2z" ,
  eight:"M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm64 192c17.7 0 32 14.3 32 32l0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 192c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-192zM320 288c17.7 0 32 14.3 32 32l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32z" ,
  nine: "M128 0C110.3 0 96 14.3 96 32l0 192 96 0 0-32c0-35.3 28.7-64 64-64l224 0 0-96c0-17.7-14.3-32-32-32L128 0zM256 160c-17.7 0-32 14.3-32 32l0 32 96 0c35.3 0 64 28.7 64 64l0 128 192 0c17.7 0 32-14.3 32-32l0-192c0-17.7-14.3-32-32-32l-320 0zm240 64l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16zM64 256c-17.7 0-32 14.3-32 32l0 13L187.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L352 301l0-13c0-17.7-14.3-32-32-32L64 256zm288 84.8L216 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L32 340.8 32 480c0 17.7 14.3 32 32 32l256 0c17.7 0 32-14.3 32-32l0-139.2z" ,
};  

const renderSVG = (path, style) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    style={{ marginLeft: "10px", fill: "#FFFFFF", marginTop: "5px",...style}}
  >
    <path d={path} />
  </svg>
);

const CareerCard = ({ title, servicelogo,style,description, isSmallScreen }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const cardStyle = {
    padding: "16px",
    borderRadius: "12px",
    boxShadow: "none",
    backgroundColor: !isSmallScreen && isHovered ? "#fff" : "#F7F5FF",
    transition: "background-color 0.3s ease-in-out",
    padding: "0px",
    ...(isSmallScreen && { padding: "0px" }),
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardContent style={{ ...(isSmallScreen && { padding: "0px" }) }}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <div
                style={{
                  width: "90px",
                  height: "85px",
                  padding: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#6c41ff",
                  marginLeft: "140px",
                  justifyContent: "center"
                }}
              >
                {renderSVG(servicelogo, style)}
              </div>
            </Grid>
          </Grid>
          <br />
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{
              paddingBottom: "8px",
              fontWeight: "bold",
              fontSize: "1.7rem",
              fontFamily: "Poppins , sans-serif",
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body1"
            paragraph
            align="centre"
            style={{
              paddingBottom: "16px",
              color: "#506690",
              fontFamily: "Poppins , sans-serif",
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};


const OnloadAnimate = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return <div ref={ref}>{inView && children}</div>;
};

const AnimatedNumber = ({ end, duration, unit, isSmallScreen }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        color="primary"
        style={{
          color: "black",
          fontWeight: "600",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        {inView && <CountUp end={end} duration={duration} />}
        {unit}
      </Typography>
    </div>
  );
};

const Services = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreentwo = useMediaQuery('(max-width: 700px)');
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    document.title = "Services | Restaverse";
    window.scrollTo(0, 0);
  }, []);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };
  return (
    <div className="services-main">
      <div className="services-title">
        <PageTitle title={"Our Services"} />
      </div>
      <div>
        <OnloadAnimate>
          <section className="career-body p-5 services-each-main">
            <div className="container-lg">
              <div className="row">
                <div className="col-sm-12 col-xs-12 col mx-auto text-center service-section2">
                  <Typography
                    className="animate-from-top "
                    variant="h2"
                    style={{
                      color: "4F4F4F",
                      fontWeight: "bold",
                      marginBottom: theme.spacing(3),
                      fontFamily: "Poppins, sans-serif",
                      textAlign: isSmallScreen ? "centre" : "centre",
                      margin: "0 auto",
                      fontSize: "2rem",
                      width: isSmallScreen ? "100%" : "60%",
                    }}
                  >
                   Elevate your restaurant's digital journey
                  </Typography>

                  {/* <h2
                    className="services-section2-header animate-from-top"
                    style={{ fontSize: "2rem", fontWeight: "700" }}
                  >
                    Elevate Your Hospitality Journey
                  </h2> */}
                  {/* <br></br> */}
                  <br></br>
                  <Typography
                    className="animate-from-top "
                    // variant="h2"
                    style={{
                      color: "4F4F4F",
                      // fontWeight: "bold",
                      marginBottom: theme.spacing(3),
                      fontFamily: "Poppins, sans-serif",
                      textAlign: "centre",
                      margin: "0 auto",
                      fontSize: "1rem",
                      width: isSmallScreen ? "100%" : "60%",
                    }}
                  >
                    We'll help you save and leverage your time with Restaverse's AI-infused tools
                  </Typography>

                  {/* <p className="services-section2-desc" style={{}}>
                    Discover the pinnacle of restaurant management with
                    Restaverse's AI-infused tools.
                  </p> */}
                  <br></br>
                  <br></br>

                  <div>
                    {isSmallScreentwo ? 
                    (<ServiceCarousel isSmallScreentwo={isSmallScreentwo} />
                    ):
                    (<div className="career-section3-cards">
                      <Container
                        style={{ paddingLeft: "0px", paddingRight: "0px" }}
                      >
                        <Grid container spacing={0}>
                          {[
                            {
                              title: "Review Management",
                              servicelogo: svgPaths.one,
                              style: { marginLeft: "2px", marginTop:"-2px" },
                              description:
                                "Centralizes customer reviews from multiple platforms (Google, TripAdvisor, Zomato, etc.) to give a unified customer sentiment view.",
                            },
                            {
                              title: "Automated Reporting",
                              servicelogo: svgPaths.two,
                              description:
                                "We help you with exhaustive summaries for several business ops metrics that are pre-defined and hit your mailbox every morning.",
                            },
                            {
                              title: "Menu Management",
                              servicelogo: svgPaths.three,
                              description:
                                " Tracks item stockouts to prevent revenue loss due to unavailable dishes.",
                            },
                            {
                              title: "Integration Mastery",
                              servicelogo: svgPaths.four,
                              style: { marginLeft: "-2px",marginTop:"-2px"},
                              description:
                                "Integrates with over 20 platforms (POS, CRM, digital marketing, reservation, etc.) to correlate data and identify trends.",
                            },
                            {
                              title: "Store wise Scorecard",
                              servicelogo: svgPaths.five,
                              description:
                                "Uses gamification to rank store performance and reward the top performers.",
                            },
                            {
                              title: "Complaints Management",
                              servicelogo: svgPaths.six,
                              description:
                                "Enables tracking and resolution of customer complaints across both online and offline channels, including issuing refunds and gratifications.",
                            },
                            {
                              title: "Marketing Suggestions",
                              servicelogo: svgPaths.seven,
                              description:
                                "Provides directional suggestions for online advertising and offers to improve performance.",
                            },
                            {
                              title: "Visual Analytics",
                              servicelogo: svgPaths.eight,                          
                              description:
                                "Visualise & make your own DIY tools across the channels to arrive at faster conclusion on insights and next steps.",
                            },
                            {
                              title: "Revenue Forecasting",
                              servicelogo: svgPaths.nine,
                              description:
                                "Use historic data sets to predict the future revenues.",
                            },
                          ].map((job, index) => (
                            <CareerCard
                              key={index}
                              {...job}
                              isSmallScreen={isSmallScreen}
                            />
                          ))}
                        </Grid>
                      </Container>
                    </div>)}
                  </div>

                  
                </div>
              </div>
            </div>
          </section>
        </OnloadAnimate>
      </div>
      <div>
        <OnloadAnimate>
          {/* <section className="career-body">

            <div className="container-lg section-container"> */}
          <section className="career-body p-xs-3 p-md-5 services-each-main">
            <div className="container-lg">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-xs-12 col-lg-6 services-section2">
                  <div className="center-the-container">
                    <h2
                      className="career-section2-left-header animate-from-top"
                      style={{
                        fontSize: isSmallScreen ? "2rem" : "2rem",
                        fontFamily: "Poppins , sans-serif",
                      }}
                    >
                      What makes us stand out ?
                    </h2>
                    <br></br>
                    <div
                      className="image-and-text-container"
                      style={{
                        display: "flex",
                        fontSize: "",
                        color: "#506690",
                        fontFamily: "Poppins , sans-serif",
                      }}
                    >
                      <div>
                        <p className="fade-in-slow">
                          We suggest and execute real fast. We hustle to get
                          things done from all stakeholders, so you can focus on
                          the bigger picture.
                        </p>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            md={4}
                            style={{
                              ...(isSmallScreen && { textAlign: "center" }),
                            }}
                          >
                            <AnimatedNumber
                              end={300}
                              duration={2}
                              unit={" Cr+"}
                              isSmallScreen={isSmallScreen}
                            />
                            <Typography
                              variant="body1"
                              style={{ fontFamily: "Poppins , sans-serif" }}
                            >
                              Ad Spend Managed
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            style={{
                              paddingLeft:'40px',
                              ...(isSmallScreen && { textAlign: "center" }),
                            }}
                          >
                            {/* <div style={{ textAlign: "center" }}> */}
                              <AnimatedNumber
                                end={18}
                                duration={2}
                                unit={"+"}
                                isSmallScreen={isSmallScreen}
                              />
                              <Typography
                                variant="body1"
                                style={{ fontFamily: "Poppins , sans-serif" }}
                              >
                                Cities
                              </Typography>
                            {/* </div> */}
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            sm={5}
                            md={5}
                            style={{
                              ...(isSmallScreen && { textAlign: "center" }),
                            }}
                          >
                            <AnimatedNumber
                              end={42}
                              duration={2}
                              unit={"%"}
                              isSmallScreen={isSmallScreen}
                            />
                            <Typography
                              variant="body1"
                              style={{ fontFamily: "Poppins , sans-serif" }}
                            >
                              CAGR
                            </Typography>
                          </Grid>
                        </Grid>

                        <br></br>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={5}>
                            <StyledButton
                              component={Link}
                              to="/contact-us"
                              style={{ color: "white" }}
                            >
                              Join Waitlist
                            </StyledButton>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12   pb-3 col-md-12 col-xs-12 col-lg-6 aligh-item-center services-section2">
                  <div className="aligh-item-center">
                    <div>
                      <img
                        className="img-fluid"
                        src={map}
                        alt=""
                        style={{
                          objectFit: "cover",
                          height: "auto",
                          width: "100%",

                          position: "static",
                          // bottom: `max(-30vw, -300vh)`,
                          // opacity: "0.7",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </OnloadAnimate>
      </div>
      <div>
        <div className="container-lg">
          <SuceessStory
            titletext="Be ahead of the curve"
            desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
            buttontext="Join Waitlist"
            redirect="/contact-us"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
