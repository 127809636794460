import React from 'react'
import './challenge.css';

const Challenge = () => {
  return (
    <div className="challenge">
      <div>
        <h3> Challenge</h3>
        <hr></hr>
      </div>
      <div className="main-section-challenge">
        <div className="left-challenge">
          Elevating Organic Rankings on Aggregator Platforms
        </div>

        <div>
          Persian Darbar faced a unique challenge within the competitive
          landscape of food delivery. Their expertly crafted appetizers and
          tikkas, significant to their culinary identity, were not reaching
          their sales potential on popular aggregator platforms, leading to a
          crucial segment of their menu being consistently overlooked.
        </div>
      </div>
    </div>
  );
}

export default Challenge