import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import fortyone from '../../images/blogs/41.png';
import fortytwo from '../../images/blogs/41.png';
 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
const Blogtwentyone = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Beyond the Plate: How Packaging Speaks to Your Customers Before They Even Take a Bite
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Urmi Dangi"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/urmi-dangi-5a549b255/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Urmi Dangi</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Oct 08, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Excitement Starts with the Box! Your food is the star, but packaging is the opening act that sets the tone. <a href='https://www.epsilon.com/us/about-us/pressroom/better-to-be-loved-than-needed-when-driving-consumer-engagement-epsilon-study-finds' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>73%</a> of the purchase decisions of customers are influenced by product packaging. From the colors to the design, it can turn ordinary takeout into something special, amplifying your brand and leaving customers eager for more.
                                <br></br><br></br>
                                Let’s dive into how creative, eye-catching packaging can turn heads, boost your brand, and leave customers thrilled for what’s inside!
                                <br></br><br></br>
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={fortyone}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <ol>
                                    <li>
                                        <p>First Impressions Matter
                                            The moment your food arrives, your packaging speaks volumes about who you are as a restaurant. It’s the introduction to your restaurant’s story, setting the tone for what’s inside. Whether you’re a chic fine dining spot or a laid-back eatery, your packaging should reflect the experience you promise. Investing in visually appealing, high-quality materials conveys a sense of care and professionalism. It shows that you pay attention to every detail, even the container your food arrives in.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Branding Opportunity
                                            Think of your packaging as a powerful marketing tool that extends beyond just delivering food.
                                            <br></br>
                                            <ul>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>It’s perfect for showcasing your logo, brand colors, and tagline,</b> creating a recognizable and unified brand image.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>A well-designed, eco-friendly container</b> enhances the unboxing experience, making it feel uniquely tailored to your restaurant.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Tailor the design</b> to reflect your brand—vibrant for casual dining or sleek for fine dining.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Packaging tells your brand’s story,</b> deepening customer connections.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>It’s not just a container—</b>it’s free marketing as customers carry it, sparking curiosity and drawing attention.
                                                    </p>
                                                </li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Sustainability Speaks Volumes <br></br>
                                            With customers increasingly prioritizing eco-friendliness, sustainable packaging can give your restaurant a competitive edge. <a href='https://www.epsilon.com/us/about-us/pressroom/better-to-be-loved-than-needed-when-driving-consumer-engagement-epsilon-study-finds' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>66%</a> of consumers are willing to pay more for sustainable brands. By using compostable or biodegradable materials, you not only reduce your carbon footprint but also show customers that you care about the environment. This commitment <b style={{fontWeight:500}}>enhances your reputation and fosters trust,</b> particularly among the growing number of eco-conscious diners. Sustainable packaging isn’t just responsible; it’s a smart way to connect with customers who value sustainability in their dining choices.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Crafting Memorable Experiences through Packaging <br></br>
                                            Packaging has the power to evoke emotions and craft unforgettable experiences. <a href='https://www.epsilon.com/us/about-us/pressroom/better-to-be-loved-than-needed-when-driving-consumer-engagement-epsilon-study-finds' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>80% of consumers</a> are more likely to make a purchase when brands offer personalized experiences. When a customer opens a thoughtfully wrapped package, it ignites the same thrill as unwrapping a gift. <b style={{fontWeight:500}}>Adding personal touches—like a handwritten note or a loyalty card—</b>can elevate the experience, making it feel uniquely special. These thoughtful details, though small, can cultivate customer loyalty and appreciation, demonstrating that your restaurant is committed to delivering a truly memorable dining experience.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                            A Social Media Sensation   <br></br>
                                            In the age of social media, standout packaging can turn your customers into brand ambassadors.
                                            <br></br>
                                            <ul>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Attractive packaging becomes a visual focal point,</b> enticing diners to snap photos and share their experiences online.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Packaging influences perception,</b> making consumers believe the product is worth trying.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Every detail counts—</b>whether it’s a beautifully designed box or an eye-catching label, it can spark user-generated content.
                                                    .</p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p>By <b style={{fontWeight:500}}>creating packaging that stands out,</b> you’re not just delivering food—you’re crafting a shareable experience.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Each order becomes an opportunity</b> for organic marketing and deeper customer connection.
                                                    </p>
                                                </li>
                                            </ul>
                                        </p>
                                    </li>
                                </ol>

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={fortytwo}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                             CONCLUSION
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                It’s time to think of your packaging as an extension of your restaurant’s brand. It’s more than just a way to hold food—it’s a powerful tool that tells your story, reinforces your values, and enhances the overall customer experience. So, how will you let your packaging speak for your brand?
                                <br></br><br></br>
                                Partner with <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse</a> to unlock valuable insights and innovative strategies that will enhance your brand and boost customer loyalty. Let’s transform the dining experience together—one memorable delivery at a time!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogtwentyone;