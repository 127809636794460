import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import twentyfive from '../../images/blogs/25.png';
import twentysix from '../../images/blogs/26.png';
const Blogthirteen = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            One Screen to Rule Them All: Centralized Aggregator Management
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Shiksha Chauhan"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/shikshachauhan/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Shiksha Chauhan</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Sep 10, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Imagine running a restaurant where managing delivery platforms feels like being pulled in every direction at once—menus to update, orders streaming in from all sides, and reviews demanding attention from every corner. This nonstop scramble can leave restaurant owners and managers stretched thin, pulling them away from what matters most: delivering exceptional guest experiences, crafting the perfect menu, and improving internal operations.
                                <br></br><br></br>
                                What if I told you there’s a way to manage all your delivery platforms from a single screen? You’d probably think, “No way, that can’t be real.” Centralized aggregator management might seem like a far-fetched idea, but for restaurants ready to simplify the chaos, it’s quickly proving to be the real deal—transforming operations in ways you never imagined.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentyfive}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Why Your Restaurant Needs Centralized Aggregator Management
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <Typography sx={{
                                    textAlign: 'justify',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                    marginLeft: '10px'
                                }}>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        margin: 0,

                                    }}>
                                        <li style={{ listStyleType: 'square' }}><b style={{fontWeight:500}}>Efficiency and customer satisfaction are non-negotiable</b> in the fast-paced restaurant industry. </li>
                                        <li style={{ listStyleType: 'square' }}>Centralized aggregator management simplifies operations, allowing you to focus on delivering outstanding dining experiences and growing your business.</li>
                                        <li style={{ listStyleType: 'square' }}><b style={{fontWeight:500}}>Centralized management is no longer a luxury—it’s essential</b> for modern restaurants. </li>
                                        <li style={{ listStyleType: 'square' }}>Restaurants that don’t streamline their delivery processes risk falling behind competitors who do.</li>
                                        <li style={{ listStyleType: 'square' }}>In a market where customers demand <b style={{fontWeight:500}}>swift, accurate, and seamless service,</b> having the <b style={{fontWeight:500}}>right tools is crucial</b> for meeting and exceeding expectations.</li>
                                    </ul>
                                </Typography>
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <br></br><br></br>
                                Centralized Aggregator Management: Simplifying Your Restaurant’s Digital Life
                                <br></br>
                                A centralized platform brings all your delivery and management needs into one cohesive system, cutting out the hassle of switching between multiple apps. Here’s how it revolutionizes your operations:

                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Unified Menus
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Streamline your menu updates with ease. With a centralized system, you only need to make one update to synchronize your menu across all platforms instantly. This not only saves time but also ensures that your menu is consistent and accurate everywhere it’s listed. Enjoy the efficiency of having all your menu changes reflected in real-time, saving you time and reducing errors.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentysix}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Streamlined Orders
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Consolidate all your delivery orders into one easy-to-use interface. Whether they come from Uber Eats, DoorDash, or another platform, they’re all managed from a <b style={{fontWeight:500}}>single dashboard.</b> This centralization <b style={{fontWeight:500}}>minimizes errors, speeds up order processing,</b> and ensures quicker delivery times. With all orders in one place, you can manage them more efficiently, reducing confusion and boosting overall service quality. Enjoy a more organized and responsive order management system.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',
                                    maxWidth: '550px',
                                    height: 'auto',
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                             See the difference a unified approach can make.  Explore Restaverse! Our platform integrates your orders and reviews into one streamlined system, helping you deliver better service and grow your business with ease.
                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Centralized Reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Handle all your customer reviews from a single, unified dashboard. Instead of switching between various platforms, view and respond to feedback from every service in one place. This streamlined approach helps you stay on top of customer opinions, engage promptly, and address concerns effectively. By consolidating your review management, you can boost your online reputation, streamline your response process, and foster better customer relationships with ease.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4.Comprehensive Analytics
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Access a full spectrum of insights into your restaurant's performance with a centralized analytics platform. Forget about fragmented data from various platforms; instead, access a single dashboard that brings together <b style={{fontWeight:500}}>revenue metrics, peak order times, and delivery efficiency.</b> This integrated approach helps you make informed decisions, optimize operations, and identify trends to boost your business. Enjoy seamless insights and strategic clarity without the hassle of juggling multiple data sources.
                            </Typography>
                           
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <br></br><br></br>
                                If you're ready to end the chaos of managing multiple platforms and streamline your operations, centralized aggregator management offers a powerful solution. By consolidating all operations into one intuitive system, you can streamline processes, save valuable time, reduce errors, and enhance customer satisfaction, all while boosting your restaurant’s online visibility. Adopting this technology results in a more streamlined, organized, and thriving operation.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogthirteen;