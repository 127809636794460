import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { StyledButton } from "../../Pages/home2/home2.js";

const SuceessStory = ({ titletext, desctext, buttontext, redirect }) => (
  <section className="join-team-section">
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      textAlign="left"
      padding={{ xs: 3, mdx: 10 }}
      // sx={{
      //   paddingX: { },
      // }}
    >
      <Grid item xs={12} md={8}>
        <Typography
          variant="h3"
          gutterBottom
          style={{ fontFamily: 'Poppins , sans-serif', color: "white" }}
        >
          {titletext}
        </Typography>
        <Typography
          paragraph
          style={{fontFamily: 'Poppins , sans-serif', color: "white" }}
        >
          {desctext}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} sx={{display:'flex' }}>
        <StyledButton
          variant="outlined"
          component={Link}
          to={redirect}
          style={{ color: "white",marginLeft: "auto",  }}
        >
          {buttontext}
        </StyledButton>
      </Grid>
    </Grid>
  </section>

  //   <section className="join-team-section">
  //   <Box
  //     display="flex"
  //     flexDirection={{ xs: "column", md: "row" }}
  //     alignItems="center"
  //     justifyContent="space-around"
  //     color="#fff"
  //     p={{ xs: 3, md: 10 }}
  //     marginTop={{ xs: 0, md: 0 }}

  //   >
  //     <div>
  //       <Typography
  //         variant="h3"
  //         gutterBottom
  //         style={{ fontFamily: "'Sen', sans-serif" }}
  //       >
  //         Join our amazing team now
  //       </Typography>
  //       <Typography paragraph style={{ fontFamily: "'Sen', sans-serif" }}>
  //       Restaverse is looking for like-minded individuals. We prefer both humans and aliens 👽
  //       </Typography>
  //     </div>
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       style={{ padding: "15px 30px", fontFamily: "'Sen', sans-serif",textTransform:'none',backgroundColor:'#6C41FF' }} // Adjust the padding values as needed

  //     >
  //       Join the team
  //     </Button>
  //   </Box>
  // </section>
);

export default SuceessStory;
