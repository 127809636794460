import "./career.css";

import Typography from "@mui/material/Typography";

import PageTitle from "../../Components/PageTitle/pagetitles";

import CareerSection4Cards from "../../Components/Career/CareerSection4cards/careersection4cards";
import CareerSection5img from "../../Components/Career/CareerSection5img/careersection5img";
import { useEffect } from "react";

import useTheme from "@mui/system/useTheme";
import { useMediaQuery } from "@mui/material";
import shikshaimg from "../../images/career/shiksha.webp";
import dishaimg from "../../images/employees/1.png";

// const elements = [
//   <CareerSection3
//     description={`“Working with Restaverse feels like a spicy blend of tech
// and tradition. It's a daily tech feast where innovation is
// the main course!“`}
//     imgsrc={dishaimg}
//     empname="Disha"
//   />,
//   <CareerSection3
//     description={`“Collaborating with Restaverse is a high-flying journey into the future. It's like strapping into a rocket where innovation propels us skyward!“`}
//     imgsrc={shikshaimg}
//     empname="Shiksha"
//   />,
// ];

const elements = [
  {
    description:
      "Collaborating with Restaverse is a high-flying journey into the future. It's like strapping into a rocket where innovation propels us skyward!",
    imgsrc: shikshaimg,
    empname: "Shiksha Chauhan",
  },
  {
    description:
      "Working with Restaverse feels like a spicy blend of tech and tradition. It's a daily tech feast where innovation is the main course!",
    imgsrc: dishaimg,
    empname: "Disha Madani",
  },
  {
    description:
      "Collaborating with Restaverse is a high-flying journey into the future. It's like strapping into a rocket where innovation propels us skyward!",
    imgsrc: shikshaimg,
    empname: "Shiksha Chauhan",
  },
  {
    description:
      "Working with Restaverse feels like a spicy blend of tech and tradition. It's a daily tech feast where innovation is the main course!",
    imgsrc: dishaimg,
    empname: "Disha Madani",
  },
];

const Career = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    document.title = "Join Us | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="career-main">
      <div className="career-title">
        <PageTitle title={"We are hiring"} />
      </div>

      {/* section 5 */}

      <div>
        <section className="career-body p-4 p-sm-5 p-md-5">
          <div className="section-container">
            <div className="col-sm-12 col-xs-12 col mx-auto text-center">
              <Typography
                className="animate-from-top "
                variant={isSmallScreen ? "h4" : "h2"}
                style={{
                  color: "4F4F4F",
                  fontWeight: "bold",
                  marginBottom: theme.spacing(3),
                  fontFamily: "Poppins , sans-serif",
                  textAlign: "centre",
                  margin: "0 auto",
                  width: "80%",
                }}
              >
                Life at Restaverse
              </Typography>
              <br></br>
              <br></br>
            </div>

            <div className="row">
              <CareerSection5img />
            </div>
          </div>
        </section>
      </div>
      <div>
        <section
          className="career-body p-4 p-sm-5 p-md-5"
          style={{ fontFamily: "Poppins, sans-serif" }}
        >
          <div className="section-container">
            <div className="row g-5">
              <div className="col-sm-12 col-xs-12 col mx-auto text-center">
                <Typography
                  className="animate-from-top "
                  variant={isSmallScreen ? "h4" : "h2"}
                  style={{
                    color: "4F4F4F",
                    fontWeight: "bold",
                    marginBottom: theme.spacing(3),
                    fontFamily: "Poppins, sans-serif",
                    // textAlign: "centre",
                    // margin: "0 auto",
                    width: isSmallScreen ? "100%" : "70%",
                    margin: "auto",
                  }}
                >
                  Embark on Your Culinary Tech Journey
                </Typography>
                <br></br>
                <p
                  style={{
                    width: isSmallScreen ? "100%" : "60%",
                    margin: "0 auto",
                    fontSize: "1.1rem",
                  }}
                >
                  Restaverse is looking for like-minded individuals. We prefer
                  both humans and aliens 👽 You don’t have to wait for a better
                  world. You can join us and build it.
                </p>
                <br></br>
                <br></br>
                <br></br>

                <div className="career-section4-cards">
                  <CareerSection4Cards />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* section3 employees */}
      <div style={{ paddingBottom: "40px" }}>
        
      </div>
    </div>
  );
};

export default Career;
