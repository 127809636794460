import React from "react";
import "./casestudy.css";
import Challenge from "../../Components/Challenge/challenge";
import Infotags from "../../Components/Infotags/infotags";
import Solution from "../../Components/Challenge/solution";
import Impact from "../../Components/Challenge/impact";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import PageTitle from "../../Components/PageTitle/pagetitles";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import persianimage from "../../images/CaseStudy/persianimages/PersianHome.webp";


const PersianDarbarCasestudy = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    document.title = 'Case Study | Restaverse';
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const infoAboutCompany = {
    Brand: "Persian Darbar",
    Platform: "Zomato & Swiggy",
    Cuisine: "Biryani",
    Location: "Mumbai & Pune",
    AOV: "High",
    "Target Audience": "Mass",
  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "
          //  style={{ fontFamily: "Sen, sans-serif" }}
          className="container-lg"
        >
          <div
            className="casestudy"
            //  style={{ fontFamily: "Sen, sans-serif" }}
            style={{ fontFamily: "'Poppins', sans-serif" }}
          >
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                  Persian Darbar: Revenue growth via focused category approach
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={persianimage} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <Challenge />
              </section>

              <section id="solution">
                <Solution />
              </section>

              <section id="impact">
                <Impact />
              </section>
              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

                <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default PersianDarbarCasestudy;
