import React,{ createContext, useContext, useState } from "react";


const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState('');
  
    return (
      <AppContext.Provider value={{ loading, setLoading, loadingContent, setLoadingContent }}>
        {children}
      </AppContext.Provider>
    );
  };
  
  export const useAppContext = () => {
    return useContext(AppContext);
  };