import React from "react";
import "./challenge.css";

const Challenge = () => {
  return (
    <div className="challenge">
      <div>
        <h3> Challenge</h3>
        <hr></hr>
      </div>
      <div className="main-section-challenge">
        <div className="left-challenge">Turning Ad Challenges into Success</div>

        <div>
          Despite substantial advertising, UFC struggled with unsatisfactory
          ROAS and inventory issues, which frequently led to stockouts,
          highlighting inefficiencies in both marketing and supply chain
          management.
        </div>
      </div>
    </div>
  );
};

export default Challenge;
