import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppContext } from "../../Context/notification-context";
const Notification = () => {

  const { loading, setLoading, loadingContent, setLoadingContent } = useAppContext();
  return (
    <div
      style={{
        width: "300px",
        position: "fixed",
        top: "10%",
        right: "1%",
        zIndex: "20",
        fontSize: "2rem",
        backgroundColor:'#EDF7ED',
        borderRadius:'3px'
      }}
    >
      <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
        {loadingContent}
      </Alert>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{loading && <LinearProgress style={{ width: "100%" }} />}</div>
    </div>
  );
};

export default Notification;
