import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, delay, reset, style }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (reset) {
      setCurrentText('');
      setCurrentIndex(0);
    }

    let timeout;

    const words = text.split(' ');

    if (currentIndex < words.length) {
      timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + (prevText ? ' ' : '') + words[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, text, reset]);

  return <span style={style}>{currentText}</span>;
};

export default Typewriter;
