import React from "react";
import "./casestudy.css";
import Challenge from "../../Components/Challenge/challenge2";
import Infotags from "../../Components/Infotags/infotags";
import Solution from "../../Components/Challenge/solution2";
import Impact from "../../Components/Challenge/impact2";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import silverbeachmimage from "../../images/CaseStudy/silverbeachcafe/silverbeachcafe1.webp";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import sbgimg2 from "../../images/CaseStudy/silverbeachcafe/silverbeachcafe2.png";
import sbgimg3 from "../../images/CaseStudy/silverbeachcafe/silverbeachcafe3.png";
import sbgimgowner from "../../images/CaseStudy/silverbeachcafe/silverbeachcafeowner.png";


const UFCCasestudy5 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    document.title = 'Case Study | Restaverse';
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const infoAboutCompany = {
    Brand: `Silver Beach`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Asian",
    Location: "Mumbai & Bengaluru",
    AOV: "High",
    "Target Audience": "Premium",
  };

  const challenge = {
    text1: "Boosting Delivery and Efficiency",
    text2: `Silver Beach Cafe encountered significant hurdles, with delivery income barely impacting their top line and prolonged order acceptance times diminishing customer experience and streamlining concerns.`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 4,
        figurePrefix: "",
        figuresPostfix: "x",
        content: "Growth in overall revenue",
      },
      card2: {
        header: "Conversion",
        figures: 200,
        figuresPostfix: "%",
        content: "increase in overall menu opens",
      },
      card3: {
        header: "Ads Conversion",
        figures: 85,
        figuresPostfix: "%",
        content: "increse in Ads conversions",
      },
    },
    owner_views: {
      title: "Silver Beach Cafe",
      description: `I'm deeply grateful to team Restaverse for their instrumental role in our evolution. Their innovative approach to tailoring our menus and co-curating them with our chef has revolutionized our delivery service and solidified our brand's presence. This partnership with team Restaverse has been a pivotal milestone in our journey, promising an exciting future ahead.`,
      name: "Mr. Hitesh Keswani",
      designation: "Founder, Silver Beach Cafe",
      owner_img: sbgimgowner
    },
  };

  const solutions = {
    1: {
      header: "Tailored Menus for Optimized Service",
      content: `To tackle Silver Beach Cafe's challenges, we adopted a dynamic approach by customizing the menu based on geographic location and meal times, aligning with customer preferences and moods. This strategy not only enhanced the customer experience by offering tailored choices but also optimized kitchen operations and menu listings, ensuring peak efficiency throughout service hours.`,

      src: sbgimg2,
    },
    2: {
      header: "Strategic Menu Curation for Brand Positioning",
      content:
        "In collaboration with Silver Beach Cafe's chef, we meticulously curated the menu, taking into account the local demand and supply dynamics. This collaborative effort led to a strategic emphasis on the cafe's signature dishes, elevating the brand's market positioning. This thoughtful curation process ensured that the offerings resonated with the local clientele, bolstering the cafe's reputation and delivery revenue.",
      src: sbgimg3,
    },

  };


  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
          <div className="casestudy" style={{ fontFamily: "Poppins , sans-serif" }}>
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                  Silver Beach Cafe: Revenue growth via better search engine optimisation
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={silverbeachmimage} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions} />
              </section>

              <section id="impact">
                <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

                <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default UFCCasestudy5;
