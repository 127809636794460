import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import seven from '../../images/blogs/7.png';
import eight from '../../images/blogs/8.png';
const Blogfour = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14}, // Custom height (default is 4px)
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1', // Change the filled color of the progress bar
                    },
                    backgroundColor: '#D3D3D3', // Change the unfilled background color

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            The Impact of Online Reviews on Restaurant Revenue
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Arsh Khanna"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/arshkhanna1/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Arsh Khanna</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: July 19, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                We often hear the question: "Do online reviews really matter?" The answer is a resounding yes! Online reviews play a crucial role in shaping a restaurant's reputation and directly impact its success. These reviews help <b style={{fontWeight:500}}>build credibility and trust,</b> as potential diners often rely on the experiences shared by others before deciding where to eat.
                                <br></br><br></br>
                                In fact, <b style={{fontWeight:500}}>98% of consumers</b> read online reviews for local businesses, and <b style={{fontWeight:500}}>54% of online shoppers</b> check reviews before dining at a new restaurant. These reviews possess the power to either <b style={{fontWeight:500}}>attract new customers or deter them,</b> making them a critical factor in driving foot traffic and boosting your restaurant's success.
                                <br></br><br></br>
                                There are <b style={{fontWeight:500}}>key steps to enhance and protect your online reputation.</b> Implementing these strategies can help restaurants stand out in a competitive dining scene where digital trust can significantly influence sales.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={seven}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"

                                }}
                            >
                                1. Choose the Perfect Moment
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Asking customers for feedback is crucial for restaurants aiming to build an effective review strategy. The key is to request reviews at the right time without disrupting the dining experience—immediate requests might not always yield the best results, as customers often need time to fully appreciate and evaluate their experience.
                                <br></br>
                                Restaurants can solicit reviews in various natural and seamless ways:
                                <br></br>
                                <Typography sx={{
                                    textAlign: 'justify',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                    marginLeft: '10px'
                                }}>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        margin: 0,

                                    }}>
                                        <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>Social Media:</b> Use posts on platforms like Instagram and Facebook to encourage satisfied diners to share their experiences and leave reviews.</li>
                                        <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>WhatsApp Messages:</b> Share a link to a review page or feedback survey via WhatsApp, simplifying the process for customers to give their input.</li>
                                        <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>Loyalty Programs:</b> Encourage reviews through loyalty programs by rewarding customers with points or exclusive offers for leaving feedback after their visit. </li>
                                    </ul>
                                </Typography>
                                <br></br>
                                By using these strategies, restaurants can gather valuable feedback while enhancing customer satisfaction and engagement.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"

                                }}
                            >
                                2. Track Your Review Sources
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Understanding where customers seek information is vital for a successful review strategy in the restaurant industry. To be effective, restaurants need to know which platforms diners use to research and review their experiences.
                                <br></br><br></br>
                                Over <b style={{fontWeight:500}}>63% of consumers rely on Google for reviews,</b> making it the top choice due to its integration of <b style={{fontWeight:500}}>review snippets and star ratings</b> directly in search results. Following Google, diners also frequently use Yelp, TripAdvisor, Facebook, Zomato, and Swiggy to check reviews and engage with restaurants. Zomato offers detailed <b style={{fontWeight:500}}>reviews, ratings, and menu information,</b> helping diners make informed decisions. Swiggy, primarily known for its food delivery services, also features customer reviews and ratings, providing <b style={{fontWeight:500}}>insights into restaurant quality and service.</b>
                                <br></br><br></br>
                                Social media also plays a key role, allowing customers to interact with restaurants, ask questions, and share their experiences. By focusing on these key platforms, restaurants can effectively manage their online reputation and attract more guests.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',
                                    maxWidth: '550px',
                                    height: 'auto',
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Unlock the power of your restaurant’s reviews with Restaverse to boost your reputation, delight your customers, and drive revenue growth. Sign up now to turn feedback into your greatest asset!

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Keep an Eye on and Analyze Reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <b style={{fontWeight:500}}>53% of customers</b> expect a response to negative reviews within a week and some expect a response in <b style={{fontWeight:500}}>three days or less.</b> This highlights the necessity for restaurants to <b style={{fontWeight:500}}>actively monitor, read, and respond to reviews</b> to protect their reputation. Effective review management should be a regular part of restaurant operations.
                                <br></br><br></br>
                                Moreover, diners are increasingly skeptical of fake reviews.  Creating false reviews can backfire and damage credibility. A <b style={{fontWeight:500}}>mix of positive, negative, and neutral reviews is more credible and trustworthy.</b> Many review platforms also ban anonymous reviews and use advanced algorithms to detect fake feedback, making authenticity essential for maintaining a strong online reputation.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Highlight Positive Reviews
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={eight}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                When potential diners research a restaurant and <b style={{fontWeight:500}}>see positive reviews</b> featured on the restaurant’s website or social media, it creates a <b style={{fontWeight:500}}>strong first impression.</b> These reviews also serve to remind returning customers of the high-quality food and service they can expect.
                                <br></br><br></br>
                                Restaurants can effectively use <b style={{fontWeight:500}}>detailed, descriptive reviews as powerful testimonials and marketing tools.</b> Reviews highlighting specific dishes, exceptional service, or memorable dining experiences—presented as both <b style={{fontWeight:500}}>concise quotes and more detailed stories—</b>can make a significant impact. Displaying these reviews in a way that aligns with the restaurant’s brand message enhances authenticity in marketing.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Engage and Address
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Experienced restaurant owners <b style={{fontWeight:500}}>view reviews as priceless, free advice from their patrons.</b> By actively engaging with feedback, they can build stronger relationships with diners and foster meaningful discussions among staff.
                                <br></br><br></br>
                                Addressing feedback—both positive and negative—boosts a restaurant's reputation and shows a commitment to customer satisfaction. Acknowledging reviews and illustrating how customer input has led to meaningful changes enhances credibility. It’s <b style={{fontWeight:500}}>important to express appreciation for feedback</b> and <b style={{fontWeight:500}}>clearly communicate</b> how suggestions have been acted upon, whether in <b style={{fontWeight:500}}>public or private responses.</b> This practice not only deepens <b style={{fontWeight:500}}>customer loyalty</b> but also conveys that the restaurant <b style={{fontWeight:500}}>truly values</b> and listens to its guests.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                CONCLUSION
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Harnessing online reviews through strategic feedback requests, monitoring key platforms, and actively engaging with customer input can elevate your restaurant’s reputation and drive revenue. <b style={{fontWeight:500}}>Showcasing positive reviews and thoughtfully addressing feedback builds loyalty and drives repeat business.</b> Use these insights to excel in a competitive market and transform customer feedback into growth. Start your path to outstanding success by truly listening to your guests!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogfour;