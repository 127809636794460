import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import nine from '../../images/blogs/9.png';
import ten from '../../images/blogs/10.png';
const Blogfive = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14}, 
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1', 
                    },
                    backgroundColor: '#D3D3D3', 

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >
                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Turning Negative Reviews into Positive Change: A Restaurant’s Guide to Reputation Management
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Rishon Dharia"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/rishon-dharia-5aaa551b2/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Rishon Dharia</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Oct 10, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={nine}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In today's digital landscape, managing your online reputation is crucial. For restaurants, online reviews hold the power to shape your business’s destiny. A rave review can flood your tables with eager new diners, while a single negative comment can ripple through your reputation.
                                <br></br><br></br>
                                But fear not! Conquering negative reviews can be a game-changer for your restaurant. Here’s an exhilarating guide on how to turn those negative reviews into a chance to shine, ensuring your restaurant’s reputation not only endures but soars!
                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Understanding the Role of Reviews
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Diner reviews have always been a fundamental element in deciding a restaurant's success, but their importance has grown in the digital age. <b style={{fontWeight:500}}>Nowadays, many diners rely heavily on online review sites like Google, Zomato, Swiggy, and TripAdvisor to make their dining decisions,</b> making it essential to manage your restaurant’s online reputation proactively. <b style={{fontWeight:500}}>Positive reviews can help attract new patrons, </b>while <b style={{fontWeight:500}}>negative reviews offer a special opportunity to demonstrate your restaurant’s dedication to high standards and exceptional service.</b> Addressing these reviews effectively can turn challenges into opportunities to showcase your dedication to excellence.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Respond in a Calm and Professional Manner
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <b style={{fontWeight:500}}>Feeling defensive in the face of criticism is a common reaction,</b> but it's important to handle negative reviews with a <b style={{fontWeight:500}}>level-headed approach.</b> Remember, your response serves not only the reviewer but also <b style={{fontWeight:500}}>future potential customers who will see it.</b>
                                <br></br><br></br>
                                <b style={{fontWeight:500}}>Professional responses to negative reviews can lead to a 25% improvement in online reputation scores</b> (ReviewTrackers). By maintaining a professional tone and addressing feedback thoughtfully, you can enhance your restaurant’s reputation and demonstrate a <b style={{fontWeight:500}}>commitment to exceptional service.</b> This not only helps address the immediate concern but also leaves <b style={{fontWeight:500}}>a positive impression on prospective diners,</b> contributing to your restaurant’s overall success.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Reputation & Improvement through Reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Once you’ve processed the feedback from a less-than-ideal experience, carefully review the suggestions for improvement. You might uncover <b style={{fontWeight:500}}>valuable insights. Develop a detailed action plan,</b> make the necessary improvements, and <b style={{fontWeight:500}}>inform your staff.</b>
                                <br></br><br></br>
                                <b style={{fontWeight:500}}>Addressing concerns in negative reviews and implementing changes can improve customer retention by up to 30%</b> (Zendesk). Consider following up with the reviewer to let them know about the improvements you’ve made. This gesture not only shows that <b style={{fontWeight:500}}>you value their feedback</b> but also demonstrates your <b style={{fontWeight:500}}>commitment to improving the dining experience.</b> By showcasing your <b style={{fontWeight:500}}>responsiveness and willingness to evolve,</b> you can enhance your restaurant’s reputation and draw in new customers.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',
                                    maxWidth: '550px',
                                    height: 'auto',
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Struggling with negative reviews? Don’t let them hold you back. With Restaverse, you can transform criticism into powerful growth strategies.<br></br>
                                Take control of your restaurant’s reputation. Visit Restaverse and see how our solutions can help you turn challenges into opportunities.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Turn Criticism into Strategy
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Transform your improvements into a marketing asset by showcasing them through your PR efforts. Promoting changes driven by reviews can result in a <b style={{fontWeight:500}}>15-20% increase in new customer inquiries </b>(Social Media Examiner). Use digital, local, and social media channels to highlight the upgraded experiences at your restaurant. This approach not only demonstrates your dedication to progress but also draws attention to your restaurant’s strengths, enticing diners to visit and support your business.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={ten}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Monitor the Competition
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In the cutthroat world of the food industry, staying competitive is essential for retaining your customer base and outpacing rivals. If you don’t actively monitor emerging trends and marketing innovations, you risk losing even your most devoted customers to more agile competitors. <b style={{fontWeight:500}}>Regular competitive analysis (every 1-2 weeks)</b> can enhance competitive advantage by <b style={{fontWeight:500}}>20% and improve market responsiveness</b> (Nielsen). Although you can perform this task manually, utilizing specialized software can expedite data collection and analysis, providing deeper insights for more strategic decision-making. By staying informed and adapting quickly, you ensure your restaurant remains a top choice in a dynamic market.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                CONCLUSION

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Turning negative reviews into triumphs can truly redefine your restaurant's journey. By tackling feedback head-on and showcasing your dedication to improvement, you transform criticism into a powerful tool for growth. This proactive approach revitalizes your relationship with disgruntled customers and impresses potential diners with your commitment to improvement.
                                Remember, it’s not just about the challenges you face but how you navigate them with grace and resolve. Embrace each review as a golden opportunity to shine brighter, elevate your service, and set new standards of hospitality.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogfive;