import React from "react";
import "./casestudy.css";
import Challenge from "../../Components/Challenge/challenge2";
import Infotags from "../../Components/Infotags/infotags";
import Solution from "../../Components/Challenge/solution2";
import Impact from "../../Components/Challenge/impact2";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";


import nomnomimage from "../../images/CaseStudy/nomnom/nomnom1.webp";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import nomnomimage2 from "../../images/CaseStudy/nomnom/nomnom2.png";
import nomnomimage3 from "../../images/CaseStudy/nomnom/nomnom3.png";
import nomnomowner from "../../images/CaseStudy/silverbeachcafe/silverbeachcafeowner.png"

const UFCCasestudy4 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  // const challengesection =

  useEffect(() => {
    document.title = "Case Study | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
 

  const infoAboutCompany = {
    Brand: `Nom Nom`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Asian",
    Location: "Mumbai & Bengaluru",
    AOV: "High",
    "Target Audience": "Premium",
  };

  const challenge = {
    text1: "Boosting Visibility and Efficiency",
    text2: `Nom Nom was grappling with two significant issues: its online visibility and organic rankings were notably weak, and there was a clear misalignment in meal prep times and pricing. Our strategy focused on rectifying these core areas to bolster Nom Nom's market standing and operational effectiveness.`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 3,
        figurePrefix: "",
        figuresPostfix: "x",
        content: "Growth in overall revenue",
      },
      card2: {
        header: "Conversion",
        figures: 125,
        figuresPostfix: "%",
        content: "increase in overall menu opens",
      },
      card3: {
        header: "Ads Conversion",
        figures: 45,
        figuresPostfix: "%",
        content: "increase in Ads conversions",
      },
    },
    owner_views: {
      title: "Nom Nom",
      description: `As the founder of Nom Nom, I extend my heartfelt gratitude to team Restaverse for their technological prowess in revolutionizing our online ordering business and enriching our menu with exclusive offerings. Their innovative solutions have been a cornerstone of our growth, elevating the Nom Nom experience and setting us on an exciting path forward.`,
      name: "Mr. Hitesh Keswani",
      designation: "Founder, Nom Nom",
      owner_img:nomnomowner
    },
  };


    const solutions = {
      1: {
        header: " Exclusive Online Menu",
        content: `To rejuvenate Nom Nom's online presence, we curated an exclusive selection of dishes specifically for their digital menu. This initiative aimed at enticing a broader online audience, offering them a taste of novelty and exclusivity. The introduction of these unique dishes was instrumental in differentiating Nom Nom in the digital space, encouraging more online orders and enhancing brand appeal.`,
  
        src: nomnomimage2,
      },
      2: {
        header: "Streamlined Ordering System",
        content:
          "Our strategy to improve Nom Nom's digital footprint involved optimizing their online ordering system to enhance operational efficiency and customer experience. By refining the ordering process and ensuring seamless interaction, we significantly elevated customer satisfaction. This led to better reviews and higher organic rankings, directly contributing to an increase in online order volume and overall brand visibility.",
        src: nomnomimage3,
      },
 
    };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
          <div
            className="casestudy"
            style={{ fontFamily: "Poppins , sans-serif" }}
          >
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                Nom Nom: Revenue growth via better menu optimisation
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={nomnomimage} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
                {/* <Challenge/> */}
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions}/>
              </section>

              <section id="impact">
                {/* <Impact /> */}
                <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

              <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default UFCCasestudy4;
