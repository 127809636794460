import React from "react";
import "./casestudy.css";
import Challenge from "../../Components/Challenge/challenge2";
import Infotags from "../../Components/Infotags/infotags";
import Solution from "../../Components/Challenge/solution2";
import Impact from "../../Components/Challenge/impact2";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import tbsemimage from "../../images/CaseStudy/tbse/tbse1.webp";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import tbseimg2 from "../../images/CaseStudy/tbse/tbse2.png"
import tbseimg3 from "../../images/CaseStudy/tbse/tbse3.png"
import tbseowner from "../../images/CaseStudy/tbse/tbseowner.png" 

const UFCCasestudy6 = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    document.title = "Case Study | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const infoAboutCompany = {
    Brand: `The Bar Stock Exchange`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Bar",
    Location: "Mumbai & Bengaluru",
    AOV: "Mid",
    "Target Audience": "Mass",
  };
  const challenge = {
    text1: " Elevating Bar Food Sales",
    text2: `TBSE was encountering a significant hurdle with its bar segment, where food sales were relatively underperforming to their bar sales, affecting the overall revenue and customer experience.`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 21,
        figurePrefix: "",
        figuresPostfix: "x",
        content: "Growth in overall revenue",
      },
      card2: {
        header: "Conversion",
        figures: 25,
        figuresPostfix: "x",
        content: "increase in overall menu opens",
      },
      card3: {
        header: "Ads Conversion",
        figures: 600,
        figuresPostfix: "%",
        content: "increase in Ads conversions",
      },
    },
    owner_views: {
      title: "The Bar Stock Exchange",
      description: `As the founder of TBSE, I'm immensely thankful to team Restaverse for their visionary approach in elevating our food offerings. Their strategic use of online platforms not only amplified our delivery sales but also fundamentally enhanced our dine-in experience, striking a perfect balance in our sales mix. This partnership has been transformative, and I look forward to our continued success together.`,
      name: "Mr. Mihir Desai",
      designation: "Founder, TBSE",
      owner_img:tbseowner
    },
  };
  const solutions = {
    1: {
      header: "Digital Spotlight on Cuisine",
      content: `To counter TBSE's challenge, we initiated a campaign to showcase their food offerings on various online platforms. This strategy not only kickstarted revenue generation from deliveries but also had a ripple effect, boosting dine-in food sales over 6-9 months and enhancing the brand's dining appeal.`,

      src: tbseimg2,
    },
    2: {
      header: "Harmonizing Food and Beverage Sales",
      content:
        "The strategic emphasis on online visibility and improved food offerings has led to a more balanced and enhanced food-to-alcohol sales ratio for TBSE. This shift reflects a significant improvement in the overall sales mix, contributing to a more comprehensive and appealing brand experience.",
      src: tbseimg3,
    },

  };


  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
          <div
            className="casestudy"
            style={{ fontFamily: "Poppins , sans-serif" }}
          >
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                TBSE: Revenue growth from under utilised bar kitchens
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={tbsemimage} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
              <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions}/>
              </section>

              <section id="impact">
              <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

              <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default UFCCasestudy6;
