import { Container, Grid, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import first from "../../../images/career/1.webp";
import second from "../../../images/career/2.webp";
import third from "../../../images/career/3.webp";
import fourth from "../../../images/career/4.webp";
import fifth from "../../../images/career/5.webp";
import sixth from "../../../images/career/6.webp";
import "./careersection5img.css";
import { useInView } from "react-intersection-observer";

const CareerSection5img = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  return (
    <div ref={ref}>
      {inView && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} className="hide-on-mobile fade-in1">
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "none",
                backgroundImage: `url(${fourth})`,
                backgroundSize: "cover",
                // backgroundPosition:'50% 25%',
                height: "300px",
                width: "100%",
              }}
            ></Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="hide-on-mobile fade-in2">
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "none",
                backgroundImage: `url(${second})`,
                backgroundSize: "cover",
                height: "300px",
                width: "100%",
              }}
            ></Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="fade-in3">
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "none",
                backgroundImage: `url(${third})`,
                backgroundSize: "cover",
                height: "300px",
                width: "100%",
              }}
            ></Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="fade-in4">
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "none",
                backgroundImage: `url(${first})`,
                backgroundSize: "cover",
                backgroundPosition: "90% 25%",
                height: "300px",
                width: "100%",
              }}
            ></Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="fade-in5">
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "none",
                backgroundImage: `url(${fifth})`,
                backgroundSize: "cover",
                height: "300px",
                width: "100%",
              }}
            ></Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="fade-in6">
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "none",
                backgroundImage: `url(${sixth})`,
                backgroundSize: "cover",
                height: "300px",
                width: "100%",
              }}
            ></Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CareerSection5img;
