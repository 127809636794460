import React from "react";
import "./challenge.css";

const Challenge = () => {
  return (
    <div className="challenge">
      <div>
        <h3> Challenge</h3>
        <hr></hr>
      </div>
      <div className="main-section-challenge">
        <div className="left-challenge">Boosting Online Sales Strategy</div>

        <div>
          Persian Darbar aimed to increase revenues from their existing stores
          through better online platform performance, focusing on overcoming the
          challenge of standing out in a competitive digital food delivery space
          to boost sales.
        </div>
      </div>
    </div>
  );
};

export default Challenge;
