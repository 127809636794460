import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import thirtythree from '../../images/blogs/33.png';
import thirtyfour from '../../images/blogs/34.png';
const Blogseventeen = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: { sm: 65, xs: 56 },
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: { sm: 8, xs: 14 },
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: { sm: 2, xs: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize: { xs: '35px', sm: '47px' }
                            }}
                        >
                            Revamp and Thrive: 7 Essential Tips for Menu Optimization
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Rishon Dharia"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: { sm: 'center', xs: 'left' }, flexDirection: { sm: 'row', xs: 'column' }, gap: { sm: 87, xs: 2 } }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/rishon-dharia-5aaa551b2/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Rishon Dharia</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 30, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: { sm: 2, xs: 0 },
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: { sm: 2, xs: 0 },
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Picture your menu as more than just a catalog of dishes—envision it as a powerful tool poised to elevate your restaurant's success. In the bustling world of dining, a meticulously crafted menu isn't just a list of items—it's your secret weapon for captivating customers, driving up sales, and supercharging kitchen efficiency.
                                <br></br>
                                Ready to transform your menu into a strategic asset? Discover these eight game-changing tips to maximize profitability and efficiency in your restaurant.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={thirtythree}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <ol>
                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Understand Your Customer Preferences</b> <br></br>
                                            Start by analyzing your customer base. Identify which dishes are most popular and which flavors are trending. <b style={{ fontWeight: 500 }}>Data from the National Restaurant Association shows that 70% of customers prefer dining at restaurants that offer personalized options based on their preferences.</b> Leverage customer reviews, sales trends, and market insights to fine-tune your menu according to your audience’s preferences. By catering to their tastes, you’ll boost satisfaction and foster customer loyalty.
                                        </p>
                                    </li>
                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Use design and layout effectively</b> <br></br>
                                            A well-designed and visually engaging menu simplifies the decision-making process for customers, enhancing their dining experience. <b style={{ fontWeight: 500 }}>A study by Menu Design Group found that well-designed menus can increase sales by up to 15%.</b> Use color, fonts, imagery, and design elements strategically to highlight standout dishes and create a cohesive, attractive look. This approach not only captures attention but also contributes to a more enjoyable and engaging dining atmosphere.
                                        </p>
                                    </li>
                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Optimize for Seasonal Ingredients</b> <br></br>
                                            Infuse your menu with seasonal ingredients to keep it fresh and enticing. Seasonal produce offers distinctive flavors and can be more budget-friendly. <b style={{ fontWeight: 500 }}>According to the USDA, seasonal produce can be up to 30% cheaper and fresher compared to off-season items.</b> Regularly update your menu to include these seasonal specials, promoting them as exclusive offerings to spark interest and urgency. This strategy not only refreshes your offerings but also attracts customers eager to enjoy new, seasonal dishes.
                                        </p>
                                    </li>
                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Vivid Menu Descriptions</b> <br></br>
                                            Captivate your customers with mouthwatering descriptions that make each dish irresistible. Move beyond listing ingredients and focus on conveying the taste, texture, and overall experience. <b style={{ fontWeight: 500 }}>Research from Cornell University indicates that descriptive menu language can boost sales of those items by 27%.</b> For example, rather than simply stating “Classic Cheeseburger” describe it as “Juicy, flame-grilled beef patty topped with melted cheddar cheese, crisp lettuce, and ripe tomato, all nestled in a freshly baked brioche bun.” Vivid, appetizing language not only makes your dishes more appealing but can also support premium pricing by highlighting their unique qualities.
                                        </p>
                                    </li>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '20px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <img src={signup} style={{
                                            width: '100%',
                                            maxWidth: '550px',
                                            height: 'auto',
                                        }} alt="Description of the image" />
                                        <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                                    </Box>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            marginTop: "5px",
                                            fontFamily: 'Lexend Deca, sans-serif',
                                            fontSize: "1.25rem",
                                            lineHeight: "1.77777778",
                                            color: "#213343",
                                            fontWeight: 300
                                        }}
                                    >
                                        Discover how our platform can help you optimize menu descriptions and enhance your restaurant's success. Dive into our tools and insights to transform your offerings. Start optimizing today!
                                    </Typography>

                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Effective Menu Pricing</b> <br></br>
                                            Price your menu items strategically to motivate customers to explore new options and boost their total spend. <b style={{ fontWeight: 500 }}>A report from the Restaurant Business Online reveals that strategic menu pricing can increase revenue by up to 20%.</b> Evaluate ingredient costs, operational expenses, and market conditions, while also considering competitor pricing. By setting prices that reflect both value and quality, you can encourage diners to sample a wider range of dishes, ultimately enhancing their experience and increasing your revenue.
                                        </p>
                                    </li>
                                    
                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Cater to Dietary Preferences</b> <br></br>
                                            <b style={{ fontWeight: 500 }}>The International Food Information Council notes that 61% of consumers prefer restaurants that offer a variety of dietary options, such as vegetarian, vegan, and gluten-free.</b> Clearly identify these options on your menu to help customers with specific dietary requirements find suitable choices easily. Offering a range of inclusive options can attract a broader audience and improve overall customer satisfaction.
                                        </p>
                                    </li>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20px',
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={thirtyfour}
                                            style={{
                                                width: '100%',
                                                maxWidth: '550px',
                                                height: 'auto',
                                            }}
                                            alt="Description of the image"
                                        />
                                    </Box>
                                    <li>
                                        <p><b style={{ fontWeight: 500 }}>Regularly Review and Update Your Menu</b> <br></br>
                                            An unchanged menu can quickly become outdated and less appealing. Regularly review your menu's performance by tracking sales, customer feedback, and industry trends. Periodically refresh your menu to reflect seasonal ingredients, emerging culinary trends, and evolving customer preferences. This proactive strategy keeps your menu lively and up-to-date.
                                        </p>
                                    </li>

                                </ol>

                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Transform your offerings into a dazzling array that not only attracts diners but also boosts your bottom line. We hope these tips give you the insights needed to refine your menu strategy. Ready to make a change? Dive into these strategies and see how they can elevate your menu and boost your restaurant's success.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogseventeen;