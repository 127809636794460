import React from 'react'
import './infotags.css';
import {
  faEarthAsia,
  faLayerGroup,
  faUtensils,
  faLocationDot,
  faCoins,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Infotags = ({header,value}) => {

  const getIconName=(val)=>{
    if (val === "Brand") {
    return faEarthAsia;
  } else if (val === "Platform") {
    return faLayerGroup;
  } else if (val === "Cuisine") {
    return faUtensils;
  } else if (val === "Location") {
    return faLocationDot;
  } else if (val === "AOV") {
    return faCoins;
  } else if (val === "Target Audience") {
    return faUserGroup;

  }
}

   const icon2 = getIconName(header);

  // console.log(value);
  return (
    <div className="infotagchild">
      {/* <div className="icon"> */}

      <FontAwesomeIcon
        icon={icon2}
        style={{
          fontSize: "2rem", // Adjust the size as needed
          color: "#6c41ff",
          width: "35px", // Adjust the size as needed
          height: "35px",
          padding: "15px",
          borderRadius: "10px",
          backgroundColor: "#ffffff",
        }}
      />
      {/* </div> */}
      <div className="infotag-content">
        <div className="infotag-header">{header}</div>
        <div className="infotag-value" style={{}}>{value}</div>
      </div>
    </div>
  );
}

export default Infotags