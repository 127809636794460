import React from "react";
import "./challenge.css";
import AnimatedNumber from "../Team/animatednumber";
import ufcanowner from "../../images/CaseStudy/ufcimages/UFCowner.webp"

const Impact = () => {
  const impactCards = {
    card1: {
      header: "Revenue",
      figures: 10,
      figurePrefix: "",
      figuresPostfix: "x",
      content: "Growth in overall revenue",
    },
    card2: {
      header: "Conversion",
      figures: 95,
      figuresPostfix: "%",
      content: "increse in Menu-to-order conversions",
    },
    card3: {
      header: "Ads Conversion",
      figures: 3,
      figuresPostfix: "x",
      content: "increse in Ads conversions",
    },
  };

  return (
    <div className="challenge">
      <div>
        <h3> Impact</h3>
        <hr></hr>
      </div>
      <div className="impact-cards">
        {Object.keys(impactCards).map((key) => {
          const card = impactCards[key];
          return (
            <div className="impact-single-card">
              <div className="impact-card-header">{card.header}</div>
              <div className="impact-card-figure">
                <AnimatedNumber
                  end={card.figures}
                  duration={3}
                  unit={card.figuresPostfix}
                />
              </div>
              <div className="impact-card-content">{card.content}</div>
            </div>
          );
        })}
      </div>

      <div className="impact-section">
        <div className="impact-left-section">
          <h1>UFC</h1>
          <p>
            As a restaurant owner, I've witnessed incredible growth thanks to
            Team Restaverse's insights. Their data-driven strategies improved
            our visibility, inventory management, and predictive analytics,
            leading to a more efficient operation and significantly enhanced
            customer satisfaction. Their expertise was key in overcoming our
            previous challenges.
          </p>

          <div className="writer-name">
            <h3>Mr. Mayur Jhala</h3>
            <span>Director, Jhala Brothers</span>
          </div>
        </div>

        <img src={ufcanowner}className="impact-img"></img>
      </div>
    </div>
  );
};

export default Impact;
