import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import "./testimonials.css";
import Omaer from "../../../images/home/reviews/OmaerSshaikh.jpg";
import DevYadav from "../../../images/home/reviews/DevYadav.jpg";
import mihirD from "../../../images/home/reviews/MihirDesai.webp";
import Prasuk from "../../../images/home/reviews/PrasukJain.webp";
import mdbhol from "../../../images/home/reviews/md-bhol.webp";

const TestimonialsGrid = () => {
  return (
    <div
      className="p-2 p-md-5"
      style={{ fontFamily: "Poppins , sans-serif", backgroundColor: "#F7F5FF" }}
    >
      <div className="col-sm-12 col-xs-12 col mx-auto text-center hometestimonials">
        <h2
          className="career-section2-left-header animate-from-top"
          style={{
            fontSize: "2rem",
            padding:'8px'
          }}
        >
          What Our Customers Say
        </h2>
        <br></br> 
        {/* <br></br> */}
        <p
          style={{
            width: "60%",
            margin: "0 auto",
            padding:'20px'
            // fontSize: "1.1rem",
          }}
        >
          Restaverse has helped the founders of several restaurants, consultants
          and conglomerates in the industry. See why you can trust us too.
        </p>
        <br></br>
        <br></br>
        {/* <br></br> */}
      </div>
      <div style={{ background: "#F7F5FF" }}>
        <div class="outerdiv" style={{ backgroundColor: "#F7F5FF" }}>
          <div
            class="innerdiv"
            style={{
              backgroundColor: "#F7F5FF",
            }}
          >
            {/* <!-- div1 --> */}
            <div class="div1 eachdiv"> 
              <div class="userdetails">
                <div class="imgbox">
                  <img src={Omaer} alt="" />
                </div>
                <div class="detbox">
                  <p class="name">Omaer S. Shaikh</p>
                  <p class="designation">
                   MD, Shalimar Restaurants
                  </p>
                </div>
              </div>
              <div class="review">
                <h4>
                  We've been a traditionally offline business and the digital
                  transformation provided by team restaverse has helped us
                  rationalise our spends across all our online mediums
                </h4>
                <p>
                  They have helped us almost outsource this division with
                  experts we can trust. Their help in launching and scaling our
                  cloud only brands shows that they can truly leverage the
                  online e-commerce and q-commerce platforms well. They've
                  helped us reduce our CAC by ~50%, and also pushed us to
                  improve our other customer facing metrics.
                </p>
              </div>
            </div>
            {/* <!-- div2 --> */}
            <div class="div2 eachdiv" style={{}}>
              <div class="userdetails">
                <div class="imgbox">
                  <img src={DevYadav} alt="" />
                </div>
                <div class="detbox">
                  <p class="name">Dev Yadav</p>
                  <p class="designation">
                    Co-founder, <span style={{ fontSize: "0.8rem" }}>Greenr</span>{" "}
                  </p>
                </div>
              </div>
              <div class="review">
                {/* <h4>
                  Since partnering with Team Restaverse, we've achieved new
                  revenue highs, following a year of steady performance.
                </h4> */}
                <p style={{ fontSize: ".9rem",color:'white' }}>
                  We were at a stable revenue for about a year, post our
                  engagement with team restaverse we've been consistently
                  outdoing our highs and their insights have helped us improvise
                  revenue from some of our non-performing stores and also helped
                  us figure the right playbook with no increment in discount per
                  order.
                </p>
              </div>
            </div>
            {/* <!-- div3 --> */}
            <div class="div3 eachdiv">
              <div class="userdetails">
                <div class="imgbox">
                  <img src={mihirD} alt="" />
                </div>
                <div class="detbox">
                  <p class="name dark">Mihir Desai</p>
                  <p class="designation dark">
                    Founder, The Bar Stock Exchange
                  </p>
                </div>
              </div>
              <div class="review dark">
                <h4 style={{ lineHeight: "1.6" }}>
                  Team restaverse has helped us create a completely new
                  profitable business vertical, without any additional capex.
                </h4>
                <p>
                  Their ability to optimise the organic levers to help us jump
                  about ~6-7X of existing revenue per store at a sizeable base,
                  was the biggest takeaway.
                </p>
                <p>
                  Their acumen in optimising every data set is their main
                  strength, and it helps us to focus on the things that are big
                  needle movers! They are our go-to for all things on these
                  online platforms.
                </p>
              </div>
            </div>
            {/* <!-- div4 --> */}
            <div class="div4 eachdiv">
              <div class="userdetails">
                <div class="imgbox">
                  <img src={Prasuk} alt="" />
                </div>
                <div class="detbox">
                  <p class="name dark">Prasuk Jain</p>
                  <p class="designation dark">MD, Snow World Entertainment</p>
                </div>
              </div>
              <div class="review dark">
                {/* <h4>An overall wonderful and rewarding experience</h4> */}
                <h4 style={{ lineHeight: "1.4" }}>
                  Their expertise in the online platforms for the F&B space in
                  unmatched.
                </h4>
                <p>
                  Restaverse has not only helped entrepreneurs like me adapt but
                  also scale and thrive on the platform.
                </p>
              </div>
            </div>
            {/* <!-- div5 --> */}
            <div class="div5 eachdiv">
              <div class="userdetails">
                <div class="imgbox">
                  <img src={mdbhol} alt="" />
                </div>
                <div class="detbox">
                  <p class="name">Mohammed Bhol</p>
                  <p class="designation">Co-founder, House of Biryan</p>
                </div>
              </div>
              <div class="review">
                <h4>
                  The digital storefront has been a steep learning curve to
                  adapt, that’s where Restaverse as a platform has added immense
                  value.
                </h4>
                <p>
                  In my opinion, the digital storefront on these highly evolved
                  aggregator apps are only geared towards consumer experience
                  and as a business owner it is important to understand what
                  those metrics are and how to position oneself on the same.
                  This is beyond a product or a service but they’re partners in
                  how to co-build and scale your brand.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsGrid;
