import { Container, Grid, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ModalForm from "../../Modal/modal";
import ReadMoreTextArea from "../../ReadMore/readmoretextarea";

const CareerCard = ({ designation, location, description, setNotification ,setLoadingContent,
  setLoading}) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card style={{ padding: "16px", borderRadius: "12px", boxShadow: "none" }}>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          align="left"
          style={{
            paddingBottom: "8px",
            fontWeight: "bold",
            fontSize: "1.2em",
            fontFamily: "Poppins , sans-serif",
          }}
        >
          {designation}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          align="left"
          style={{
            color: "#6c41ff",
            fontWeight: "500",
            fontSize: "1.1rem",
            fontFamily: "Poppins , sans-serif",
          }}
        >
          Location: {location}
        </Typography>
        <br></br>
        <Divider
          style={{
            marginBottom: "16px",
            backgroundColor: "rgba(108, 65, 255, 0.5)",
          }}
        />
        <br></br>

        <Typography
          variant="body1"
          paragraph
          align="left"
          style={{
            paddingBottom: "16px",
            color: "#506690",
            fontFamily: "Poppins , sans-serif",
          }}
        >
          <ReadMoreTextArea reviewText={description} />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ModalForm
              triggerButton={
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    padding: "12px",
                    backgroundColor: "#6c41ff",
                    marginBottom: "16px",
                    fontWeight: "bold",
                    borderRadius: "6px",
                    fontFamily: "Poppins , sans-serif",
                  }}
                >
                  Apply
                </Button>
              }
              designation={designation}
              location={location}
              setNotification={setNotification}
              setLoadingContent={setLoadingContent} 
              setLoading={setLoading}
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default CareerCard;
