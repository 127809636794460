import React from "react";
import "./challenge.css";

const Solution = () => {
  const solutions = {
    1: {
      header: "SEO Overhaul",
      content: `By enhancing search visibility and refining their
cataloging process, UFC significantly improved the discoverability of
their products. This strategic adjustment led to an increase in
organic reach, ensuring that their offerings were more easily found by
potential customers, thereby optimizing their presence in a crowded
marketplace.`,

      src: "/images/UFC 2.png",
    },
    2: {
      header: "Supply Chain Optimization",
      content:
        "Utilizing sales trends and predictive analytics, UFC pinpointed operational inefficiencies, which allowed for the development of a responsive supply chain and inventory system, greatly improving their ability to meet demand and reduce instances of stockouts. This data-driven approach streamlined operations and enhanced overall performance.",
      src: "/images/UFC 3.png",
    },
    // 3: {
    //   header: "Omnichannel customer service",
    //   content:
    //     "Yellow.ai deployed Dynamic AI agent on multiple digital channels, capable of understanding customer queries and responding with high accuracy for tasks such as bookings, cancellations, retrieving vouchers or tickets, live agent support, and addressing any issues or queries related to Pelago or the travel domain.",
    //   src: "",
    // },
  };
  return (
    <div className="challenge">
      <div>
        <h3> Solution</h3>
        <hr></hr>
      </div>

      {Object.keys(solutions).map((key) => {
        const solution = solutions[key];
        return key % 2 === 0 ? (
          <div className="solution-item2" key={key}>
            <img src={solution.src} className="solution-img" alt="" />
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header">{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
          </div>
        ) : (
          <div className="solution-item1" key={key}>
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header">{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
            <img src={solution.src} alt="" className="solution-img" />
          </div>
        );
      })}
    </div>
  );
};

export default Solution;
