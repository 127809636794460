/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */

import React from "react";
import { useEffect } from "react";
import "../AllPoliciesCss/allpolicies.css";

const Headings = ({ headings, activeId }) => (
  <ul>
    {headings.map((heading) => (
      <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
        <a
          className="policiesheadings"
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            document.querySelector(`#${heading.id}`).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? "active" : ""}
              >
                <a
                  className="policiesheadingsdesc"
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  React.useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("main h2, main h3")
    );

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});
  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: document.querySelector("iframe"),
      rootMargin: "500px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <nav className="policies-nav" aria-label="Table of contents">
      <Headings headings={nestedHeadings} activeId={activeId} />
    </nav>
  );
};

const TermsConditons = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="policies-container">
      <main className="policies-container-main">
        <h2 className="policiesh2" id="introduction">
          Terms and Condition
        </h2>
        <h3 id="fees" className="policiesh3">
          1. FEES, EXPENSES, AND TERMS OF PAYMENT{" "}
        </h3>
        <p>
          a) For the Service Provider’s performance of its obligations under
          this Agreement, the Client shall pay to the Service Provider the fees
          described in Schedule A, which will be the Service Provider’s full
          compensation (the “Fees”). Fees shall be payable subject to the
          deduction of applicable taxes.
        </p>
        <p>
          b) Upon the execution of this Agreement, the Service Provider shall
          issue an initial invoice for the advance payment to be made by the
          Client. The duration covered by this advance payment and the specific
          terms will be determined based on the individual agreement with each
          Client. Following the receipt of the advance payment, the Service
          Provider shall onboard the Client and commence the provision of
          services as outlined in this Agreement.
        </p>
        <p>
          c) After the initial advance payment, the Service Provider will issue
          invoices for the subsequent periods of service provision. The
          frequency of these invoices (monthly, quarterly, etc.) and the periods
          they cover will be mutually agreed upon by the parties for each
          specific contract. Invoices for the upcoming service period shall be
          issued by the Service Provider by the 20th day of the preceding
          period. All invoices shall include the agreed-upon rates, along with
          any applicable Taxes or GST as per the prevailing tax rates, if
          applicable.{" "}
        </p>
        <p>
          d) The Client shall be liable to clear the quarterly invoices raised
          by the Service Provider within a time period of 15 (fifteen) days post
          invoice raised/submitted by the Service Provider. It is clarified that
          the Fees shall be completely paid, in case, the Fees are not received
          by the Service Provider, the Service Provider reserves its right to
          suspend providing the Services by issuing a written notice period of 7
          (seven) days.
        </p>
        <p>
          e) Service Provider shall, without recourse to Client, be fully and
          solely liable for (a) timely payment of all taxes, duties, levies
          (including but not limited to GST, etc.), and/or other statutory dues
          as applicable under relevant laws in relation to the provision of
          services under this Agreement and (b) any consequential demands,
          penalties, fines, damages, etc.{" "}
        </p>
        <p>
          f) The Service Provider undertakes to levy applicable GST in
          accordance with the GST Laws. Service Provider shall ensure to make
          payment of GST amount against the GST TIN Number of the jurisdiction
          as provided by Client to Service Provider in writing before placing
          the order for the supply of goods or services.
        </p>
        <p>
          g) It is agreed between the parties that all the out-of-pocket
          expenses incurred Service Provider in respect of Services being
          provided to the Client shall be charged as per actuals and shall form
          part of the invoices raised by the Service Provider. This would be
          cleared in 2 working weeks from the date of submission of the invoice.{" "}
        </p>
        <br></br>
        <h3 id="service-provider" className="policiesh3">
          2. OBLIGATIONS OF THE SERVICE PROVIDER{" "}
        </h3>
        <p>
          a) To execute/perform the services through the period of this
          agreement in accordance with the terms of this agreement.{" "}
        </p>
        <p>
          b) To use all its endeavors to provide the highest quality of services
          with all due care, diligence, and efficiency, in accordance with the
          industry standards of professional competence, organization, and
          responsibility.{" "}
        </p>
        <p>
          c) Further, the Service provider shall:
          <p>
            i. regularly update and obtain guidance and instructions from such
            persons as may be indicated by the Client from time to time in
            writing/email, on all matters under this Agreement
          </p>
          <p>
            ii. promptly comply with the instructions given by the Client from
            time to time, if the same is so agreed by the Service Provider
            subject to it being permissible under applicable law and practice,
            in connection with the performance of the Services
          </p>
          <p>
            iii. keep confidential any and all Confidential Information of the
            Client and its customers that it may be provided with or may come
            across in the Client networks or databases in the course of
            providing the Services
          </p>
          <p>
            d) The Service Provider shall appoint an Account Manager who shall
            be the point of contact for the Client for services to be provided
            by the Service Provider under the present Agreement.
          </p>
          <br></br>
          <h3 id="Obligation-client" className="policiesh3">
            3. OBLIGATIONS OF THE CLIENT s
          </h3>
          <p>
            a) To provide the details of the outstanding recoverable along with
            all necessary details.
          </p>
          <p>
            b) To provide and appraise all the details of legal action, copy of
            the notice, summons, or decree to the service provider on a
            best-effort basis.{" "}
          </p>
          <p>
            c) To make timely payments towards the fee and other agreed payments
            to the service provider{" "}
          </p>
          <p>
            d) To provide timely instructions and accurate information related
            to each of the Customer assignments and the Services to be provided{" "}
          </p>
          <p>
            e) To make the payment of consideration, fees, reimbursement, etc.
            within the time stipulated, irrespective of whether it is able to
            recover any amounts from its customers or not
          </p>
          <p>
            f) The Client shall not suppress any information or materials,
            suppression of which will affect/obstruct the Services provided or
            to be provided by the Service provider under this Agreement.
          </p>
          <br></br>
          <h3 id="LIABILITY" className="policiesh3">
            4. LIABILITY{" "}
          </h3>
          <p>
            a) The Service Provider's responsibility in the case of violation of
            obligations shall be limited to intent and gross negligence. In any
            case, liability shall be limited to foreseeable, contractually
            typical damages or as provided under this Agreement.{" "}
          </p>
          <p>
            b) The Service Provider shall be held accountable for the advice
            given on the basis of data provided by the Client.
          </p>
          <p>
            c) Client shall be liable to timely pay in the Fees as per Schedule
            A.{" "}
          </p>
          <p>
            d) Client shall be liable to provide the Service Provider the
            accurate and exact information in order for the Service Provider to
            fulfill its obligations under the present Agreement.{" "}
          </p>
          <br></br>
          <h3 id="Warranty" className="policiesh3">
            5. WARRANTY{" "}
          </h3>
          <p>
            a) The Service Provider hereby represents that it has the authority,
            skill, experience, and resources to render Services hereunder and
            shall render the Services in an ethical and bona fide manner and in
            compliance with all laws and regulations.{" "}
          </p>
          <p>
            b) The Service Provider is duly organized, validly existing, and in
            good standing under the laws of India and has full corporate power
            and authority to enter into this Agreement and perform its
            obligations under this Agreement.{" "}
          </p>
          <p>
            c) Service Provider has agreed to obtain all necessary licenses,
            permissions, and registrations (as applicable).{" "}
          </p>
          <p>
            d) No Client officer, director, employee, or immediate family member
            has received or will receive anything of value of any kind from the
            Service Provider, or its officers, directors, employees, or agents
            in connection with this Agreement; and no Client personnel have a
            business relationship of any kind with Service Provider or its
            officers.{" "}
          </p>
          <br></br>
          <h3 id="trademarks" className="policiesh3">
            6. USE OF TRADEMARKS{" "}
          </h3>
          <p>
            a) Service Provider hereby acknowledges Client rights, title, and
            interest in its trademarks, logos, trading styles, and other
            intellectual property (“Intellectual Property”) of Client and agrees
            and undertakes not to contest, at any time or do or cause to be
            done, any act or thing, directly or indirectly, which impairs
            Client’s right, title or interest in its Intellectual Property.
          </p>
          <p>
            b) Service Provider further agrees that it shall not adopt, whether
            during the term of this Agreement or at any time after its
            termination or expiry, any mark, word, symbol, slogan, usage, etc.
            which in any way infringes the Intellectual Property rights of
            Client or those of its parent or affiliate companies.
          </p>
          <br></br>
          <h3 id="INDEMNITY" className="policiesh3">
            7. INDEMNITY{" "}
          </h3>
          The Parties agree to indemnify, defend and hold harmless the other
          party, its affiliates, and their respective officers, directors,
          employees, and agents, harmless from and against any claims, demands,
          loss, damages, liability, causes of action, losses, proceedings,
          judgments, or reasonable costs and expenses of every nature (including
          reasonable attorney’s fees and expenses) arising out of:
        </p>
        <p>
          i. the commission or omission of the Services under this Agreement
        </p>
        <p>
          ii. any acts of gross negligence or misconduct of the other Party, its
          employees, agents, and contractors
        </p>
        <p>iii. non-compliance with any of the applicable laws and statutes </p>
        <p>
          iv. any fines, or penalties levied by the Government Authorities
          concerning the performance of obligations under this Agreement{" "}
        </p>
        <p>
          v. infringement of any third-party patent, trademark, or copyright{" "}
        </p>
        <p>
          vi. any actual or threatened breach of its confidentiality obligations{" "}
        </p>
        <p>vii. breach of any of the terms of this Agreement. </p>
        <br></br>
        <h3 id="Termination" className="policiesh3">
          8. TERMINATION{" "}
        </h3>
        <p>
          a) Service Provider and Client reserve their right to individually
          terminate the services of Service Provider at any location, with or
          without cause, at any time during the term of the Agreement by
          delivering not less than Thirty (30) days written notice of such
          individual terms of the location to Service Provider. In that event,
          the Service Provider agrees and undertakes to continue its services
          under this Agreement in the remaining areas subject to terms and
          conditions.{" "}
        </p>
        <p>
          b) Each Party shall have the right to terminate, within immediate
          effect, this Agreement in its entirety upon giving written
          notification to the other Party, if such other Party has committed a
          breach of this Agreement and the breaching Party does not rectify such
          breach within the notice period of 15 (fifteen) days from the date of
          notice of such breach. Such termination shall be without prejudice to
          any other remedy to which the non-breaching Party may be entitled. The
          failure of either Party to terminate this Agreement for breach of any
          conditions or covenant shall not affect its right to terminate it for
          subsequent breaches of the same or other conditions or covenants.{" "}
        </p>
        <p>
          c) Either Party may terminate this Agreement effective upon issuance
          of written notice if, at any time, the other party files a petition in
          bankruptcy, or a petition for insolvency is admitted before
          adjudicating authority or enters into an arrangement with its
          creditors, or applies for or consents to the appointment of a receiver
          or trustee, or makes an assignment for the benefit of creditors, or
          suffers or permits the entry of an order adjudicating it to be
          bankrupt{" "}
        </p>
        <p>
          d) Notwithstanding anything to the contrary contained anywhere else in
          this entire agreement; this agreement may be terminated by either
          Party by giving Thirty (30) days notice in writing even without
          assigning any reason for the sake of convenience.
        </p>
        <br></br>
        <h3 id="Arbitration" className="policiesh3">
          9. ARBITRATION
        </h3>
        <p>
          {" "}
          Arbitration of this Agreement unless settled amicably between the
          parties shall be referred for Arbitration. The arbitration proceedings
          will be conducted by the sole arbitrator under the Arbitration &
          Conciliation Act, 1996 & any subsequent related amendments thereto.
          The arbitration proceedings shall be conducted in English and held in
          Mumbai. The cost of arbitration proceedings shall be borne by both
          parties in equal proportions{" "}
        </p>
        <br></br>
        <h3 id="genral-provisions" className="policiesh3">
          10. GENERAL PROVISIONS{" "}
        </h3>
        <p>
          a) Governing Law & Jurisdiction: This Agreement and all matters
          related to it shall be governed, interpreted, and construed in
          accordance with the laws of India and subject to the exclusive
          jurisdiction of the courts of Mumbai.{" "}
        </p>
        <p>
          b) Waiver – Failure by the Client at any time to enforce any
          obligation by the Service Provider or to claim a breach of any term of
          this Agreement or to exercise any power of obligation under this
          Agreement will not affect any subsequent breach and will not prejudice
          Client as regards any subsequent action.{" "}
        </p>
        <p>
          c) Severability – If any term or provision of this Agreement should be
          declared invalid by a court of competent jurisdiction, the remaining
          terms and provisions of this Agreement shall remain unimpaired and in
          full force and effect.{" "}
        </p>
        <p>
          d) Modification – No modification, waiver, or amendment of any term or
          condition of this Agreement shall be effective unless and until it
          shall be reduced to writing and signed by Client and Service Provider.
          All legally valid and applicable amendments will automatically become
          part of this Agreement.{" "}
        </p>
        <p>
          e) Survival – The provision of this Agreement that by their nature and
          context are intended to survive the performance hereof shall survive
          the completion and termination of this Agreement.{" "}
        </p>
        <p>
          f) Force majeure -It is understood by the parties that all events
          beyond their respective control being Force Majeure or Acts of God,
          including fire, flood, war, lightning, earthquake, Government
          policies, etc., which have the effect of preventing the performance of
          the Agreement by the Parties so affected shall be construed to as
          suspension of the obligation to perform by the affected Party. In the
          event aforesaid force, majeure event continues for a period of 30
          days, Client may terminate this Agreement by giving 30 days written
          notice to Service Provider.
        </p>
        <p>
          g) Relationship – The relationship between the Service Provider and
          Client shall be principal to principal basis.{" "}
        </p>
        <p>
          h) Assignment – The Service Provider shall not assign any of its
          obligations to any other third party without the written consent of
          the Client.{" "}
        </p>
        <h3 id="genral-provisions" className="policiesh3">
          11. Disclaimer:{" "}
        </h3>
        <p>
        Please note that this software is currently in the early stages of release.
        While we are committed to providing a robust and reliable experience, 
        there may be occasional glitches or service outages as we continue to refine and enhance the system. 
        As a token of appreciation for your early adoption, we are offering a special early bird pricing.
        Your feedback during this period is invaluable and will help us deliver a better product.
        </p>
        {/* <br></br> */}
        {/* <h2 id="Schedule" className="policiesh2">Schedule A</h2>
        <h3 id="Service-Provider-agrees" className="policiesh3">
          1. Service Provider agrees and undertakes to provide the services
          including but not limited to the following to Client:
        </h3>

        <p>
          {" "}
          1.1) Organic Ranking - Search engine optimization on these platforms.
          This can be tracked and gauged with the help of metrics such as
          impressions, and menu opens/sessions.
        </p>
        <p>
          1.2) Advertising - Guidance and optimization on these platforms. Fixed
          monthly budgets shall be allocated for advertising spends pre-hand and
          a projected revenue attribution from those spends shall be highlighted
        </p>
        <p>
          1.3) Discounts - Suggestions around customer offers on these
          platforms. Our endeavor is to keep them in check periodically and post
          the initial customer penetration, we will help to optimize the
          discounts per order or discounts as a % of a subtotal to ensure that
          operationally the restaurant operating margins become better
        </p>
        <p>
          1.4) Customer metrics – Highlighting and suggesting ways to improve
          them. Conversions, Menu to cart, cart to order, Menu to order, and
          Impression to the menu are the metrics that will be covered here.
          Monthly reports shall be shared in regard to the performance metrics.
          Dedicated POC shall be in place for the brand to help them with all
          the above.
        </p>
        <p>
          1.5) Operational metrics – Highlighting and suggesting ways to
          optimize them. Definite monthly reports shall be shared and discussed
          upon them as these would be in complete control of the restaurant
          partner/client. Food rating, page rating, kitchen prep time,
          rejection, grid visibility, serviceability, IGCC, and ORS are the
          operational metrics
        </p>
        <p>1.6 POS order integration is mandatory </p>
        <h3 id="the-client-agrees" className="policiesh3">
          2. The client agrees and undertakes that they will maintain the
          following for the entire duration of the engagement:
        </h3>
        <p>
          2.1) Food rating: The client will maintain a monthly average food
          rating above 4.2 across both platforms i.e. Zomato and swiggy
        </p>
        <p>
          2.2) Page rating: The client will maintain the brand's page rating on
          Zomato above 4.0 and swiggy above 4.2 for all the months Kitchen prep
          time: The client will maintain the kitchen prep time below 20 minutes{" "}
        </p>
        <p>
          2.3) Rejection: The client will maintain the rejection rates below
          0.5% across all the meal times
        </p>
        <p>
          2.4) Grid visibility: The client will maintain visibility rates above
          98% per store per active meal times{" "}
        </p> */}
      </main>
      <TableOfContents />
    </div>
  );
};

export default TermsConditons;
