import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import five from '../../images/blogs/5.png';
import six from '../../images/blogs/6.png';
import './blog.css';
const Blogthree = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Unlocking the Potential of Automation in Restaurant Review Management
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Manish Swami"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/manish-swami-491719184/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Manish Swami</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: May 28, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                A stellar review can create a buzz of new diners eager to try your restaurant, while a negative review might send potential guests looking elsewhere.In the competitive restaurant industry, <b style={{fontWeight:500}}>online reviews have the power to elevate or sink your establishment's reputation.</b> Juggling these reviews manually as your restaurant grows is no small feat. That’s where <b style={{fontWeight:500}}>automated review management</b> comes into play.
                                <br></br><br></br>
                                This smart solution <b style={{fontWeight:500}}>streamlines the process, saving your precious time and ensuring your responses are swift and consistent.</b> It not only turns potential challenges into opportunities for growth but also aids in fast decision-making, allowing you to address issues proactively and seize opportunities for improvement more efficiently.
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={five}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"

                                }}
                            >
                                1. Consistency
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In the restaurant industry, a consistent approach to review responses is vital for a positive brand image. Automation ensures every response—positive or negative—maintains a uniform tone and professionalism.
                                <br></br><br></br>
                                This consistency extends to essential elements such as proper salutations, clear calls to action, and a standardized signature, ensuring that every response reflects your restaurant’s values and maintains a polished presentation. Automated review management supports this consistency, enhancing trust and showcasing your commitment to superior customer service and meaningful interactions with your patrons.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Real-time Alerts
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In the realm of restaurant reviews, real-time alerts can be provided in two main ways. <b style={{fontWeight:500}}>First,</b> alerts are triggered by the receipt of negative reviews, such as <b style={{fontWeight:500}}>1-star, 2-star, or 3-star ratings,</b> which immediately notify you of potential problems or customer dissatisfaction. This prompt information allows for swift intervention.
                                <br></br><br></br>
                                The <b style={{fontWeight:500}}>second</b> type involves alerts based on specific keywords that <b style={{fontWeight:500}}>highlight recurring themes or concerns,</b> guiding you to important aspects of customer feedback that need attention. Together, these alert systems help you manage your restaurant's reputation proactively and respond effectively to both general and specific feedback.
                                <br></br><br></br>
                                This immediate attention can greatly improve your restaurant's reputation, highlighting your appreciation for guest feedback and your commitment to resolving concerns swiftly.

                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Personalize Responses

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Even when using <b style={{fontWeight:500}}>automated systems,</b> it’s essential to <b style={{fontWeight:500}}>inject a personal touch into your responses. Addressing customers by their names and mentioning specific details</b> from their reviews adds a layer of authenticity to the interaction. <b style={{fontWeight:500}}>This personalization shows that your replies are not just automated</b> but are crafted with attention to individual experiences.
                                <br></br><br></br>
                                <b style={{fontWeight:500}}>AI systems, refined through machine learning,</b> are adept at understanding and responding to restaurant-related reviews with growing accuracy. By incorporating this advanced technology, you can ensure that <b style={{fontWeight:500}}>responses are both relevant and personalized.</b> Personalizing your replies <b style={{fontWeight:500}}>helps forge a closer connection with your guests,</b> making them feel appreciated and understood. It <b style={{fontWeight:500}}>elevates automated replies from mere formality to genuine exchanges,</b> deepening your relationship with customers and enhancing their overall experience.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',
                                    maxWidth: '550px',
                                    height: 'auto',
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Automate Your Responses Now! Save time, improve customer satisfaction, and maintain a 5-star reputation by effortlessly managing your online reviews and messages. Join Retaverse now and let automation work for you!

                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Sentiment Analysis
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Gaining insights into how both new and repeat users perceive a product or service is made significantly easier through sentiment analysis. By <b style={{fontWeight:500}}>analyzing reviews,</b> it provides a <b style={{fontWeight:500}}>deeper understanding of how first-time users feel about their experiences,</b> highlighting whether they are satisfied or facing issues that need attention. For <b style={{fontWeight:500}}>repeat users,</b> sentiment analysis can <b style={{fontWeight:500}}>track consistency in satisfaction,</b> helping to ensure that long-term customer loyalty is maintained.
                                <br></br><br></br>
                                By <b style={{fontWeight:500}}>comparing sentiment patterns between new and repeat users,</b> businesses can identify if their service is consistently meeting expectations or needs improvement.Furthermore, <b style={{fontWeight:500}}>sentiment analysis enables businesses to rank stores or locations according to their performance by evaluating user feedback.</b>This process enables companies to <b style={{fontWeight:500}}>identify their top-performing locations while addressing areas that may require improvement.</b>
                                <br></br><br></br>
                                By adopting this <b style={{fontWeight:500}}>data-driven approach,</b> businesses can gain <b style={{fontWeight:500}}>valuable insights into customer satisfaction and operational effectiveness,</b> which in turn leads to <b style={{fontWeight:500}}>improved customer experiences and fosters business growth.</b>

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                               5. Monitor Trends and Insights
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Leveraging automated tools to track and analyze customer sentiment allows companies to gain valuable insights into feedback trends. By tracking patterns and identifying recurring themes, businesses can gain deeper insights into customer concerns and preferences.
                                <br></br><br></br>
                                This data-driven methodology allows businesses to make <b style={{fontWeight:500}}>precise and strategic operational improvements,</b> ensuring they <b style={{fontWeight:500}}>meet customer expectations with greater accuracy.</b> By systematically <b style={{fontWeight:500}}>identifying and addressing recurring issues or trends,</b> companies can make ongoing <b style={{fontWeight:500}}>improvements to their service quality.</b> Ultimately, this leads to a more satisfying and refined customer experience.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={six}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                              6. Operational Improvements
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Constructive handling of negative reviews can reveal significant opportunities for operational improvements. By analyzing and identifying recurring patterns in negative feedback, restaurants can gain a <b style={{fontWeight:500}}>clearer understanding of specific issues</b> impacting the customer experience. 
                                <br></br><br></br>
                                This enhanced understanding allows them to implement targeted changes and improvements, leading to a <b style={{fontWeight:500}}>more refined and satisfying overall dining experience.</b> Addressing these concerns not only improves service quality but also strengthens customer satisfaction and loyalty.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                CONCLUSION
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Automating your restaurant’s review management can revolutionize how you handle feedback, ensuring timely, consistent, and personalized responses. Utilize real-time alerts, sentiment analysis, and trend monitoring to address issues swiftly, boost customer satisfaction, and drive meaningful operational enhancements.
                                <br></br><br></br>
                                Embrace automation to transform your review management, elevate your restaurant’s reputation, and unlock unparalleled success in a competitive landscape.
                                Get ready to stand out, captivate your guests, and drive extraordinary growth!


                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogthree;