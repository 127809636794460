import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import twentyseven from '../../images/blogs/27.png';
import twentyeight from '../../images/blogs/28.png';
const Blogfourteen = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Simplify to Succeed: How Streamlined Menus Lead to Higher Margins
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Charmi Jain"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/charmi-jain-a7a9a8197/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Charmi Jain</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 8, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Unlock the secret to restaurant success by transforming your menu into a powerhouse of efficiency and flavor! By crafting dishes around a select range of core ingredients, you can significantly cut down on complexity in the kitchen. This method reduces food waste, lowers costs, and allows for more precise inventory management. With a simplified menu, your team can work more efficiently, ensuring consistent quality in every dish while keeping expenses in check.
                                <br></br>
                                <b style={{fontWeight:500}}>Say goodbye to unnecessary overhead and hello to a more profitable, smoothly run operation</b> that keeps both your customers and your bottom line satisfied! It's time to simplify, save, and serve up success with a menu that works as hard as you do!
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentyseven}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Manage Labor Costs Effectively
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Most restaurants aim for a labor cost percentage somewhere between <a href='https://www.restaurant365.com/blog/how-to-calculate-labor-cost-percentage/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>25%-35%</a> of sales.It is essential to cut down on labor costs. By focusing on a smaller set of core ingredients, you simplify kitchen operations, allowing your staff to work more efficiently and reduce the time spent on meal prep. This reduces the need for extra staff, cutting labor costs and boosting efficiency. Chefs can quickly <b style={{fontWeight:500}}>master the menu, ensuring consistency and minimizing waste.</b>
                                <br></br>
                                Cross-training staff on a streamlined menu also enhances flexibility, making it easier to manage busy periods or staff shortages. Ultimately, a simplified menu lowers costs and keeps your restaurant running smoothly for long-term success.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Minimize Food Waste
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Food waste is a major concern in the restaurant industry. Globally, restaurants throw out approximately <a href='https://www.nrdc.org/sites/default/files/wasted-2017-report.pdf' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>3 billion</a> tons of food each year. Simplifying your menu to fewer core ingredients helps cut waste by reducing spoilage and streamlining usage.
                                <br></br><br></br>
                                This strategy also allows for bulk purchasing, which <b style={{fontWeight:500}}>lowers food costs and reduces delivery frequency, saving on transportation and cutting your carbon footprint.</b> A more manageable inventory improves tracking and forecasting, preventing overstocking.  Overall, this approach boosts your bottom line and supports eco-friendly practices that attract sustainability-minded customers.
                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Enhanced Kitchen Operations
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                When your kitchen staff has fewer dishes to prepare, they can execute each meal faster and with more consistency. A streamlined menu allows your team to master the dishes, leading to improved quality control and quicker preparation times. <b style={{fontWeight:500}}>Faster preparation times can increase table turnover rates by approximately 10% (Source: Toast POS).</b> The end result is a more efficient kitchen that can handle a higher volume of orders, ultimately allowing you to serve more customers and increase your restaurant’s profitability.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Improved Customer Delight
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <b style={{fontWeight:500}}>Simplified menus can decrease the time customers spend making decisions by up to 25% (Source: Harvard Business Review).</b> When faced with an overload of choices, customers can become frustrated and take longer to order. A well-curated menu simplifies their decision-making process, leading to faster and more confident choices. This leads to faster service and a better overall experience. Happy customers are more likely to return and leave positive reviews, boosting your restaurant’s reputation.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentyeight}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Swift Staff Development
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Training staff can be challenging, especially with a large, complex menu. A streamlined menu simplifies this process, <b style={{fontWeight:500}}>allowing new hires to learn faster and reducing training time and costs.</b> Fewer dishes and ingredients mean new hires can learn quickly, cutting down on training time and costs. A focused menu allows staff to become proficient faster, enhancing food quality and service.
                                For experienced team members, a focused menu offers the chance to perfect their skills and mentor others. Instead of constantly learning new dishes, they can <b style={{fontWeight:500}}>refine their techniques and support new hires.</b> This creates a cohesive team that delivers a seamless, high-quality dining experience.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Conclusion
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Simplifying your menu is a strategic move that leads to better control over costs, more efficient operations, and ultimately, higher margins. By narrowing your focus to a well-curated selection of dishes, you create a more enjoyable experience for both your customers and your staff.
                                <br></br> <br></br>
                                If you're ready to explore how <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse</a> can help streamline your menu management and improve your restaurant’s efficiency, get in touch with us today!

                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogfourteen;