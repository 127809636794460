import React from "react";
import "./challenge.css";

const SolutionSection = ({solutions}) => {
 
  return (
    <div className="challenge">
      <div>
        <h3> Solution</h3>
        <hr></hr>
      </div>

      {Object.keys(solutions).map((key) => {
        const solution = solutions[key];
        return key % 2 === 0 ? (
          <div className="solution-item2" key={key}>
            <img src={solution.src} className="solution-img" alt="" />
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header">{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
          </div>
        ) : (
          <div className="solution-item1" key={key}>
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header">{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
            <img src={solution.src} alt="" className="solution-img" />
          </div>
        );
      })}
    </div>
  );
};

export default SolutionSection;
