import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import fifteen from '../../images/blogs/15.png';
import sixteen from '../../images/blogs/16.png';
const Blogeight = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            How to Respond to Restaurant Reviews to Build Trust and Loyalty
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Valeron Toscano"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/valeron-toscano/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Valeron Toscano</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Sep 13, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Every review your restaurant receives is a chance to create a story, one that turns first-time diners into lifelong fans. <b style={{fontWeight:500}}>Imagine transforming a simple comment into a loyal customer</b> who can’t wait to return! By responding to reviews with <b style={{fontWeight:500}}>energy and authenticity,</b> you’re not just addressing customer feedback—you’re igniting excitement and building a community of loyal patrons. <b style={{fontWeight:500}}>Your responses have the power to turn ordinary interactions into memorable experiences</b> that elevate your brand and keep customers coming back for more.
                                <br></br>
                                Ready to make every word count and leave a lasting impression?  It all starts with how you connect with your reviewers.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={fifteen}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Swift and Responsive Communication
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Responding to reviews within <b style={{fontWeight:500}}>24 hours saw a 15% increase in positive sentiment</b>(Review trackers). This quick response time adds a personal touch to your acknowledgment, demonstrating that you value and appreciate your customers’ feedback. <b style={{fontWeight:500}}>Prompt replies</b> not only enhance the impact of your responses but also help in building a positive image of your restaurant. By addressing reviews swiftly, you show your commitment to customer satisfaction and keep the enthusiasm high.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Personalize your response
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                By addressing reviewers by name and referencing specific details from their feedback, you show that you value their individual experiences and are attentive to their concerns. Personalized responses can <b style={{fontWeight:500}}>improve customer retention by up to 30%</b> (Zendesk). Addressing both praise and constructive suggestions makes your responses more engaging. Ultimately, personalized replies enhance customer satisfaction and build loyalty.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Highlight Positive Aspects
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Acknowledging positive points in reviews allows you to highlight your business’s strengths. When a reviewer commends your customer service, express your gratitude and highlight your team’s dedication to excellence. Highlighting <b style={{fontWeight:500}}>positive feedback can lead to a 10-15% increase in brand loyalty</b> (Bright local). Acknowledging these positive aspects not only shows appreciation but also reinforces the strengths and values that differentiate your business.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Address concerns and offer a solution (if any) 
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                               Effectively addressing concerns can <b style={{fontWeight:500}}>increase customer retention by 20-25%</b>(PWC). When a reviewer highlights an issue, acknowledge their concern with empathy and explain the steps you’re taking to resolve it. Offering a clear solution demonstrates proactivity and dedication to customer satisfaction. This approach not only helps address the immediate concern but also reassures other potential customers that you value feedback and are committed to continuous improvement.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Invite further dialogue 
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                If the issue is complex or needs further discussion, invite the reviewer to continue the conversation offline. Offer a direct contact method like an email or phone number for a more detailed resolution. This demonstrates your dedication to resolving issues thoroughly and personally. Handling sensitive issues privately can improve public perception and lead to a <b style={{fontWeight:500}}>10-15% increase in positive reviews</b>(Harvard business review) and ensure the customer feels valued.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                6. Keep it brief and professional
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                While personalizing responses to reviews is crucial, maintaining professionalism and conciseness is equally important. Use clear, <b style={{fontWeight:500}}>respectful language</b> when addressing feedback. Keep responses brief and constructive, focusing directly on the issues raised. This approach reinforces your restaurant's dedication to quality and customer satisfaction.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={sixteen}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                7. Welcome Future Opportunities
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                              Encouraging future visits by inviting customers to return can increase return rates by 20-30% (Yotpo). Express that you value their feedback and are committed to continually improving their experience. Let them know their satisfaction is a priority. Reinforce your commitment to quality and express eagerness to welcome them back to your restaurant soon. 
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                FINAL THOUGHTS 
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Incorporating feedback into your restaurant’s service strategy begins with a thoughtful response to each review. By being timely, personal, and professional, you show your customers that their feedback is valued and acted upon.  By acknowledging positive aspects, addressing concerns empathetically, and inviting further dialogue, you show your commitment to enhancing customer experiences. As you invite patrons back and showcase your dedication to continual improvement, you turn positive experiences into lasting impressions, reinforcing your restaurant’s reputation and encouraging repeat business.
                                <br></br><br></br>
                                Transform your customer interactions with <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse!</a> Discover how our platform can help you respond effectively, build stronger customer relationships, and enhance your reputation. Get started with Restaverse and make every interaction count!

                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogeight;