import Container from "react-bootstrap/esm/Container";
import { Grid } from "@mui/material";
import CareerCard from "../CareerCard/careercard";
import Notification from "../../notification/notification";
import { useState } from "react";

const CareerSection4Cards = () => {
  const [notification,setNotification] = useState(false);
  const [loading,setLoading] = useState(false);
  const [loadingContent,setLoadingContent] = useState('');
  // const notify = () => {

  //   setNotification(true)

  //   setTimeout(() => {
  //     console.log("Delayed for 1 second.");
  //     setNotification(false)
  //   }, 4000);

  // }
  return (
    <Grid container spacing={6}>
      { notification&&<Notification content={loadingContent} loading={loading}/>}
      {[
        {
          designation: "Business Strategy",
          location: "PAN India",
          description:
            "Strategic role managing projects across digital platforms, shaping strategy for restaurants and D2C food brands.",
        },
        {
          designation: "City Lead",
          location: "PAN India",
          description:
            "Manage city operations, drive growth, oversee teams, strategize sales, customer satisfaction, analyze market trends.",
        },
        {
          designation: "Operations Manager",
          location: "PAN India",
          description:
            "Oversee daily operations, optimize efficiency,solve restaurant operational challenges and customer and client satisfaction.",
        },
        {
          designation: "Marketing Manager",
          location: "Mumbai, India",
          description:
            "Lead content strategy, manage campaigns, analyze trends, and drive marketing for client and brand growth.",
        },
        {
          designation: "Full Stack Developer",
          location: "Mumbai, India",
          description:
            "Full-stack engineer for pivotal role, driving platform innovation, design, implementation and growth at Restaverse.",
        },
        {
          designation: "Sales Manager",
          location: "PAN India",
          description:
            "Acquire new clients, manage relations, analyze data, monitor trends, and enhance operational efficiency.",
        },
      ].map((job, index) => (
        <CareerCard key={index} {...job} setNotification={setNotification} setLoadingContent={setLoadingContent} setLoading={setLoading} />
      ))}
    </Grid>
  );
};

export default CareerSection4Cards;
