import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import greenrhome from "../../images/CaseStudy/greenr/Greenr Home.webp";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import greenrowner from "../../images/CaseStudy/greenr/Greenr.webp"
import greenrone from '../../images/CaseStudy/greenr/Greenr 1.webp';
import greenrtwo from '../../images/CaseStudy/greenr/Greenr 2.webp';


const Greenr = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  // const challengesection =

  useEffect(() => {
    document.title = "Case Study | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
 

  const infoAboutCompany = {
    Brand: `Greenr Cafe`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Healthy food",
    Location: "Mumbai & Delhi",
    AOV: "High",
    "Target Audience": "Premium",
  };

  const challenge = {
    text1: "Boosting Visibility In Online Platforms",
    text2: `Greenr Cafe, known for its quality food and positive customer feedback, faced a significant challenge of low brand awareness on digital platforms. Despite offering nutritious food that resonated well with their customers, the brand struggled to reach a wider audience. This lack of awareness hindered potential growth opportunities and prevented the cafe from maximising its customer base and market presence.
`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 56,
        figurePrefix: "",
        figuresPostfix: "%",
        content: "Increase in overall Revenue",
      },
      card2: {
        header: "Conversion",
        figures: 110,
        figuresPostfix: "%",
        content: "Increase in overall Menu Opens",
      },
      card3: {
        header: "Orders Conversion",
        figures: 63,
        figuresPostfix: "%",
        content: "Increase in overall Orders",
      },
    },
    owner_views: {
      title: "Greenr Cafe",
      description: `Restaverse has been Greenr's first attempt at using technology to enable business growth. The entire team is so well versed with the gamut of digital food commerce. They not only identify the gaps, but also find solutions to filling them, in order to optimise business. They brought in a new genre of audience pool to Greenr. I'm glad we are associated with them and thankful for them in helping us know our business and consumer better`,
      name: "Vaibhav Nagori",
      designation: "Co-Owner Greenr Cafe",
      owner_img:greenrowner
    },
  };


    const solutions = {
      1: {
        header: " Exclusive Online Menu",
        content: `We conducted a thorough review and optimization of Greenr Cafe's menu to enhance customer engagement and increase conversions. We revised and enriched their menu to highlight unique selling points and evoke sensory appeal, thereby making the menu items more enticing to potential customers. Overall we aimed to stimulate the appetite and drive orders directly from the menu. `,
        src: greenrone,
      },
      2: {
        header: "Implemented Targeted Promotions",
        content:
          "To expand Greenr Cafe's reach and attract new customers, we implemented targeted promotional strategies. We introduced limited-time discounts and special offers on select menu items to incentivize trial and repeat purchases. Leveraging digital marketing channels, we ran targeted ads to increase visibility and awareness of Greenr Cafe among potential customers in the local community. These campaigns focused on highlighting the cafe's unique offerings and enticing new customers to visit or place orders.",
        src: greenrtwo,
      },
 
    };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
          <div
            className="casestudy"
            style={{ fontFamily: "Poppins , sans-serif" }}
          >
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                Greenr Cafe: Customer Engagement Enhancement through Strategic Menu Optimization
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={greenrhome} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
                {/* <Challenge/> */}
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions}/>
              </section>

              <section id="impact">
                {/* <Impact /> */}
                <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

              <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default Greenr;
