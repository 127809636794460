import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import home2 from "../../../images/home/Homepage2.webp";
import { StyledButton } from "../../../Pages/home2/home2";
import "./HomeSection4.css";

const features = [
  "Tailored Solutions for Restaurants",
  "Innovation-Driven Approach",
  "Customer-Centric Engagement",
  "Proven Track Record",
];

const HomeSection4 = (isSmallScreen) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  console.log(isSmallScreen);

  return (
    <div className="" ref={ref}>
      {inView && (
        <Grid container spacing={5}>
          {/* Left Section */}
          <Grid
            item
            xs={12}
            sm={6}
            className="aligh-item-center center-the-element"
            // style={{margin:isSmallScreen?'20px':'initial'}}
          >
            <Box
              className="career-main-section1-left-image aligh-item-center"
              style={{ height: "100%", zIndex: 1, padding: 0 }}
            >
              <img
                className="img-fluid"
                src={home2}
                alt=""
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  // transform: "scale(1)",
                  // margin:isSmallScreen?'20px':'0px'
                }}
              />
            </Box>
          </Grid>

          {/* Right Section */}
          <Grid
            item
            xs={12}
            sm={6}
            className="know-more-container-text section-container-text homesection4text"
          >
            <Box
              className="center-the-container"
              style={{ paddingLeft: "60px" }}
            >
              <h2
                className="career-section1-right-header animate-from-top"
                style={{ fontSize: "2rem", paddingTop: "30px" }}
              >
                Intelligent Insights, Intuitive Interface
              </h2>
              <br></br>
              <p className="career-section1-right-text fade-in-slow">
                Unlock the full potential of your restaurant with Restaverse's
                cutting-edge analytics and AI-driven platform.
              </p>
              <br></br>
              {/* Features List */}
              <Grid container spacing={2}>
                {features.map((feature, index) => (
                  <Grid item xs={6} key={index}>
                    <Box display="flex">
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          color: "#6c41ff",
                          padding: "8px",
                          backgroundColor: "#6c41ff1a",
                          borderRadius: "50%",
                        }}
                      />
                      <span className="know-more-container-list-items-text">
                        {feature}
                      </span>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={12} sm={4}>
                  {/* <Button
                    variant="outlined"
                    component={Link}
                    to="/services"
                    // color="#6C41FF"
                    style={{
                      width: isSmallScreen ? "90%" : "30%",
                      color: "white",
                      backgroundColor: "#6C41FF",
                      textTransform: "none",
                      fontFamily: "Poppins , sans-serif",
                    }}
                  >
                    View Details
                  </Button> */}
                  <br></br>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to="/services/know-more"
                    style={{ color: "white" }}
                  >
                    View Details
                  </StyledButton>
                </Grid>
              </Grid>

              <br />
              {/* <Grid>
                <Grid>
                  <Button
                    variant="outlined"
                    component={Link}
                    to="/services"
                    // color="#6C41FF"
                    style={{
                      width: isSmallScreen ? "90%" : "30%",
                      color: "white",
                      backgroundColor: "#6C41FF",
                      textTransform: "none",
                      fontFamily: "Poppins , sans-serif",
                    }}
                  >
                    View Details
                  </Button>
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default HomeSection4;
