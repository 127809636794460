import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import three from '../../images/blogs/3.png';
import four from '../../images/blogs/4.png';
import './blog.css';

const Blogtwo = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14}, // Custom height (default is 4px)
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1', // Change the filled color of the progress bar
                    },
                    backgroundColor: '#D3D3D3', // Change the unfilled background color

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            From Feedback to Action: Tips for Tracking Restaurant Reviews
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Charmi Jain"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex',  alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/charmi-jain-a7a9a8197/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Charmi Jain</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: July 29, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                In the fast-paced world of dining, where every guest’s opinion can echo across the internet, mastering the craft of restaurant review monitoring has never been more critical.
                                With platforms like Google, Zomato, and TripAdvisor allowing diners to share their experiences with the world, these reviews often serve as personal recommendations to a global audience. To stay competitive, restaurant marketers must prioritize monitoring and managing reviews to safeguard their brand reputation and stay attuned to customer feedback.
                                <br></br>
                                Research and statistics on customer reviews reveal that:
                                <br></br>
                                <Typography sx={{
                                    textAlign: 'justify',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                    marginLeft: '10px'
                                }}>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        margin: 0,

                                    }}>
                                        <li style={{ listStyleType: 'disc' }}><a href='https://pos.toasttab.com/blog/on-the-line/grow-restaurant-reviews' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}> 43% of people </a> won’t go to a restaurant if its rating is below 3-3.5 stars.</li>
                                        <li style={{ listStyleType: 'disc' }}><a href='https://bloomintelligence.com/blog/how-restaurant-ratings-reviews-affect-business/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>92% of consumers</a> read restaurant reviews before visiting it.</li>
                                        <li style={{ listStyleType: 'disc' }}><a href='https://bloomintelligence.com/blog/how-restaurant-ratings-reviews-affect-business/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}> 77% of diners</a> rely on peer reviews over critic reviews.</li>
                                    </ul>
                                </Typography>

                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={three}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>


                            <Typography
                                color="black"
                                gutterBottom={true}
                                variant="h4"
                                component="p"
                                sx={{
                                    marginTop: "30px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    lineHeight: "1.77777778",
                                    color: "#213343"
                                }}
                            >
                                4 Proven Tips for Effective Review Monitoring:
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                From managing a sprawling franchise to a cozy local eatery, <b style={{fontWeight:500}}>reviews are the game-changer for your brand's reputation and revenue.</b> As diners post their experiences online, how can you jump into the conversation and make a splash?  Let’s explore how to harness the power of reviews to boost your brand and drive success!

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"

                                }}
                            >
                                Claim Your Local Listings
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Securing your restaurant’s <b style={{fontWeight:500}}>local listings is essential</b> for top-notch review management and a <b style={{fontWeight:500}}>strong online presence.</b> These listings don’t just provide your business info—they play a pivotal role in <b style={{fontWeight:500}}>shaping your reputation, search engine visibility, and revenue potential.</b>
                                <br></br><br></br>
                                By adopting a local listing management solution, you effectively <b style={{fontWeight:500}}>establish your presence</b> across various <b style={{fontWeight:500}}>review sites and business directories.</b> Plus, <b style={{fontWeight:500}}>claiming these listings is essential for monitoring reviews</b>—you won’t be able to publicly respond to customer feedback unless you first secure your profiles.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Unify Review Supervision
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                When overseeing multiple restaurant locations, <b style={{fontWeight:500}}>staying on top of reviews across various platforms is essential.</b> Keeping an eye on reviews from popular platforms like Google, Facebook, zomato,swiggy, and Tripadvisor is vital for understanding customer experiences as they are <b style={{fontWeight:500}}>home to a staggering 88% of all online reviews,</b> but so is tracking reviews on specialized platforms like OpenTable, Grubhub, AllergyEats, and Zomato.
                                <br></br><br></br>
                                Consumers are <a href='https://www.vendasta.com/blog/50-stats-you-need-to-know-about-online-reviews/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>50% more</a> likely to be motivated by an online review than by a discount offer. Investing in an online reputation management tool that centralizes reviews from all these platforms can be a game-changer. By bringing all feedback together into a unified platform, your team can <b style={{fontWeight:500}}>efficiently handle reviews, address customer issues, and identify trends across different locations.</b> This integration not only <b style={{fontWeight:500}}>minimizes the time and effort needed to manage multiple review sites</b> but also fosters a more unified strategy for handling your brand’s online presence. Consequently,  this streamlined method <b style={{fontWeight:500}}>enhances customer satisfaction, supports more informed decision-making, and strengthens your overall reputation.</b>
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',      // Allow the image to take up 100% of its container width
                                    maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                    height: 'auto',     // Maintain the aspect ratio of the image
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Centralize Your Review Management with Restaverse! Effortlessly reply to reviews, manage feedback, and keep track of all your interactions in one place. Join Restaverse and streamline your review process now!

                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Address Reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Restaurateurs who embrace evolving diner tastes and trends are the ones who will outshine their competitors and lead the industry.
                                <br></br><br></br>
                                Businesses with more reviews generate <a href='https://www.reviewtrackers.com/reports/online-reviews-survey/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>54% more</a> revenue. Furthermore, those with <b style={{fontWeight:500}}>more than the average number of reviews bring in 82% more annual revenue</b> than businesses with below-average review quantity. <b style={{fontWeight:500}}>Effectively responding to both negative reviews and positive feedback,</b> you not only shield your brand’s reputation but also <b style={{fontWeight:500}}>deepen your understanding of customer needs and expectations.  Monitoring reviews should go beyond mere observation—</b>actively engage with the feedback and adapt based on what you discover. <b style={{fontWeight:500}}>This proactive approach can transform customer experiences from mediocre to exceptional.</b>
                                <br></br><br></br>
                                Maintain a courteous and professional tone in your responses to reviews, as the manner in which you reply can be as impactful as the review itself.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={four}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Rectify Customers’ Issues
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <a href='https://www.reviewtrackers.com/guides/examples-responding-reviews/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>53% of consumers</a> expect businesses to respond to negative reviews within a week, but <b style={{fontWeight:500}}>63% have experienced a business simply not responding at all. Frequent review monitoring is crucial</b> because it enables your team to <b style={{fontWeight:500}}>pinpoint and address particular issues that affect the customer experience.</b> While assessing reviews, <b style={{fontWeight:500}}>prioritize the detailed text and unstructured feedback over just the star ratings</b> for a more comprehensive view.
                                <br></br><br></br>
                                <b style={{fontWeight:500}}>Leading restaurant groups today are leveraging advanced tools</b> such as customer experience analytics, natural language processing, and sentiment analysis to <b style={{fontWeight:500}}>better understand guest experiences and harness reviews to optimize operations and improve business outcomes.</b>
                                <br></br><br></br>
                                Addressing the concerns raised in guest feedback and reviews, your restaurant can more effectively regain unhappy customers, improve decision-making, and provide a superior guest experience.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Concluding Thoughts

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                The tips outlined for effective restaurant review monitoring establish a robust framework for receiving unsolicited feedback, gaining a true understanding of customers, and encouraging loyalty.
                                <br></br><br></br>
                                <b style={{fontWeight:500}}>Keep in mind:</b> The guest experience begins long before diners walk through your doors. In a world where digital platforms dictate every crucial interaction, your success hinges on your ability to excel in monitoring and managing online reviews and feedback. Seize this opportunity, and watch your restaurant become a leader in unforgettable guest experiences.

                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogtwo;