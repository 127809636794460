export const validateAccessToken = async (accessToken) => {
  try {
    console.log(accessToken);
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${accessToken}`
    );

    if (!response.ok) {
      throw new Error("Failed to validate access token");
    }

    const data = await response.json();

    // Check if the token is valid
    if (data.error) {
      console.error("Access token is not valid:", data.error);
      return false;
    } else {
      console.log("Access token is valid");
      return true;
    }
  } catch (error) {
    console.error("Error validating access token:", error);
    return false;
  }
};

export const fetchUserInfo = async (accessToken) => {
  // Make HTTP request to Google People API
  return fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch user info");
      }
      return response.json();
    })
    .then((data) => {
      console.log("User Info:", data);
      return data;
      // Handle user info response here
    })
    .catch((error) => {
      console.error("Error fetching user info:", error);
    });
};

export const handleUserInfo = (
  setAccessToken,
  setIsLoggedIn,
  validateAccessToken
) => {
  const userInfo = localStorage.getItem("userInfo");

  if (userInfo) {
    const parsedUserInfo = JSON.parse(userInfo);
    console.log(userInfo);
    console.log(Date.now());
    if (parsedUserInfo.expires_in > 0) {
      setIsLoggedIn(true);
      // dispatch({ type: "LOGIN" });

      const access_token = parsedUserInfo.access_token;
      setAccessToken(access_token);

      // dispatch({ type: "SET_ACCESS_TOKEN", payload: {accessToken: access_token } });
      console.log("hello");
      // Validate access token
      console.log(access_token);
      validateAccessToken(access_token)
        .then((isValid) => {
          console.log(isValid);
          if (!isValid) {
            // If access token is not valid, clear user info from local storage
            console.log("not valid");
            localStorage.removeItem("userInfo");
            //   setIsLoggedIn(false);
            // dispatch({ type: "LOGOUT" });
            setIsLoggedIn(false);
            return null;
          }
        })
        .catch((error) => {
          console.error("Error validating access token:", error);
        });

        return parsedUserInfo;
    }
  }
  return null;
};

export const handleLogout = (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: "LOGOUT" });
};

export const handleLogin = async (
  tokenResponse,
  setIsLoggedIn,
  setAccessToken,
  fetchUserInfo,
  validateAccessToken,
  fetch_Api
) => {
  try {
    const userInfo = await fetchUserInfo(tokenResponse.access_token);
    const tokenValidity = await validateAccessToken(tokenResponse.access_token);
    userInfo["expires_in"] = tokenResponse.expires_in;
    userInfo["access_token"] = tokenResponse.access_token;
    const end_point = "api/website_gmail_login_data"
    console.log(userInfo);
    fetch_Api(end_point,userInfo)
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
    // dispatch({ type: 'LOGIN' });
    setIsLoggedIn(true);
    const access_Token = tokenResponse.access_token;
    setAccessToken(access_Token);
    return userInfo;
  } catch (error) {
    console.error("Error fetching user info", error);
    return null;
  }
};

export const fetch_Api = (end_point, data) => {
  const headers = {
    "Content-Type": "application/json", // Assuming JSON data is being sent
  };

  console.log(data);

  fetch(`https://prod.restaverse.com/${end_point}`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data["message"] == "Your response data") {
        // setLoading(false);
        // setLoadingContent("Your form has been submitted.");
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          // setNotification(false);
        }, 3000);
      }
      // setFormData(defaultFormData);
      // notify()
    })
    .catch((error) => {
      console.error("Error saving data:", error);
    });
};


export const delay = async (ms) => {
  return new Promise((resolve) => 
      setTimeout(resolve, ms));
};