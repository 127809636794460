import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import shmone from '../../images/CaseStudy/shm/SHM 1.webp';
import shmtwo from '../../images/CaseStudy/shm/SHM 2.webp';
import shmhome from '../../images/CaseStudy/shm/SHM Home.webp';
import shmowner from '../../images/CaseStudy/shm/SHM.webp';

const Shm = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });



  useEffect(() => {
    document.title = "Case Study | Restaverse";
    window.scrollTo(0, 0);
  }, []);


  const infoAboutCompany = {
    Brand: `SHM`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Bakery",
    Location: "Mumbai",
    AOV: "High",
    "Target Audience": "Premium",
  };

  const challenge = {
    text1: "Boosting Visibility and Efficiency",
    text2: `Despite significant popularity amongst customers and premium product quality, the brand was struggling with low search rankings on the online aggregator platforms against its competitors.These challenges, alongside reduced visibility on major platforms, were preventing them from fully capitalising on market opportunities and driving substantial growth.
`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 1833,
        figurePrefix: "",
        figuresPostfix: "%",
        content: "Growth in overall revenue",
      },
      card2: {
        header: "Conversion",
        figures: 1062,
        figuresPostfix: "%",
        content: "increase in overall menu opens",
      },
      card3: {
        header: "Orders",
        figures: 1800,
        figuresPostfix: "%",
        content: "increase in overall orders",
      },
    },
    owner_views: {
      title: "SHM",
      description: `Partnering with Restaverse has been great. Their industry knowledge and customised strategies streamlined our operations and enhanced our menu. Their marketing efforts greatly improved the user experience on the delivery platforms, ensuring a seamless and engaging journey for our customers. With their help we have seen a growth in our restaurant's performance and have learnt quite a few things ourselves as well!`,
      name: "Neha Sethi",
      designation: "Founder at Sweetish House Mafia",
      owner_img: shmowner
    },
  };


  const solutions = {
    1: {
      header: " Exclusive Online Menu",
      content: `We placed a strong emphasis on making hygienic changes, creating detailing item descriptions, carefully analysing competitor pricing, and skillfully engineering the menu structure to optimise its search ranking. These efforts were designed to improve customer understanding, ensure competitive pricing positioning, and showcase key menu items, ultimately enhancing the brand's visibility and search performance on the online aggregator platforms.`,
      src: shmone,
    },
    2: {
      header: "Implemented Targeted Promotions",
      content:
        "To grow the brand's ranking and menu opens on these online platforms, we introduced attractive discounts and enhanced the advertising strategy. Offering special promotions helped to draw in more customers, while targeted ad optimization ensured the brand reached the right audience effectively. These combined efforts were designed to boost the online presence, drive higher traffic, and achieve a stronger search ranking.",
      src: shmtwo,
    },

  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          className="container-lg"
        >
          <div
            className="casestudy"
            style={{ fontFamily: "Poppins , sans-serif" }}
          >
            <div className="main-section">
              <div className="left-main-section">
                <div className="left-section-desc">
                SHM: Revenue growth via menu enhancement strategies
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={shmhome} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
                {/* <Challenge/> */}
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions} />
              </section>

              <section id="impact">
                {/* <Impact /> */}
                <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

                <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default Shm;
