import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import eleven from '../../images/blogs/11.png';
import twelve from '../../images/blogs/12.png';
const Blogsix = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Maximize Your Success: The Ultimate Guide to Mastering Customer Review Management
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Amar Savla"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/amarr-prabodh-savla-5a533060/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Amar Savla</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 26, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Imagine a restaurant where every meal is tailored to perfection, every visit feels like home, and every customer leaves with a smile. This is the power of online reputation management (ORM).
                                <br></br><br></br>
                                By systematically <b style={{fontWeight:500}}>collecting, analyzing, and acting on feedback,</b> you <b style={{fontWeight:500}}>gain crucial insights into what your customers truly enjoy and where you can improve.</b> This approach not only elevates your service but also builds lasting relationships, transforming occasional visitors into dedicated patrons. In a competitive market, staying attuned to your customers' voices is the key to long-term success.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={eleven}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"

                                }}
                            >
                                How to manage customer reviews?
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Effectively managing customer reviews involves a systematic approach to ensure that reviews are gathered, organized, analyzed, and acted upon in a way that drives continuous improvement.
                                <br></br>
                                Here is a detailed process with best practices and pro-tips for each stage:
                                <br></br>
                            </Typography>
                            <Typography sx={{
                                textAlign: 'justify',
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",
                                overflowWrap: "normal",
                                color: "#213343",
                                fontWeight: 300,
                                marginLeft: '10px'
                            }}>
                                <ul style={{
                                    listStyleType: 'none',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    margin: 0,

                                }}>
                                    <li style={{ textAlign: 'justify' }}><b style={{fontWeight:500}}> Step 1: Ask for reviews</b><br></br> To effectively gather customer reviews, start by clearly <b style={{fontWeight:500}}>defining your objectives</b> to guide the survey design. <b style={{fontWeight:500}}>Use event-triggered surveys</b> for timely insights and <b style={{fontWeight:500}}>balance close-ended</b> with <b style={{fontWeight:500}}>open-ended questions</b> to capture both quantitative and qualitative data. Ensure <b style={{fontWeight:500}}>mobile-friendliness</b> for easy access on any device. <b style={{fontWeight:500}}>Keep surveys concise</b> to avoid fatigue, and use skip logic for efficiency. Enhance review collection with methods like interviews and observations. Finally, <b style={{fontWeight:500}}>collect reviews</b> from all channels to get a comprehensive view of customer experiences.</li>
                                    <li style={{ textAlign: 'justify' }}><b style={{fontWeight:500}}> Step 2: Categorize Leverage Reviews for Training</b><br></br> Utilize an online reputation management system to <b style={{fontWeight:500}}>organize reviews into categories</b> such as product performance, user experience, feature requests, technical issues, customer support, pricing and billing, and general sentiment. <b style={{fontWeight:500}}>Use metadata </b>like customer demographics and review source to <b style={{fontWeight:500}}>tag and label reviews</b> for better analysis. <b style={{fontWeight:500}}>Prioritize reviews</b> using methods like the Urgent vs. Important matrix or feature prioritization frameworks. <b style={{fontWeight:500}}>Evaluate the impact and feasibility</b> of each review to address the most important and actionable items first.</li>
                                    <li style={{ textAlign: 'justify' }}><b style={{fontWeight:500}}> Step 3: Analyze </b><br></br> <b style={{fontWeight:500}}>Use dedicated dashboards and AI-powered analytics</b> to optimize the review analysis process. <b style={{fontWeight:500}}>Evaluate reviews using metrics</b> like Customer Satisfaction (CSAT), Net Promoter Score (NPS), and Customer Effort Score (CES). <b style={{fontWeight:500}}>Produce reports</b> on recurring issues, feature requests, and sentiment trends to identify areas for improvement. <b style={{fontWeight:500}}>Employ visualizations</b> such as heatmaps to highlight frequent pain points, sentiment analysis charts to understand emotional context, and trend graphs to monitor changes over time. <b style={{fontWeight:500}}>Leverage insights</b> to prioritize responses, refine strategies, and boost customer satisfaction and product development.</li>
                                    <li style={{ textAlign: 'justify' }}><b style={{fontWeight:500}}> Step 4: Act</b><br></br> Address user <b style={{fontWeight:500}}>reviews</b> by tackling pain points and needs in innovative ways. Engage <b style={{fontWeight:500}}>cross-functional teams</b> to ensure that the implementation of these solutions is thorough and effective. <b style={{fontWeight:500}}>Delegate review tasks</b> to the appropriate teams for specialized focus, and create <b style={{fontWeight:500}}>action plans</b> with clear accountability and deadlines for efficient issue resolution. <b style={{fontWeight:500}}>Enhance urgency</b> by setting up reminder emails and manager alerts to ensure timely follow-ups. <b style={{fontWeight:500}}>Automatically create cases</b> from negative reviews and <b style={{fontWeight:500}}>keep customers updated</b> on progress to maintain transparency and trust. This approach ensures that issues are addressed promptly and effectively, fostering greater customer satisfaction and loyalty. </li>
                                    <li style={{ textAlign: 'justify' }}><b style={{fontWeight:500}}> Step 5: Follow-up </b><br></br> After addressing user reviews, reach out to inform customers about the actions taken in response. <b style={{fontWeight:500}}>Communicate clearly and transparently</b> about the steps you’ve implemented to resolve their concerns. <b style={{fontWeight:500}}>Encourage customers to leave positive reviews</b> on social media or review platforms once their issues are resolved, which can improve your Net Promoter Score (NPS) and brand reputation. <b style={{fontWeight:500}}>Provide regular updates</b> to show your commitment to their satisfaction. This practice helps build stronger relationships and fosters greater customer loyalty..</li>

                                </ul>
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twelve}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px",
                                    marginBottom: "10px"
                                }}
                            >
                                5 ways to handle customer reviews
                               
                            </Typography>
                            <Typography sx={{
                                textAlign: 'justify',
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",
                                overflowWrap: "normal",
                                color: "#213343",
                                fontWeight: 300,
                                marginLeft: '10px'
                            }}>
                                <ul style={{
                                    listStyleType: 'none',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    margin: 0,

                                }}>
                                    <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>Prompt Acknowledgment:</b> Quickly acknowledge customer reviews, regardless of their nature, to demonstrate that their input is valued. Responding quickly reflects your attentiveness and commitment, even if a resolution is not yet available. </li>
                                    <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>Leverage Reviews for Training:</b> Use customer reviews as a key resource for training your customer service team. Identify common issues and integrate these insights into training materials to enhance service quality and address recurring concerns. </li>
                                    <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>Review and Act:</b> Consistently evaluate customer reviews internally to spot trends and areas needing improvement. Take actionable steps to address feedback, including refining products, enhancing processes, or adjusting services.</li>
                                    <li style={{ listStyleType: 'disc' }}><b style={{fontWeight:500}}>Strategies for Public Responses:</b> Create approaches for addressing feedback publicly, particularly on social media and review sites. Provide professional and empathetic responses that acknowledge the issue, offer practical solutions, and suggest continuing the conversation or resolving the matter through private communication. </li>
                                    <li style={{ listStyleType: 'disc'}}><b style={{fontWeight:500}}>Embed Continuous Improvement:</b> Cultivate an environment where ongoing enhancement is a core value. Encourage team members to actively seek out and use customer reviews to drive positive changes and improve the overall customer experience.</li>

                                </ul>
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                CONCLUSION
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Effective online reputation management transforms occasional visitors into loyal patrons by systematically managing customer reviews. By systematically managing customer reviews—asking, categorizing, analyzing, acting, and following up—you boost satisfaction and enhance your brand.
                                <br></br><br></br>
                                Want to see real results? Contact Restaverse today and discover how we can supercharge your review management and set your restaurant apart!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogsix;