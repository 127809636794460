import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import one from '../../images/blogs/1.png';
import two from '../../images/blogs/2.png';
import signup from '../../images/blogs/Sign_up.png';

const ArticlePage = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: { sm: 65, xs: 56 },
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: { sm: 8, xs: 14 },
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: { sm: 2, xs: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',

                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize: { xs: '35px', sm: '47px' }
                            }}
                        >
                            Top 7 Restaurant Review Sites Every Owner Must Monitor for Success
                        </Typography>

                        {/* <Button
                            variant="contained"
                            sx={{ background: 'rgb(108, 65, 255)', height: '50px', width: "400px", marginBottom: '10px', textTransform: 'none' }}
                        >
                            Subscribe to Masters in Marketing
                        </Button> */}
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Shiksha Chauhan"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px', }}
                            />
                            <Box sx={{ display: 'flex', alignItems: { sm: 'center', xs: 'left' }, flexDirection: { sm: 'row', xs: 'column' }, gap: { sm: 87, xs: 2 } }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/shikshachauhan/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Shiksha Chauhan</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 12, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: { sm: 2, xs: 0 },
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: { sm: 2, xs: 0 },
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                }}
                            >
                                In today’s digital world, restaurant reviews can make or break your business! When diners are hungry they straight up read the online reviews before selecting a restaurant.
                                <br></br><br></br>
                                Online reviews have become essential to the modern consumer’s decision-making process.<a href='https://www.demandhub.co/articles/benefits-of-google-reviews/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>95% of consumers read online reviews for restaurants before choosing a restaurant, and 93% say these reviews significantly influence their dining decisions.</a>
                                <br></br><br></br>
                                Ready to uncover the top platforms that can boost your success and keep you in the spotlight? Let’s explore and take your restaurant to the next level!
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={one}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>


                            <Typography
                                color="black"
                                gutterBottom={true}
                                variant="h4"
                                component="p"
                                sx={{
                                    marginTop: "30px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    lineHeight: "1.77777778",
                                    color: "#213343"
                                }}
                            >
                                Top 7 Restaurant Review Sites Owners Should Be Tracking

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                The conversation about a restaurant doesn’t end at the table; it continues on review sites and social media. By regularly monitoring and analyzing the feedback, restaurant owners can address any issues and enhance their establishment's appeal to prospective diners.

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "30px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}
                            >
                                Here are seven websites that provide ratings and reviews for restaurants:
                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"

                                }}
                            >
                                1. Zomato
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <b style={{ fontWeight: 500 }}>Operating in over 3,200 cities</b>, Zomato has captured a dominant <b style={{ fontWeight: 500 }}>55% share</b> in the online ordering landscape. Customers use the platform to <b style={{ fontWeight: 500 }}>search and discover restaurants, give and read reviews, order food, book a table, or dine out.</b>
                                With <a href='https://b.zmtcdn.com/investor-relations/Zomato_overview_deck_May24.pdf' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>18 million customers</a>, <b style={{ fontWeight: 500 }}>247,000 restaurant partners, and 4,00,000 delivery partners,</b> Zomato has become a crucial platform for restaurants to <b style={{ fontWeight: 500 }}>enhance their online visibility</b> and attract a significant share of diners actively searching for dining options.
                                To maximize the benefits of Zomato for your restaurant, it's essential to <b style={{ fontWeight: 500 }}>claim your restaurant listing.</b> This step grants you the ability to <b style={{ fontWeight: 500 }}>monitor customer reviews</b> and <b style={{ fontWeight: 500 }}>provide timely responses,</b> ensuring that any feedback—positive or negative—is addressed promptly.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. TripAdvisor

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                When people travel, they don’t just book a place to stay—they seek out the best dining experiences as well. This is where TripAdvisor comes into play. Known for its travel insights, TripAdvisor also plays <b style={{ fontWeight: 500 }}>a pivotal role</b> in the restaurant listing space, where it can significantly <b style={{ fontWeight: 500 }}>impact a restaurant's success.</b>
                                <br></br><br></br>
                                With <a href='https://www.mara-solutions.com/post/how-to-respond-to-tripadvisor-reviews-in-seconds' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>86% of customers</a> reading reviews and <b style={{ fontWeight: 500 }}>96% of hospitality brands</b> acknowledging the <b style={{ fontWeight: 500 }}>influence of online reviews on bookings,</b> a strong presence on TripAdvisor can <b style={{ fontWeight: 500 }}>make or break a restaurant’s reputation.</b>
                                <br></br><br></br>
                                Furthermore, <b style={{ fontWeight: 500 }}>83% of travelers feel more confident</b> in their choices after consulting TripAdvisor, restaurants with <b style={{ fontWeight: 500 }}>strong reviews</b> on the platform are more likely to <b style={{ fontWeight: 500 }}>attract diners</b> who prioritize quality dining experiences. Optimize your TripAdvisor presence by completing your listing, and <b style={{ fontWeight: 500 }}>actively encourage and respond to reviews</b> to boost your restaurant’s visibility and reputation.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Google
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <b style={{ fontWeight: 500 }}>Attracting 183 million daily visitors</b> and facilitating <b style={{ fontWeight: 500 }}>5.6 billion searches every day,</b> Google stands as the <b style={{ fontWeight: 500 }}>most popular website</b> on the internet. Additionally, about <a href='https://wisernotify.com/blog/google-review-stats/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>74% of consumers</a> feel that positive Google reviews help them trust a business more.
                                <br></br><br></br>
                                <b style={{ fontWeight: 500 }}>Having positive reviews is vital</b> because they are displayed on Google when users search for your restaurant or others in the area, impacting their decision-making process. Google also <b style={{ fontWeight: 500 }}>displays information</b> like <b style={{ fontWeight: 500 }}>peak hours, average stay times, price levels, and menus.</b>
                                <br></br><br></br>
                                Since listing on Google My Business is free, every restaurant should be listed to enhance visibility and increase the likelihood of appearing in Google Maps searches.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Meta
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Harnessing Instagram and Facebook effectively can significantly boost a restaurant’s success. Instagram’s blend of <b style={{ fontWeight: 500 }}>photo-sharing, authenticity, and user-generated content</b> allows restaurants to <b style={{ fontWeight: 500 }}>showcase their dishes and atmosphere</b> while leveraging positive reviews to build <b style={{ fontWeight: 500 }}>brand loyalty and drive conversions.</b>
                                <br></br><br></br>
                                Simultaneously, Facebook's robust review system, where <a href='https://www.elluminatiinc.com/social-media-statistics-for-restaurant/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>72% of users</a> rely on <b style={{ fontWeight: 500 }}>shared comments for dining choices,</b> provides a critical platform for direct engagement and its <b style={{ fontWeight: 500 }}>impressive traffic of over 75 million monthly users</b> makes it an indispensable tool for restaurants.
                                <br></br><br></br>
                                By actively engaging with reviews on Instagram and Facebook, restaurants can address <b style={{ fontWeight: 500 }}>customer feedback, resolve issues, and show appreciation</b> for positive comments.

                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',      // Allow the image to take up 100% of its container width
                                    maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                    height: 'auto',     // Maintain the aspect ratio of the image
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Take charge of your online reputation today! With Restaverse's all-in-one dashboard, you can seamlessly manage your reviews across top platforms like Zomato, TripAdvisor, Google, Swiggy, Magicpin, Meta, and EazyDiner. Maximize your restaurant’s digital presence and unlock its full potential with ease.
                            </Typography>






                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Swiggy
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Swiggy has a presence in over <a href='https://www.livemint.com/companies/start-ups/swiggy-eyes-100-mn-customers-using-the-app-over-15-times-a-month-in-10-15-years-11571422800906.html' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>500 cities and towns</a>, <b style={{ fontWeight: 500 }}>140,000 restaurants on its platform</b>, and <b style={{ fontWeight: 500 }}>2.1 lakh active delivery partners.</b> <a href='https://blog.swiggy.com/2022/12/15/how-india-swiggyd-2022/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>85% of Swiggy One users</a> used more than one service from Swiggy. Members saved the most with free deliveries. Swiggy Dineout saw rising popularity among users with over <b style={{ fontWeight: 500 }}>10,000 new restaurants joining the platform</b> this year.
                                <br></br><br></br>
                                When choosing a dining experience, many users turn to <b style={{ fontWeight: 500 }}>reviews to gauge the quality</b> of restaurants. With <b style={{ fontWeight: 500 }}>Swiggy One,</b> the decision becomes even easier as it offers enticing benefits like <b style={{ fontWeight: 500 }}>free delivery, significant discounts, and exclusive offers.</b> By combining favorable reviews with the advantages of Swiggy One, users can enjoy a superior dining experience at a better value.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                6. Magicpin

                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "30px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Magicpin is India’s <b style={{ fontWeight: 500 }}>#1 savings app</b> that spans from <b style={{ fontWeight: 500 }}>local merchants to large brands,</b> online and offline like Pantaloons, Lifestyle, Westside, Levis, Jockey, Titan, Fastrack, McDonald’s, CCD, and many more. As a platform with over <a href='https://cxotoday.com/press-release/magicpin-in-helps-restaurants-go-online-and-fully-functional-in-minutes/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>5 million users</a> and partnerships with <b style={{ fontWeight: 500 }}>more than 150,000 stores,</b> Magicpin plays a crucial role in enhancing dining experiences and restaurant visibility.
                                <br></br><br></br>
                                Magicpin offers enticing <b style={{ fontWeight: 500 }}>rewards and exclusive deals</b> that capture diners' attention. When users see these rewards associated with your restaurant, they’re more <b style={{ fontWeight: 500 }}>inclined to visit, increasing footfall and sales.</b> Additionally, these <b style={{ fontWeight: 500 }}>reward-driven visits</b> often lead to <b style={{ fontWeight: 500 }}>positive user-generated reviews,</b> enhancing your restaurant’s reputation.
                                <br></br><br></br>
                                By featuring your restaurant in Magicpin’s reward programs, you not only gain heightened visibility but also benefit from targeted local promotions and an engaged audience actively seeking dining deals.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={two}
                                    style={{
                                        width: '100%',      // Allow the image to take up 100% of its container width
                                        maxWidth: '550px', // Set a maximum width to prevent it from getting too large
                                        height: 'auto',     // Maintain the aspect ratio of the image
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                7. EazyDiner
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Offering the best deals in over <a href='https://www.prnewswire.com/in/news-releases/indias-largest-restaurant-festival-the-eazydiner-eat-out-festival-is-now-live-692150061.html' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>3,600 restaurants</a> in <b style={{ fontWeight: 500 }}>11 cities across </b>India EazyDiner’s four-pronged business model focuses on <b style={{ fontWeight: 500 }}>providing curated reviews of restaurants, table booking services at restaurants</b> and concierge services that will assist diners with making <b style={{ fontWeight: 500 }}>bookings, advising them on the menu,</b> providing alternate places to dine, among others.
                                <br></br><br></br>
                                EazyDiner launched the country’s most exclusive and strong dining program. In partnership with American Express, <b style={{ fontWeight: 500 }}>‘EazyDiner Prime’.</b> The reservation platform prime features over <b style={{ fontWeight: 500 }}>400+ of the country’s best restaurants</b> curated based on users review and feedback.
                                <br></br><br></br>
                                <b style={{ fontWeight: 500 }}>Positive customer feedback</b> increases a restaurant’s chances of being highlighted in EazyDiner’s <b style={{ fontWeight: 500 }}>exclusive deals and discounts.</b> This synergy enhances the restaurant’s <b style={{ fontWeight: 500 }}>exposure and attractiveness,</b> while also providing diners with access to <b style={{ fontWeight: 500 }}>well-regarded dining options.</b>

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Conclusion
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                For restaurant owners, seizing control of your online listings and ensuring brand consistency is a game-changer! Reviews, whether positive or negative, can have a significant impact on your revenue.
                                <br></br><br></br>
                                Dive into the feedback, respond with flair, and use it to fuel your restaurant’s growth and stand out in the competitive market!
                            </Typography>

                        </Box>
                    </Box>



                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default ArticlePage;