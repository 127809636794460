export const initialState = {
    isLoggedIn:false,
    accessToken: null
}

const authReducer = (state = initialState,  action)  =>  {

    switch (action.type)  {
        case 'LOGIN':
            return{
                ...state,
                isLoggedIn:true
            }
        case 'LOGOUT':
            return{
                ...state,
                isLoggedIn:false
            }
        case 'SET_ACCESS_TOKEN':
            return{
                ...state,
                accessToken: action.payload.accessToken
            }
        default:
            return state;
    }

}

export default authReducer;