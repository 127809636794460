import React from "react";
import "./challenge.css";
import AnimatedNumber from "../Team/animatednumber";
import persiadarbarowner from "../../images/CaseStudy/persiadarbar/Persia4.webp";

const Impact = () => {
  const impactCards = {
    card1: {
      header: "Revenue",
      figures: 5,
      figurePrefix: "",
      figuresPostfix: "x",
      content: "Growth in overall revenue",
    },
    card2: {
      header: "Conversion",
      figures: 100,
      figuresPostfix: "%",
      content: "increse in Menu-to-order conversions",
    },
    card3: {
      header: "Ads Conversion",
      figures: 100,
      figuresPostfix: "%",
      content: "increse in Ads conversions",
    },
  };

  return (
    <div className="challenge">
      <div>
        <h3> Impact</h3>
        <hr></hr>
      </div>
      <div className="impact-cards">
        {Object.keys(impactCards).map((key) => {
          const card = impactCards[key];
          return (
            <div className="impact-single-card">
              <div className="impact-card-header">{card.header}</div>
              <div className="impact-card-figure">
                <AnimatedNumber
                  end={card.figures}
                  duration={3}
                  unit={card.figuresPostfix}
                />
              </div>
              <div className="impact-card-content">{card.content}</div>
            </div>
          );
        })}
      </div>

      <div className="impact-section">
        <div className="impact-left-section">
          <h1>Persia Darbar</h1>
          <p>
            The Restaverse team has been pivotal in revolutionizing our
            operations. Their forward-thinking methods significantly enhanced
            the prominence of our signature dishes, resulting in a noticeable
            boost in online sales. Throughout our year-long partnership, we've
            seen our online revenue double, achieving impressive monthly
            figures. Our outlets now stand among the top earners in every city
            we operate in, thanks to the strategic insights and support from
            Restaverse.
          </p>

          <div className="writer-name">
            <h3>Mr. Faizan Kotwala</h3>
            <span>Founder, Persia Darbar</span>
          </div>
        </div>

        <img src={persiadarbarowner} className="impact-img"></img>
      </div>
    </div>
  );
};

export default Impact;
