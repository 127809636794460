import React from 'react'
import './challenge.css';

const ChallengeSection = ({text1,text2}) => {
  return (
    <div className="challenge">
      <div>
        <h3> Challenge</h3>
        <hr></hr>
      </div>
      <div className="main-section-challenge">
        <div className="left-challenge">
          {text1}
        </div>

        <div>
          {text2}
        </div>
      </div>
    </div>
  );
}

export default ChallengeSection