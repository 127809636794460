import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import Typography from "@mui/material/Typography";

const AnimatedNumber = ({ end, duration, unit,customfontsize,customColor}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {/* <div>  */}
      <Typography variant="h3" sx={{ color: "#6C41FF" ,fontFamily:'Poppins, sans-serif' ,fontSize: customfontsize ? customfontsize : undefined,color:customColor?customColor:undefined}}>
        {inView && (
          <CountUp end={end} duration={duration} useGrouping={false} />
        )}
        {unit}
      </Typography>
    </div>
  );
};

// const AnimatedNumber = ({ end, duration, unit }) => {
//   const [ref, inView] = useInView({
//     triggerOnce: true,
//   });

//   return (
//     <div ref={ref}>
//       <Typography
//         variant="h4"
//         color="primary"
//         style={{ color: "black", fontWeight: "600" }}
//       >
//         {inView && <CountUp end={end} duration={duration} />}
//         {unit}
//       </Typography>
//     </div>
//   );
// };

export default AnimatedNumber;
