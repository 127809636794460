import React from 'react';
import Slider from 'react-slick';
import { Card, CardContent, Typography, Box, Grid, CardMedia, Stack } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import './articlecraousel.css';
import one from '../../images/blogs/1.png'
import three from '../../images/blogs/3.png';
import five from '../../images/blogs/5.png';
import seven from '../../images/blogs/7.png';
import nine from '../../images/blogs/9.png';
import eleven from '../../images/blogs/11.png';

const articles = [
  {
    id: 1,
    image: one,
    title: 'Top 7 Restaurant Review Sites Every Owner Must Monitor for Success',
    date: 'June 12, 2024',
    link: '/blogs/top-7-restaurant-review-sites-every-owner-must-monitor-for-success'
  },
  {
    id: 2,
    image: three,
    title: 'From Feedback to Action: Tips for Tracking Restaurant Reviews',
    date: 'July 29, 2024',
    link: '/blogs/from-feedback-to-action-tips-for-tracking-restaurant-reviews'

  },
  {
    id: 3,
    image: five,
    title: 'Unlocking the Potential of Automation in Restaurant Review Management',
    date: 'May 28, 2024',
    link: '/blogs/unlocking-the-potential-of-automation-in-restaurant-review-management'
  },
  {
    id: 4,
    image: seven,
    title: 'The Impact of Online Reviews on Restaurant Revenue',
    date: 'July 19, 2024',
    link: '/blogs/the-impact-of-online-reviews-on-restaurant-revenue'
  },
  {
    id: 5,
    image: nine,
    title: ' Turning Negative Reviews into Positive Change: A Restaurant’s Guide to Reputation Management',
    date: 'Oct 10, 2024',
    link: '/blogs/turning-negative-reviews-into-positive-change-a-restaurants-guide-to-reputation-management'
  },
  {
    id: 6,
    image: eleven,
    title: 'Maximize Your Success: The Ultimate Guide to Mastering Customer Review Management',
    date: 'June 26, 2024',
    link: '/blogs/maximize-your-success-the-ultimate-guide-to-mastering-customer-review-management'
  },
];

const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <FaChevronRight />
    </div>
  );
};


const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <FaChevronLeft />
    </div>
  );
};

const ArticleCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <Box sx={{ width: '80%', textAlign: 'center', marginTop: 5, marginBottom: '30px', maxWidth: '1100px', width: '100%' }}>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: 5, fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 500 }}>
        Related Articles
      </Typography>
      <Slider {...settings}>
        {articles.map((article, index) => (
          <Grid item key={index} sx={{ padding: { sm: '10px', xs: '30px', md: '30px' } }}>
            <a href={article.link} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Card
                sx={{
                  '&:hover': {
                    backgroundColor: '#EAF0F6',
                    '& .MuiCardContent-root, & .date-wrapper': {
                      backgroundColor: '#EAF0F6',
                    },
                    '& .article-title': {
                      textDecoration: 'underline',
                      textDecorationColor: 'black',
                    },
                  },
                  height: '350px',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  boxShadow: 'none'
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={article.image}
                  alt={article.title}
                  style={{ width: '100%' }}
                />
                <CardContent
                  sx={{
                    textAlign: 'left',
                    height: '100%',
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontFamily: 'Lexend Deca, sans-serif',
                      fontSize: '16px',
                      transition: 'all 0.3s ease',
                      marginLeft: '-10px'
                    }}
                    className="article-title"
                  >
                    {article.title} {/* Title for each article */}
                  </Typography>
                  <div
                    className="date-wrapper"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '10px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Lexend Deca, sans-serif',
                        fontSize: '14px',
                        fontWeight: 300,
                        marginBottom: '10px'
                      }}
                      className="article-date"
                    >
                      {article.date} {/* Date for each article */}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </a>
          </Grid>
        ))}
      </Slider>
    </Box>
  );
};

export default ArticleCarousel;
