
import { useEffect, useRef, useState } from 'react';


const paragraphStyles = {
  WebkitLineClamp: 2,
  webkitBoxOrient: 'vertical',
  overflow: 'hidden',
  display: '-webkit-box',
};

const ReadMoreTextArea = ({ reviewText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setShowReadMoreButton(ref.current.scrollHeight !== ref.current.clientHeight);
    }
  },[]);

  return (
    <div>
      <p style={isOpen ? null : paragraphStyles} ref={ref} >
        {reviewText}
      </p>
      {showReadMoreButton && (
        <span onClick={() => setIsOpen(!isOpen)}style={{color:'#3559E0',cursor:'pointer'}}>
          {isOpen ? 'read less...' : 'read more...'}
        </span>
      )}
    </div>
  );
};

export default ReadMoreTextArea;
