import React from "react";
import "./challenge.css";
import persiaimage2 from "../../images/CaseStudy/persiadarbar/Persia2.webp"
import persiaimage3 from "../../images/CaseStudy/persiadarbar/Persia3.webp"

const Solution = () => {
  const solutions = {
    1: {
      header: "Optimizing Online Ordering for Better Conversion",
      content: `We redesigned the delivery menu, focusing on an intuitive online ordering experience. This strategic update aimed at improving the menu's navigability and visual appeal, which played a crucial role in enhancing customer engagement and conversion rates, ultimately leading to a significant uptick in online orders.`,

      src: persiaimage2,
    },
    2: {
      header: "Enhancing Online Visibility",
      content:"We proposed strategies to enhance their operational effectiveness, focusing on refining prep times, reducing customer complaints, and improving the overall user experience. These recommendations aimed to optimize service efficiency and elevate customer satisfaction.",
      src: persiaimage3,
    },
    // 3: {
    //   header: "Omnichannel customer service",
    //   content:
    //     "Yellow.ai deployed Dynamic AI agent on multiple digital channels, capable of understanding customer queries and responding with high accuracy for tasks such as bookings, cancellations, retrieving vouchers or tickets, live agent support, and addressing any issues or queries related to Pelago or the travel domain.",
    //   src: "",
    // },
  };
  return (
    <div className="challenge">
      <div>
        <h3> Solution</h3>
        <hr></hr>
      </div>

      {Object.keys(solutions).map((key) => {
        const solution = solutions[key];
        return key % 2 === 0 ? (
          <div className="solution-item2" key={key}>
            <img src={solution.src} className="solution-img" alt="" />
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header">{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
          </div>
        ) : (
          <div className="solution-item1" key={key}>
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header">{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
            <img src={solution.src} alt="" className="solution-img" />
          </div>
        );
      })}
    </div>
  );
};

export default Solution;
