import * as React from "react";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import "./policies.css";

export default function Policies() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    document.title = 'Policies | Restaverse';
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="polcies-grid-container">
      <div class="polcies-grid-item" >
        <ul
          style={{
            fontSize: "1.2rem",
            fontFamily: "Poppins , sans-serif",
            position: "sticky",
            top: "80px",
            zIndex: 100,
          }}
        >
          <li style={{ marginBottom: '10px' }}>
            <Link
              to="privacy-policy"
              style={{
                color: "#202021",
                textDecoration: "none",
                fontSize: "1.2rem",
              }}
            >
              Privacy Policy
            </Link>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Link
              to="terms-and-conditions"
              style={{
                color: "#202021",
                textDecoration: "none",
                fontSize: "1.2rem",
              }}
            >
              Terms and Condition
            </Link>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Link
              to="terms-and-services"
              style={{
                color: "#202021",
                textDecoration: "none",
                fontSize: "1.2rem",
              }}
            >
              Terms of Service
            </Link>
          </li>
          <li style={{ marginBottom: '10px' }}>
            <Link
              to="eula"
              style={{
                color: "#202021",
                textDecoration: "none",
                fontSize: "1.2rem",
              }}
            >
              EULA
            </Link>
          </li>
        </ul>
      </div>
      <div class="polcies-grid-item">
        {/* <PrivacyPolicy /> */}
        <Outlet />
      </div>
    </div>
  );
}
