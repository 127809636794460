import React from "react";
import './challenge.css';

const Solution = () => {
    const solutions = {
      1: {
        header: "Revamping Menu for Appetizer Excellence",
        content:
          "We overhauled Persian Darbar's menu, focusing on enhancing the presentation of appetizers and tikkas. This involved rethinking the order of items and using engaging language to make them stand out. The goal was to create an intuitive and appealing menu layout that would attract customers' attention, leading to a notable increase in orders for these dishes.",
        src: "/images/Persian 2.png",
      },
      2: {
        header:
          "Driving Appetizer Sales by Strategic Promotion",
        content:
          "To further boost interest in these underappreciated menu items, we implemented a targeted promotional campaign. This strategy included introducing appetizer samples, offering limited-time discounts, and featuring these dishes prominently in marketing materials. The campaign was designed to entice customers to try these flavors, leading to a surge in their popularity and sales.",
        src: "/images/Persian 3.png",
      },
      // 3: {
      //   header: "Omnichannel customer service",
      //   content:
      //     "Yellow.ai deployed Dynamic AI agent on multiple digital channels, capable of understanding customer queries and responding with high accuracy for tasks such as bookings, cancellations, retrieving vouchers or tickets, live agent support, and addressing any issues or queries related to Pelago or the travel domain.",
      //   src: "",
      // },
    };
  return (
    <div className="challenge">
      <div>
        <h3> Solution</h3>
        <hr></hr>
      </div>

      {Object.keys(solutions).map((key) => {
        const solution = solutions[key];
        return key % 2 === 0 ? (
          <div className="solution-item2" key={key}>
            <img src={solution.src} className="solution-img" alt="" />
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header"style={{lineHeight:'1.2'}}>{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
          </div>
        ) : (
          <div className="solution-item1" key={key}>
            <div className="solution-content">
              <div className="number">{key}</div>
              <div className="solution-header" style={{lineHeight:'1.2'}}>{solution.header}</div>
              <div className="solution-info">{solution.content}</div>
            </div>
            <img src={solution.src} alt="" className="solution-img"/>
          </div>
        );
      })}
    </div>
  );
};

export default Solution;
