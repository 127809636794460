/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */

import React from "react";
import { useEffect } from "react";
import "../AllPoliciesCss/allpolicies.css";

const Headings = ({ headings, activeId }) => (
  <ul>
    {headings.map((heading) => (
      <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
        <a
          className="policiesheadings"
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            document.querySelector(`#${heading.id}`).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? "active" : ""}
              >
                <a
                  className="policiesheadingsdesc"
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  React.useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("main h2, main h3")
    );

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});
  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: document.querySelector("iframe"),
      rootMargin: "500px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <nav className="policies-nav" aria-label="Table of contents">
      <Headings headings={nestedHeadings} activeId={activeId} />
    </nav>
  );
};

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="policies-container">
      <main className="policies-container-main">
        <h2 className="policiesh2" id="introduction">
          Privacy Policy
        </h2>
        <p>
          This Privacy Policy is meant to help you understand what information
          we collect, why we collect it, and how you can update, manage, export,
          and delete your information.
        </p>
        <p>
          Restaverse is an application which aggregates and provides a unified
          dashboard for the business owners in the hospitality sector that
          captures several data points and assists the owners in effective
          implementation of business strategies.
        </p>

        <p>
          At Restaverse, accessible from https://www.restaverse.com and
          https://app.restaverse.com one of our main priorities is the privacy
          of our visitors/subscribers. The present Privacy Policy contains the
          following types of information that is collected and recorded by
          Restaverse and how we use it.
        </p>

        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors/subscribers to our website with regards to the
          information that are shared and/or collected by Restaverse. This
          policy is not applicable to any information collected offline or via
          channels other than this website.
        </p>
        <br></br>
        <h2 id="consent" className="policiesh2">
          Consent
        </h2>

        <p>
          All visitors and/ or Subscribers by using the Restaverse website do
          hereby unconditionally consent to the present Privacy Policy and agree
          to its Terms of Use.
        </p>
        <br></br>
        <h2 id="data-collection" className="policiesh2">
          Information We Collect and Process
        </h2>
        <h3 className="policiesh3" id="personal-information">
          a) Personal Information
        </h3>
        <p>
          All visitors and/ or subscribers upon visit/ registering themselves on
          the website shall provide such personal information. When a visitor /
          subscriber registers for an account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>

        <h3 className="policiesh3" id="business-information">
          b) Information about your Business
        </h3>
        <p>
          Upon registering an account you shall be sharing confidential
          information in respect of your business (i.e. revenue, location,
          costs, budgets etc.). The business information that you are asked to
          provide, and the reasons why you are asked to provide it, will be made
          clear to you at the point the same is sought.
        </p>

        <h3 className="policiesh3" id="third-party-information">
          c) Third Party Information
        </h3>
        <p>
          When a visitor / subscriber accesses an account and avails the
          services of the Restaverse, the said subscriber will be sharing
          confidential information from various applications including but not
          limited to Zomato, Swiggy, Zepto, BlinkIt, Facebook and also from the
          Point of Sale Systems utilised by the visitor / subscriber
        </p>

        <h3 className="policiesh3" id="payment-card-information">
          d) Payment Card Information
        </h3>
        <p>
          To avail to our services, we may require credit or debit card account
          information of each subscriber. By submitting your credit or debit
          card account information through our services, you expressly consent
          to the sharing of your information with restaurants, third-party
          payment processors, and other third-party service providers (including
          but not limited to vendors who provide fraud detection services to us
          and other third parties).
        </p>

        <p>
          For information about the security of your credit or debit card
          account information, see the "Data Security" section below.
        </p>

        <h3 className="policiesh3" id="general-information">
          e) General Information
        </h3>
        <p>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
<br></br>
        <h2 id="usage-of-information" className="policiesh2">
          How We Use and Process This Information
        </h2>
        <p>
          We use and process the information we collect through our cloud-based
          system depending on the requirements and circumstances of the Visitor
          / Subscriber, in various ways including but not limited to
        </p>

        <p>
          a) Analyse and process data through our website to provide you with
          tailor made solutions in respect of your business
        </p>

        <p>
          b) Analyse and process data through our website in respect of
          understanding market trends to provide business solutions and
          strategies
        </p>

        <p>
          c) Establishing marketing and business development strategies for your
          business
        </p>

        <p>
          d) Optimise business efficiency and minimisation of business risks
        </p>

        <p>e) Provide, operate and maintain our website</p>

        <p>f) Improve, personalize, and expand our website</p>

        <p>g) Understand and analyze how you use our website</p>

        <p>h) Develop new products, services, features, and functionality</p>

        <p>
          i) Communicate with you, either directly or through one of our
          partners, including for customer service, to provide you with updates
          and other information relating to the website, and for marketing and
          promotional purposes
        </p>

        <p>j) Send you emails</p>

        <p>k) To carry out academic research with academic partners</p>

        <p>l) Find and prevent fraud</p>

        <p>m) To fulfil the purpose for which you provided us the same.</p>

        <p>
          It is clarified that Restaverse may combine the information that
          Restaverse receive(s) from third parties with the information
          visitors/ Subscriber provide us and information Restaverse collects
          about its visitors/ subscribers for the purposes set out above.
          Further, Restaverse may anonymize and/or de-identify information
          collected from visitors/ subscribers through the services or via other
          means, including via third-parties. As a result, our use and
          disclosure of aggregated and/or de-identified information is not
          restricted by this policy, and it may be used and disclosed to others
          without limitation.
        </p>
        <br></br>
        <h2 id="data-security" className="policiesh2">
          Data Security
        </h2>
        <h3 className="policiesh3" id="security-measures">
          a) Security Measures
        </h3>
        <p>
          Restaverse is in compliance with the Information Technology Act, 2000
          and the rules framed thereunder in respect of the personal data being
          collected by us. Restaverse has implemented appropriate technical and
          organizational measures to protect the personal data that is
          collected, used and/ or stored from unauthorized access, alteration,
          disclosure, or destruction. These measures may include encryption,
          firewalls, access controls, monitoring, and regular security audits.
        </p>
        <h3 className="policiesh3" id="data-retention">
          b) Data Retention
        </h3>
        <p>
          Restaverse shall retain the personal data it collects for the purposes
          stated above. It is clarified that the data collected shall be
          securely disposed off either on determination of the subscription of
          within such period of time as decided by Restaverse or upon a request
          made by the client.
        </p>
        <h3 className="policiesh3" id="third-party-processors">
          c) Third-party processors
        </h3>
        <p>
          Restaverse may share personal data/ business data with third-party
          service providers and it has appropriate contracts or agreements in
          place to ensure that these third-party service providers handle/ use
          the data in compliance with applicable laws and regulations.
        </p>

        <p>
        <span  style={{ fontSize: '1rem' }}>d) </span>We have in place appropriate technical and security measures to
          secure the information collected by Restaverse.
        </p>

        <p>
        <span  style={{ fontSize: '1rem' }}>e) </span>Please be aware that the transmission of information via the
          internet is not completely secure. Although we will do our best to
          protect your personal data, we cannot guarantee the security of your
          data transmitted through the Restaverse Platform. Once we have
          received your information, we will use strict physical, electronic,
          and procedural safeguards to try to prevent unauthorised access.
        </p>
        <p>
        <span  style={{ fontSize: '1rem' }}>f) </span>
           Restaverse does not use data obtained through Google Workspace APIs to develop, improve, or train generalized AI or machine learning models.
           All data obtained through Google Workspace APIs is solely used to provide and 
           improve the services offered to the users and is handled in accordance with our privacy policy.
        </p>
        <br></br>
        <h2 id="policy-changes" className="policiesh2">
          Policy Changes
        </h2>
        <p>
          a) Restaverse reserves its right to modify/ alter and/ or amend the
          present Privacy Policy and such modification(s)/
          alternation(s)/amendment(s) shall be updated from time to time.
        </p>

        <p>
          b) If we make any significant changes to this Policy we will endeavour
          to provide you with reasonable notice of such changes, such as via
          prominent notice on the Restaverse website or to your email address on
          record and where required by applicable law, due consent shall be
          obtained.
        </p>

        <p>
          c) To the extent permitted under the applicable law(a), visitors/
          subscribers continue to use of Restaverse’s services after we publish
          or send a notice about our changes to this Policy shall constitute
          visitors/ subscribers consent to the updated Policy.
        </p>
        <br></br>
        <h2 id="log-files" className="policiesh2">
          Log Files
        </h2>
        <p>
          Restaverse follows a standard procedure of using log files/ cookies.
          These files log visitors when they visit websites. All hosting
          companies do this and a part of hosting services’ analytics. The
          information collected by log files include internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and
          time stamp, referring/exit pages, and possibly the number of clicks.
          These are not linked to any information that is personally
          identifiable. The purpose of the information is for analyzing trends,
          administering the site, tracking users’ movement on the website, and
          gathering demographic information.
        </p>
        <br></br>
        <h2 id="data-protection-rights" className="policiesh2">
          Data Protection Rights
        </h2>
        <p>
          Restaverse would like to make sure you are fully aware of all of your
          data protection rights. Every visitor/ subscriber shall be entitled to
          the following:
        </p>

        <p>
          a) The right to access – Every visitor/ subscriber has the right to
          request copies of their personal data.
        </p>

        <p>
          b) The right to rectification – Every visitor/ subscriber has the
          right to request that we correct any information you believe is
          inaccurate. You also have the right to request that we complete the
          information you believe is incomplete.
        </p>

        <p>
          c) The right to erasure – Every visitor/ subscriber has the right to
          request that we erase your personal data, on raising a such a request
          with Restaverse at info@restaverse.com.
        </p>

        <p>
          d) The right to restrict processing – Every visitor/ subscriber has
          have the right to request that we restrict the processing of your
          personal data, under certain conditions.
        </p>

        <p>
          e) The right to object to processing – Every visitor/ subscriber has
          the right to object to our processing of your personal data, under
          certain conditions.
        </p>

        <p>
          f) The right to data portability – Every visitor/ subscriber has the
          right to request that we transfer the data that we have collected to
          another organization, or directly to every visitor/ subscriber
          respectively, under certain conditions.
        </p>

        <p>
          If we receive a make a request, we have one month to respond to you.
          If you would like to exercise any of these rights, please contact us
          at info@restaverse.com
        </p>
      </main>
      <TableOfContents />
    </div>
  );
};

export default PrivacyPolicy;

//   ReactDOM.render(<App />, document.getElementById("root"));
