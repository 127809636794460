import React, { createContext, useState, useContext } from 'react';

// Create a context for managing login state
export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [accessToken, setAccessToken] = useState(null);

    // Pass the values to the provider
    return (
        <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn, accessToken, setAccessToken }}>
            {children}
        </LoginContext.Provider>
    );
};

// Correct the function name and context naming
export const useLoginContext = () => {
    return useContext(LoginContext);
};
