/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */

import React from "react";
import { useEffect } from "react";
import "../AllPoliciesCss/allpolicies.css";

const Headings = ({ headings, activeId }) => (
  <ul>
    {headings.map((heading) => (
      <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
        <a
          className="policiesheadings"
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            document.querySelector(`#${heading.id}`).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? "active" : ""}
              >
                <a
                  className="policiesheadingsdesc"
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  React.useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("main h2, main h3")
    );

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});
  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: document.querySelector("iframe"),
      rootMargin: "500px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <nav className="policies-nav" aria-label="Table of contents">
      <Headings headings={nestedHeadings} activeId={activeId} />
    </nav>
  );
};

const EndUserLicenceAgreement = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policies-container">
      <main className="policies-container-main">
        <h2 className="policiesh2" id="introduction">
          End User Licence Agreement
        </h2>
        <p>
          Restaverse is licensed to You (End-User) by Restaverse Private
          Limited, located and having its registered office at 2002, 20th Floor,
          Darshan Pride, Sitaram Ghadigaonkar Marg, Nr Rosewood Hotel, Tardeo
          Mumbai 400034 ("Licensor"), for use only under the terms of this
          License Agreement.
        </p>

        <p>
          By logging in to the website of Licensor and any features / updates on
          the website thereto (as permitted by this License Agreement), You
          indicate that You agree to be bound by all of the terms and conditions
          of this License Agreement, and that You accept this License Agreement.
        </p>
        <br></br>
        <h3 id="software" className="policiesh3">
          1. THE SOFTWARE
        </h3>
        <p>
          Restaverse ("Licensed Software") is a piece of software created to
          provide a Unified dashboard that (a) captures several data points and
          assists the users in effective implementation of various business
          strategies like advertisements, promotions, customer benchmarking,
          management calls etc; (b) analyses and processes data with tailor made
          solutions in respect of your restaurant business; (c) use the same in
          respect of understanding market trends to provide business solutions
          and strategies; (d) Establishing marketing and business development
          strategies for business; (e) Optimise business efficiency and
          minimisation of business risks; and (f) Communicate with customer
          service, to provide updates and other information relating to the
          their website, and for marketing AI censored promotional purposes;
        </p>
        <br></br>
        <h3 id="license" className="policiesh3">
          2. LICENSE FEES
        </h3>

        <p>
          The Licensor for granting the license to the licensee to use the
          Licensed Software shall charge such fees which shall be intimated to
          the Licensee at the time of sign up and as per the number of outlets/
          store, for which the Licensed Software is being used.
        </p>
        <br></br>
        <h3 id="scope" className="policiesh3">
          3. SCOPE OF LICENSE
        </h3>

        <p>
          3.1 You are given a non-transferable, non-exclusive, non-sublicensable
          license to use the web based Licensed Software on any devices that you
          (End-User) own or control and as permitted by the usage rules, with
          the exception that such Licensed Software may be accessed and used by
          other accounts associated with you (End-User, The Purchaser).
        </p>

        <p>
          3.2 This license will also govern any updates of the Licensed
          Application provided by Licensor that replace, repair, and/or
          supplement the first Licensed Application, unless a separate license
          is provided for such update, in which case the terms of that new
          license will govern.
        </p>

        <p>
          3.3 You may not share or make the Licensed Application available to
          third parties (unless to the degree allowed by the Usage Rules, and
          with the Licensor’s prior written consent), sell, rent, lend, lease or
          otherwise redistribute the Licensed Application.
        </p>

        <p>
          3.4 You may not reverse engineer, translate, disassemble, integrate,
          decompile, remove, modify, combine, create derivative works or updates
          of, adapt, or attempt to derive the source code of the Licensed
          Application, or any part thereof (except with Licensor's prior written
          consent).
        </p>

        <p>
          3.5 You may not copy (excluding when expressly authorized by this
          license and the Usage Rules) or alter the Licensed Application or
          portions thereof. You may create and store copies only on devices that
          You own or control for backup keeping under the terms of this license,
          the Usage Rules, and any other terms and conditions that apply to the
          device or software used. You may not remove any intellectual property
          notices. You acknowledge that no unauthorized third parties may gain
          access to these copies at any time.
        </p>

        <p>
          3.6 Violations of the obligations mentioned above, as well as the
          attempt of such infringement, may be subject to prosecution and
          damages to the extent of 5 times the License fees paid by them.
        </p>

        <p>
          3.7 Licensor reserves the right to modify the terms and conditions of
          licensing.
        </p>

        <p>
          3.8 Nothing in this license should be interpreted to restrict
          third-party terms. When using the Licensed Application, You must
          ensure that you comply with applicable third-party terms and
          conditions.
        </p>
        <br></br>
        <h3 id="technical" className="policiesh3">
          4. TECHNICAL REQUIREMENTS
        </h3>

        <p>
          4.1 Licensor attempts to keep the Licensed Application updated so that
          it complies with modified/new versions of the application. You are not
          granted rights to claim such an update.
        </p>

        <p>
          4.2 You acknowledge that it is your responsibility to confirm and
          determine that the end-user device on which You intend to use the
          Licensed Application satisfies the technical specifications mentioned
          above.
        </p>

        <p>
          4.3 Licensor reserves the right to modify the technical specifications
          as it sees appropriate at any time.
        </p>
        <br></br>
        <h3 id="maintenance" className="policiesh3">
          5. MAINTENANCE AND SUPPORT
        </h3>

        <p>
          5.1 The Licensor is solely responsible for providing any maintenance
          and support services for this Licensed Software. You can reach the
          licensor at the email address listed on the website of the Licensed
          Software.
        </p>

        <p>
          5.2 End-User acknowledge that the services have no obligation
          whatsoever to furnish any maintenance and support services with
          respect to the Licensed Software.
        </p>
        <br></br>
        <h3 id="useofdata" className="policiesh3">
          6. USE OF DATA
        </h3>

        <p>
          You acknowledge that Licensor will be able to access and adjust your
          uploaded Licensed Software content and your personal information, and
          that Licensor's use of such material and information is subject to
          your legal agreements with Licensor and Licensor's privacy policy,
          which can be accessed by https:// https://www.restaverse.com/.
        </p>

        <p>
          You acknowledge that the Licensor may periodically collect and use
          technical data and related information about your device, system, and
          application software, and peripherals, offer product support,
          facilitate updates, and for purposes of providing other services to
          you (if any) related to the Licensed Software. Licensor may also use
          this information to improve its products or to provide services or
          technologies to you, as long as it is in a form that does not
          personally identify you.
        </p>
        <br></br>
        <h3 id="liability" className="policiesh3">
          7. LIABILITY
        </h3>

        <p>
          7.1 Licensor's responsibility in the case of violation of obligations
          shall be limited to intent and gross negligence. In any case,
          liability shall be limited to the foreseeable, contractually typical
          damages. The limitation mentioned above does not apply to injuries to
          life, limb, or health.
        </p>

        <p>
          7.2 Licensor takes no accountability or responsibility for any damages
          caused due to a breach of duties according to Section 2 of this
          License Agreement. To avoid data loss, you are required to make use of
          backup functions of the Licensed Application to the extent allowed by
          applicable third-party terms and conditions of use. You are aware that
          in case of alterations or manipulations of the Licensed application,
          you will not have access to the Licensed Software.
        </p>
        <br></br>
        <h3 id="warranty" className="policiesh3">
          8. WARRANTY
        </h3>

        <p>
          8.1 Licensor warrants that the Licensed Software is free of spyware,
          trojan horses, viruses, or any other malware at the time of your
          download. Licensor warrants that the Licensed Software works as
          described in the user documentation.
        </p>

        <p>
          8.2 No warranty is provided for the Licensed Software that is not
          executable on the device, that has been unauthorizedly modified,
          handled inappropriately or culpably, combined or installed with
          inappropriate hardware or software, used with inappropriate
          accessories, regardless of whether by yourself or by third parties, or
          if there are any other reasons outside of Licensor’s sphere of
          influence that affect the executability of the Licensed Software.
        </p>

        <p>
          8.3 You are required to inspect the Licensed Software immediately
          about issues discovered without delay by email. The defect report will
          be taken into consideration and further investigated if it has been
          emailed within a period of three (3) days after discovery.
        </p>

        <p>
          8.4 If we confirm that the Licensed Software is defective, the
          Licensor reserves a choice to remedy the situation either by means of
          solving the defect or substitute delivery.
        </p>

        <p>
          8.5 In the event of any failure of the Licensed Software to conform to
          any applicable warranty, the Licensee has the following remedy:
        </p>

        <p>
          a) The Licensee shall have a right to raise a cancellation request by
          addressing an email addressed to support@restaverse.com ;
        </p>

        <p>
          b) Upon receipt of the cancellation request, within 72 hours of
          receipt of the cancellation request, the authorised representative of
          the Licensor shall get in touch with the Licensee and understand the
          issues being faced by the Licensee. Incase, the issues faced by the
          Licensee are resolved to the satisfaction of the Licensee, the
          cancellation request shall be closed.
        </p>
        <p>
          c) However, incase, the issues faced by the Licensee cannot be
          resolved, the Licensor shall refund the License Fees for remaining
          term of the present to licensee within a period of 72 working hours
          therefrom.
        </p>

        <p>
          8.6 If the user is an entrepreneur, any claim based on faults expires
          after a statutory period of limitation amounting to twelve (12) months
          after the Licensed Application was made available to the user. The
          statutory periods of limitation given by law apply for users who are
          consumers.
        </p>
        <br></br>
        <h3 id="softwareclaims" className="policiesh3">9. LICENSED SOFTWARE CLAIMS</h3>

        <p>
          The Licensor and the End-User acknowledge that the Licensor is
          responsible for addressing any claims of the End-User or any third
          party relating to the Licensed Application or the End-User’s
          possession and/or use of that Licensed Application, including, but not
          limited to:
        </p>
        <p>(i) Licensed Software claims;</p>
        <p>
          (ii) any claim that the Licensed Application fails to conform to any
          applicable legal or regulatory requirement; and
        </p>
        <p>
          (iii) claims arising under consumer protection, privacy, or similar
          legislation, including in connection with the License’s Licensed
          Application’s use at the personal level.
        </p>
        <br></br>
        <h3 id="contactinformation" className="policiesh3">
          10. CONTACT INFORMATION
        </h3>

        <p>
          For general inquiries, complaints, questions or claims concerning the
          Licensed Application, please contact:
        </p>

        <p>RESTAVERSE</p>
        <p>2002, 20th Floor, Darshan Pride,</p>
        <p>Sitaram Ghadigaonkar Marg,</p>
        <p>Nr Rosewood Hotel, Tardeo Mumbai 400034</p>
        <p>info@restaverse.com</p>
        <br></br>
        <h3 id="termination" className="policiesh3">
          11. TERMINATION
        </h3>

        <p>
          The license is valid until terminated by the Licensor or by You. Your
          rights under this license will terminate automatically and without
          notice from Licensor if You fail to adhere to any term(s) of this
          license. Upon License termination, You shall stop all use of the
          Licensed Application, and destroy all copies, full or partial, of the
          Licensed Application.
        </p>
        <br></br>
        <h3 id="thridparty" className="policiesh3">
          12. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
        </h3>

        <p>
          The Licensor represents and warrants that the Licensor will comply
          with applicable third-party terms of agreement when using Licensed
          Application.
        </p>
        <br></br>
        <h3 id="limitedliability" className="policiesh3">
          13. LIMITED LIABILITY
        </h3>

        <p>
          The aggregate liability of the Licensor and its directors, officers,
          employees, agents, sub-contractors in respect of any claims, losses,
          costs or damages arising out of or related to this Agreement shall not
          exceed the Application fees received by the Licensor under this
          Agreement. Further, Licensor's obligation shall also be restricted
          only to actual and direct losses and not to any indirect, special or
          consequential losses. The Licensor shall not be subjected to any
          punitive or exemplary damages.
        </p>
        <br></br>
        <h3 id="ip" className="policiesh3">
          14. INTELLECTUAL PROPERTY RIGHTS
        </h3>

        <p>
          The Licensor and the End-User acknowledge that, in the event of any
          third-party claim that the Licensed Application or the End-User's
          possession and use of that Licensed Application infringes on the third
          party's intellectual property rights, the Licensor will be solely
          responsible for the investigation, defence, settlement, and discharge
          or any such intellectual property infringement claims.
        </p>
        <br></br>
        <h3 id="applicablelaw" className="policiesh3">
          15. APPLICABLE LAW
        </h3>

        <p>
          This agreement shall be governed by the laws of India. Any disputes or
          differences arising out of or in connection with this agreement (the
          “Dispute”) shall be resolved by arbitration administered under the
          Arbitration and Conciliation Act, 1996. The seat and venue of the
          arbitration shall be Mumbai. The Tribunal shall consist of a single
          arbitrator. The language of the arbitration shall be English.
        </p>
        <br></br>
        <h3 id="miscellaneous" className="policiesh3">
          16. MISCELLANEOUS
        </h3>

        <p>
          16.1 If any of the terms of this agreement should be or become
          invalid, the validity of the remaining provisions shall not be
          affected. Invalid terms will be replaced by valid ones formulated in a
          way that will achieve the primary purpose. The License Agreement shall
          be in consonance with the terms of service and the privacy policies
          available on the website of the Licensor.
        </p>

        <p>
          Collateral agreements, changes and amendments are only valid if laid
          down in writing. The preceding clause can only be waived in writing.
        </p>
      </main>
      <TableOfContents />
    </div>
  );
};

export default EndUserLicenceAgreement;
