/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */
import React from "react";
// import "./termsservices.css";
import { useEffect } from "react";
import "../AllPoliciesCss/allpolicies.css";

const Headings = ({ headings, activeId }) => (
  <ul>
    {headings.map((heading) => (
      <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
        <a
          className="policiesheadings"
          href={`#${heading.id}`}
          onClick={(e) => {
            e.preventDefault();
            document.querySelector(`#${heading.id}`).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          {heading.title}
        </a>
        {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li
                key={child.id}
                className={child.id === activeId ? "active" : ""}
              >
                <a
                  href={`#${child.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector(`#${child.id}`).scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  {child.title}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    ))}
  </ul>
);

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  React.useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("main h2, main h3")
    );

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title,
      });
    }
  });

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});
  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
        headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: document.querySelector("iframe"),
      rootMargin: "500px",
    });

    const headingElements = Array.from(document.querySelectorAll("h2, h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
    <nav aria-label="Table of contents" className="policies-nav">
      <Headings headings={nestedHeadings} activeId={activeId} />
    </nav>
  );
};

const TermsServices = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="policies-container"
      style={{ fontFamily: 'font-family: "Poppins , sans-serif",' }}
    >
      <main className="policies-container-main ">
        <h2 id="introduction" className="policiesh2">
          Terms of Service
        </h2>
        <p>
          When you use our services, you’re trusting us with your information.
          We understand this is a big responsibility and work hard to protect
          your information and put you in control.
        </p>
        <p>
          The present Terms of Service shall form a valid and legally binding
          contract between a subscriber of Restaverse Private Limited
          (“Subscriber”) and Restaverse Private Limited (“Restaverse”), and
          governs our Subscriber’s access to and use of our Services. By
          accessing and/or using our Services you are accepting that:
        </p>

        <p>
          A. All visitors and/ or Subscribers by using the Restaverse website do
          hereby unconditionally consent to the present Privacy Policy and agree
          to its Terms of Use.
        </p>
        <p>
          B. The Subscriber either himself and/ or any person accepting the
          terms on behalf of the Subscriber warrants that they are aged 18 years
          or above, are competent to enter into this Agreement. Subscriber and
          Restaverse are individually referred to as “Party” and collectively as
          “Parties”.
        </p>

        <p>
          NOW THEREFORE in consideration of the covenants and agreements
          contained int eh agreement and for the good and valuable
          consideration, the Parties hereto, intending to be legally and
          contractually bound agree as follows:
        </p>

        <p>
          At Restaverse, accessible from https://www.restaverse.com and
          https://app.restaverse.com one of our main priorities is the privacy
          of our visitors/subscribers. The present Privacy Policy contains the
          following types of information that is collected and recorded by
          Restaverse and how we use it.
        </p>

        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors/subscribers to our website with regards to the
          information that are shared and/or collected by Restaverse. This
          policy is not applicable to any information collected offline or via
          channels other than this website.
        </p>
        <br></br>
        <h2 id="consent" className="policiesh2">
          1. Definitions
        </h2>
        <h3 id="account" className="policiesh3">
          1.1) Account
        </h3>
        <p>
          {" "}
          This means any account(s) created by or on Subscriber’s behalf for
          access and use of the Services being provided by Restaverse.
        </p>
        <h3 id="ads" className="policiesh3">
          1.2) Advertisement/Marketing/Promotion Expenses
        </h3>
        <p>
          {" "}
          This means the amount a particular Subscriber spends on the marketing
          or promotion of their restaurant(s), including discount coupons’
          amount or advertisement expenses per month.
        </p>
        <h3 id="subscriber-data" className="policiesh3">
          1.3) Subscriber Data
        </h3>
        <p>
          {" "}
          This means all electronic data, text, messages, or other materials,
          including personal Data, (but excludes any and all data (including
          Personal Data) of Subscriber’s customers)) provided by Subscriber, or
          provided or obtained for or on Subscriber’s behalf, on the basis of
          which the Service(s) will be provided and/or the Subscriber Data will
          be submitted to the Services by the Subscriber through Subscriber’s
          Account for Subscriber’s use of the Services.
        </p>
        <h3 id="Confidential-Information" className="policiesh3">
          1.4) Confidential-Information
        </h3>
        <p>
          {" "}
          means all information disclosed by one Party to the other Party which
          is in tangible form and labelled “confidential” (or with a similar
          legend) or which a reasonable person would understand to be
          confidential given the nature of the information and circumstances of
          disclosure. Notwithstanding the foregoing, Confidential Information
          shall not include any information which (a) was publicly known and
          made generally available in the public domain prior to the time of
          disclosure by the disclosing party; (b) becomes publicly known and
          made generally available after disclosure by the disclosing party to
          the receiving party through no action or inaction of the receiving
          party; (c) is already in the possession of the receiving party at the
          time of disclosure by the disclosing party as shown by the receiving
          party’s files and records prior to the time of disclosure; (d) is
          obtained by the receiving party from a third-party without a breach of
          such third-party’s obligations of confidentiality; (e) is
          independently developed by the receiving party without use of or
          reference to the disclosing party’s Confidential Information, as shown
          by documents and other competent evidence in the receiving party’s
          possession; or (f) is required by law to be disclosed by the receiving
          party, provided that the receiving party shall, to the extent legally
          permitted, give the disclosing party written notice of such
          requirement prior to disclosing so that the disclosing party may seek
          a protective order or other appropriate relief. ‍
        </p>
        <h3 id="Personal-data" className="policiesh3">
          1.5)Personal Data
        </h3>
        <p>
          {" "}
          This means any information or data relating to an identified or
          identifiable natural person, as provided under the Information
          Technology Act, 2002, as amended from time to time, or such other
          similar data privacy laws that may be applicable.‍
        </p>
        <h3 id="Privacy-Policy" className="policiesh3">
          1.6) Privacy Policy
        </h3>
        <p>
          {" "}
          This means Restaverse’s privacy policy available at
          https://www.restaverse.com/ and https://app.restaverse.com/
        </p>
        <h3 id="Process-processes-processing" className="policiesh3">
          1.7) Process, processes, or processing
        </h3>
        <p>
          This means any operation or set of operations which are performed on
          Subscriber Data or on sets of Subscriber Data, whether or not by
          automated means, such as collection, recording, organisation,
          structuring, storage, adaptation or alteration, retrieval,
          consultation, use, disclosure by transmission, dissemination or
          otherwise making available, alignment or combination, restriction,
          erasure or destruction, and also includes transferring Subscriber Data
          to third parties.
        </p>
        <h3 id="Services" className="policiesh3">
          1.8) Services
        </h3>
        <p>
          This means the a unified dashboard for the business owners in the
          hospitality sector that captures several data points and assists the
          owners in effective implementation of various business strategies like
          advertisements, promotions, customer benchmarking, management calls
          etc.
        </p>
        <h3 id="Software" className="policiesh3">
          1.9) Software
        </h3>
        <p>
          {" "}
          This means software provided by Restaverse that allows the Subscribers
          to use any functionality in connection with the Services.‍
        </p>

        <h3 id="Subscription-Charges" className="policiesh3">
          1.10) Subscription Charges
        </h3>
        <p>
          This means all charges associated with Subscriber’s Account and use of
          the Services‍.
        </p>

        <h3 id="Subscription-Term" className="policiesh3">
          1.11) Subscription Term
        </h3>
        <p>
          {" "}
          This means the period during which Subscriber has agreed to subscribe
          to the Services as specified in a relevant Order Form.‍
        </p>
        <br></br>
        <h2 id="Subscriber-Rights" className="policiesh2">
          2. Subscriber’s Rights
        </h2>
        <p>
          Subject to Subscriber’s compliance with the present Agreement and
          solely during the Term of its subscription, Subscriber has the
          limited, non-exclusive, revocable right to access and use the Services
          for its internal business purposes in accordance with the subscription
          plan obtained by the Subscriber.
        </p>
        <br></br>
        <h2 id="Subscriber-Obligations" className="policiesh2">
          3. Subscriber Obligations
        </h2>
        <p>
          3.1 Subscriber Account: Each user/ Subscriber shall be identified
          using unique login information such as usernames and passwords
          (“Subscriber Login”) and such Subscriber Login shall be exclusive to
          the particular Subscriber.
        </p>

        <p>
          3.2 Acceptable Use: Subscriber agrees not to (a) license, sublicense,
          sell, resell, rent, lease, transfer, assign, distribute, time share or
          otherwise commercially exploit or make the Services available to any
          third-party, other than Users in furtherance of its internal business
          purposes as expressly permitted by this Agreement; (b) modify, adapt,
          or hack the Services or otherwise attempt to gain or gain unauthorized
          access to the Services or related systems or networks; (c) use the
          Services to store or process any content that infringes upon any
          person’s and/ or Restaverse’s intellectual property rights or is
          unlawful, racist, hateful, abusive, libellous, obscene, or
          discriminatory; or (d) “crawl,” “scrape,” or “spider” any page, data,
          or portion of or relating to the Services (through use of manual or
          automated means);
        </p>

        <p>
          3.3 Subscriber agrees that the Services shall be provided only upon
          prompt access to the requisite data being granted by the Subscriber to
          Restaverse.
        </p>

        <p>
          3.4 Subscriber represents and warrants to Restaverse that the
          Subscriber owns or has the necessary rights and consents to provide
          the requisite data to Restaverse and that doing so does not violate
          any applicable law, proprietary or privacy rights. Restaverse will be
          under no liability for any claims with respect to the Services arising
          in connection with the unavailability, inaccuracy or insufficiency of
          requisite Data being provided by the Subscriber.
        </p>
        <br></br>
        <h2 id="services" className="policiesh2">
          4. Services
        </h2>
        <p>
          4.1 Restaverse shall be providing the following Services to the
          Subscriber:
        </p>

        <p>
          a) Unified dashboard captures several data points and assists the
          owners in effective implementation of various business strategies like
          advertisements, promotions, customer benchmarking, management calls
          etc.
        </p>

        <p>
          b) Analyse and process data to provide the Subscriber with tailor made
          solutions in respect of his/her restaurant business.
        </p>

        <p>
          c) Use the same in respect of understanding market trends to provide
          business solutions and strategies
        </p>

        <p>
          d) Establishing marketing and business development strategies for the
          Subscriber’s business.
        </p>

        <p>
          e) Optimise business efficiency and minimisation of business risks.
        </p>

        <p>
          f) Communicate with the subscriber, either directly or through one of
          its Subscribers, including for customer service, to provide the
          Subscriber’s with updates and other information relating to the their
          website, and for marketing and promotional purposes.
        </p>

        <p>
          4.2 Any enhancements, new features or upgradations to the Services are
          also subject to these Terms and Restaverse reserves its right to
          deploy the same at any time within prior intimation to the
          Subscribers.
        </p>

        <p>
          4.3 The Services may temporarily be unavailable due to scheduled
          downtime for upgrades and maintenance in which case Restaverse shall
          use reasonable endeavours to notify the Subscriber in advance.
        </p>

        <p>
          4.4 Notwithstanding anything to the contrary contained elsewhere,
          Restaverse shall not be liable for unavailability of the Services
          caused by circumstances beyond ‘Restaverse’s reasonable control, such
          as, but not limited to, acts of God, acts of government, acts of
          terror or civil unrest, technical failures beyond Restaverse's
          reasonable control (including, without limitation, inability to access
          the internet), or acts undertaken by third-parties, including without
          limitation, distributed denial of service attacks.
        </p>
        <br></br>
        <h2 id="Intellectual-Property-Rights" className="policiesh2">
          5. Intellectual Property Rights
        </h2>

        <p>
          {" "}
          5.1 Except for the rights granted to the Subscriber under clause 3,
          all rights, title and interest in and to all intellectual property
          and/or proprietary rights, title and interest in or related to the
          Services, including patents, inventions, copyrights, trademarks,
          domain names, trade secrets or know-how shall belong to and remain
          exclusively with Restaverse.
        </p>

        <p>
          5.2 Subscriber owns the rights to the requisite data that the
          Subscriber provides to Restaverse.
        </p>

        <p>
          5.3 Subscriber does not claim ownership over such Subscriber Data,
          however, by posting or transferring Subscriber Data to Restaverse, the
          Subscriber grants a non-exclusive license and permission to use the
          Subscriber Data generated by Subscriber solely to provide, maintain
          and improve the Services.
        </p>

        <p>
          5.4 All rights not expressly provided to Subscriber herein are
          reserved.
        </p>
        <br></br>
        <h2 id="Third-Party-Services" className="policiesh2">
          6. Third-Party Services
        </h2>

        <p>
          Subscriber acknowledges and agrees that Subscriber’s use of
          Third-Party Services will be subject to the terms and conditions and
          privacy policies of such third-party and that Restaverse shall not be
          liable for Subscriber’s enablement, access or use of such Third-Party
          Services, including for processing of Subscriber’s data by such
          third-party. The Subscriber should contact that Third-Party Service
          provider for any issues arising in connection with use of such
          Third-Party Service.
        </p>
        <br></br>
        <h2 id="Charges-And-Payments" className="policiesh2">
          7. Charges And Payments
        </h2>

        <p>
          7.1 Subscription Charges: The Subscription Charges are due in full and
          payable in advance when Subscriber subscribes to the Services. The
          Subscription Charges shall be specified at the time of subscribing to
          the Services of Restaverse.
        </p>

        <p>
          7.2 Payment: Subscriber hereby authorizes Restaverse or its authorized
          agents, as applicable, to bill Subscriber upon Subscriber’s
          subscription to the Services (and any renewal thereof).
        </p>

        <p>
          7.3 Refunds: Unless otherwise specified in this Agreement, all
          Subscription Charges are non- refundable. No refunds shall be issued
          for partial use or non-use of the Services by Subscriber.
        </p>

        <p>
          7.4 Non-payment of Subscription Charges: Restaverse will notify
          Subscriber if Restaverse does not receive payment towards the
          Subscription Charges within the due date for Restaverse’s Account.
          Restaverse must receive payments within a maximum of ten (10) days
          from the date of Restaverse' notice. If Restaverse does not receive
          payment within the foregoing time period, in addition to its right to
          other remedies available under law, Restaverse may (i) charge an
          interest for late payment @ 1.5% per month and/or; (ii) suspend
          Subscriber’s access to and use of the services until Restaverse
          receives Subscriber’s payment towards the Subscription Charges as
          specified herein and/or; (iii) terminate Subscriber’s Account.
        </p>

        <p>
          7.5 Applicable Taxes: Unless otherwise stated, the Subscription
          Charges include the applicable taxes (collectively “Taxes”).
        </p>
        <br></br>
        <h2 id="Term-Termination-Suspension" className="policiesh2">
          8. Term, Termination, Suspension
        </h2>

        <p>
          8.1 It is stated that per Subscription Term shall be for a minimum
          period of 1 year. Thereafter, the same can be renewed with mutual
          consent of the parties and on such terms as agreed between the
          parties.
        </p>

        <p>
          8.2 Termination by Subscriber: The Subscriber may terminate their
          Account and subscription to the Services at any time with thirty (30)
          days prior written notice, but will remain liable for all Subscription
          Charges for the Subscription Term. Unless the Subscriber’s Account and
          subscription to the Services is terminated, the subscription to the
          Services will renew for a Subscription Term equivalent in length to
          the then expiring Subscription Term at Restaverse’s then prevailing
          Subscription Charges.
        </p>

        <p>
          8.3 Termination and Suspension by Restaverse: In addition to
          suspension for late payment or non- payment of Subscription Charges,
          Restaverse may suspend Subscriber’s access to and use of the
          Subscriber’s Account or the Services if the Subscriber is in violation
          of the terms of this Agreement. Restaverse will notify the Subscriber
          of its activities that violate this Agreement and provide the
          Subscriber with a period of thirty (30) days to cure or cease such
          activities (“Cure Period”). If the Subscriber does not cure or cease
          such activities within the said Cure Period or if Restaverse believes
          that Subscriber’s breach of this Agreement cannot be cured, the
          Subscriber’s Account shall be terminated immediately.
        </p>

        <p>
          8.4 Termination for Insolvency: Notwithstanding anything contained
          herein, either Party may terminate this Agreement without notice if
          the other Party becomes insolvent, makes or has made an assignment for
          the benefit of creditors, is the subject of proceedings in voluntary
          or involuntary bankruptcy instituted on behalf of or against such
          Party (except for involuntary bankruptcies which are dismissed within
          sixty (60) days, or has a receiver or trustee appointed for
          substantially all of its property.
        </p>

        <p>
          8.5 Effect of Terminating Subscriber’s Account: Following the
          termination of Subscriber’s Account either by Subscriber or
          Restaverse, the Subscriber may export Subscriber Data from within the
          Services within six (6) months of effective termination of the
          Subscriber’s Account (“Data Retention Period”), beyond which period
          Restaverse may delete all Subscriber Data. The Subscriber may
          reactivate their terminated Account within such Data Retention Period,
          unless the termination is by Restaverse.
        </p>
        <br></br>
        <h2 id="Confidentiality" className="policiesh2">
          9. Confidentiality
        </h2>

        <p>
          9.1 If the Subscriber chooses or is provided with a user
          identification code, login, password or any other information as part
          of Restaverse' security procedures, Subscriber must treat such
          information as confidential and shall not disclose such information to
          any third-party other than a User. Restaverse has the right to disable
          any user identification code or password, whether chosen by Subscriber
          or allocated by Restaverse, at any time, if in Restaverse' reasonable
          opinion, Subscriber has failed to comply with any of the provisions of
          this Agreement. Restaverse will not be responsible for any activities,
          including any attempted or actual access or loss of data occurring
          under Subscriber’s Account as a result of Subscriber’s non-compliance
          of its obligations under this clause.
        </p>

        <p>
          9.2 Confidentiality obligations: Each of the Parties will protect the
          other’s Confidential Information from unauthorized use, access or
          disclosure in the same manner as each of the Parties protects its own
          Confidential Information, and in any event, no less than reasonable
          care. Except as otherwise expressly permitted under this Agreement,
          each of the Parties may use the other’s Confidential Information
          solely to exercise its respective rights and perform its respective
          obligations under this Agreement and shall disclose such Confidential
          Information solely to those of its respective employees,
          representatives and agents who have a need to know such Confidential
          Information for such purposes and who are bound to maintain the
          confidentiality of, and not misuse, such Confidential Information. The
          provisions of this sub- clause shall supersede any non-disclosure
          agreement by and between the Parties entered prior to this Agreement
          that would purport to address the confidentiality of any Confidential
          Information and such agreement shall have no further force or effect
          with respect to the Confidential Information.
        </p>
        <br></br>
        <h2 id="Data-Protection" className="policiesh2">
          10. Data Protection
        </h2>

        <p>
          10.1 Restaverse and Subscriber understands and acknowledges that
          Restaverse does not, obtain, use or disclose any Personal Data from
          Subscribers’ customers for any purpose other than for providing the
          Services under this Agreement. Restaverse may use Personal Data
          received from Subscriber only when the consent to share the Personal
          Data has been given to Restaverse, including business improvement.
        </p>

        <p>
          10.2 The Subscriber agrees that Restaverse may process any Subscriber
          Data or Personal Data in accordance with this Agreement and the
          Privacy Policies available on the website.
        </p>

        <p>
          10.3 The Subscriber agrees that Restaverse may Process Subscriber Data
          to provide, maintain and improve the Services, or prevent or address
          any technical problems.
        </p>

        <p>
          10.4 The Subscriber agrees that Restaverse may access or disclose
          information about the Subscriber, their Account to comply with the law
          or to prevent infringement of Restaverse’s Subscribers’ or
          Restaverse’s proprietary rights. At Restaverse' sole discretion, any
          suspected fraudulent, abusive, or illegal activity by the Subscriber
          may be referred to law enforcement authorities.
        </p>

        <p>
          10.5 Restaverse shall use appropriate technical and organizational
          measures to protect the Subscriber Data. The measures used are
          designed to provide a level of security appropriate to the risk of
          Processing the data. Restaverse shall ensure that any person
          authorized by Restaverse to Process the Subscriber Data shall protect
          it in accordance with Restaverse' confidentiality obligations under
          this Agreement.‍
        </p>

        <p>
          10.6 Restaverse shall, without undue delay, notify the Subscriber
          and/or User of any accidental or unlawful destruction, loss,
          alteration, unauthorized disclosure of, or access to the Subscriber
          Data or User’s Personal Data processed by Restaverse.
        </p>

        <p>
          10.7 Restaverse may perform analytics on Subscriber Data to improve,
          enhance, support and operate the Services and compile statistical
          reports and record insights into usage patterns. Subscriber
          acknowledges that Restaverse uses Subscriber Data for the
          aforementioned purpose in compliance with applicable laws.
        </p>

        <p>
          10.8 Subscriber acknowledges and agrees that it is solely responsible
          for complying with all applicable data protection laws including
          obtaining all necessary consents and authorisations to collect any of
          its Subscribers Personal Data for use with the Services under this
          Agreement.
        </p>
        <br></br>
        <h2 id="Warranties" className="policiesh2">
          11. Warranties
        </h2>

        <p>
          11.1 The services, including all server and network components are
          provided on an “as is” and “as available” basis. all express or
          implied representations and warranties, including any implied warranty
          of merchantability, fitness for a particular purpose, accuracy or
          non-infringement, are hereby excluded.
        </p>

        <p>
          11.2 The Subscriber acknowledges that Restaverse does not warrant that
          the access to the service, which is provided over internet and various
          telecommunications networks, all of which are beyond Restaverse’
          control, will be uninterrupted, timely, secure, error-free or free
          from viruses or other malicious software.
        </p>
        <br></br>
        <h2 id="Limitation-of-Liabilities" className="policiesh2">
          12. Limitation of Liabilities
        </h2>

        <p>
          To the fullest extent permitted by applicable law, in no event will
          either party, its affiliates, officers, directors, employees, agents,
          suppliers or licensors be liable to any person for any indirect,
          incidental, special, punitive, cover or consequential damages
          (including, without limitation, damages for lost profits, lost
          revenue, lost sales, lost goodwill, loss of use or lost content,
          impact on business, business interruption, loss of anticipated
          savings, loss of business opportunity) however caused, under any
          theory of liability, including, without limitation, contract, tort,
          warranty, breach of statutory duty, negligence or otherwise, except
          payment obligations of the Subscriber even if either party has been
          advised as to the possibility of such damages or could have foreseen
          such damages to the maximum extent permitted by applicable law,
          Restaverse’ aggregate liability and that of its affiliates, officers,
          employees, agents, suppliers and licensors, relating to the services,
          will be limited to an amount equal to the lower of (a) 3 months of the
          subscription charges paid for the services; or (b) the charges paid by
          Subscriber, for the services prior to the first event or occurrence
          giving rise to such liability.
        </p>
        <br></br>
        <h2 id="Indemnification" className="policiesh2">
          {" "}
          13. Indemnification
        </h2>

        <p>
          The Subscriber will indemnify and hold Restaverse harmless against any
          claim brought by a third-party against Restaverse, and its respective
          employees, officers, directors and agents arising from Subscriber’s
          acts or omissions provided that (a) Restaverse promptly notifies
          Subscriber of the threat or notice of such a claim, (b) Subscriber has
          or will have the sole and exclusive control and authority to select
          legal representatives, or to defend and/or settle any such claim; and
          (c) Restaverse fully cooperates with Subscriber in connection
          therewith. Subscriber will have no obligation or liability with
          respect to any such claim arising out of the gross negligence or
          wilful misconduct of Restaverse.
        </p>
        <br></br>
        <h2 id="Miscellaneous" className="policiesh2">
          14. Miscellaneous
        </h2>

        <p>
          14.1 Entire Agreement and Revisions: This Agreement, together with any
          schedules or annexes, constitute the entire agreement, and supersede
          any and all prior agreements, written or oral, between Subscriber and
          Restaverse with regard to the subject matter hereof. This Agreement
          shall prevail over the terms or conditions in any purchase order or
          other order documentation Subscriber or any entity that Subscriber
          represents provides (all such terms or conditions being null and
          void), and, except as expressly stated herein, there are no other
          agreements, representations, warranties, or commitments which may be
          relied upon by either Party with respect to the subject matter hereof.
          In case of a conflict between this Agreement and an Order Form, the
          Order Form shall prevail. In case of a conflict between two Order
          Forms, the Order Form later in date shall prevail. Upon mutual
          consent, this Agreement may be amended in writing, in which case the
          latest version of this Agreement will supersede prior versions.
        </p>

        <p>
          14.2 Severability and Waiver: If any provision in this Agreement is
          held by a court of competent jurisdiction to be unenforceable, such
          provision shall be modified or interpreted so as to best accomplish
          the original intent of that provision to the fullest extent permitted
          by applicable law, and the remaining provisions of this Agreement
          shall remain unaffected and in effect. Restaverse' non-exercise of any
          right under or provision of this Agreement does not constitute a
          waiver of that right or provision of this Agreement.
        </p>

        <p>
          14.3 Relationship between the Parties: The Parties are independent
          contractors. This Agreement does not create a partnership, franchise,
          joint venture, agency, fiduciary or employment relationship among the
          Parties.
        </p>

        <p>
          14.4 Survival of Clauses: All clauses which, by their nature are
          intended to survive, including without limitation clauses in respect
          to Definitions, Intellectual Property Rights, Confidentiality, Charges
          and Payment, Warranties, Limitation of Liability, Indemnification,
          Dispute Resolution shall survive any termination of Restaverse'
          agreement with respect to use of the Services by the Subscriber.
          Termination shall not limit or waive either Party’s liability for
          obligations accrued as of or prior to such termination or for any
          breach of this Agreement.
        </p>

        <p>
          14.5 Notices: All notices to be provided by Restaverse to Subscriber
          under this Agreement may be delivered in writing at the address
          provided by the subscriber or (ii) by electronic mail on the email
          address provided for the Subscriber’s Account.
        </p>

        <p>
          14.6 Publicity Rights: Subscriber hereby grants Restaverse the right
          to identify Subscriber as a user of Restaverse' Services on
          Restaverse’ websites and/or marketing collateral and to include
          Subscriber’s use of the Services in case studies.
        </p>

        <p>
          14.7 Governing Law and Dispute Resolution: This Agreement shall be
          governed by the laws of India. Any disputes or differences arising out
          of or in connection with this Agreement (the “Dispute”) shall be
          resolved by arbitration administered under the Arbitration and
          Conciliation Act, 1996. The seat and venue of the arbitration shall be
          Mumbai. The Tribunal shall consist of a single arbitrator. The
          language of the arbitration shall be English.
        </p>
      </main>
      <TableOfContents />
    </div>
  );
};
export default TermsServices;
