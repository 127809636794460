import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import thirtynine from '../../images/blogs/39.png';
import forty from '../../images/blogs/40.png';
const Blogtwenty = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Why Deliveries Won't Hurt Your Dine-In: Breaking the Myth of Cannibalization
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Valeron Toscano"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/valeron-toscano/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Valeron Toscano</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: July 13, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In 2023, it was reported that <a href='https://www.owner.com/blog/food-delivery-statistics​' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>77%</a> of customers opted for delivery while only <a href='https://www.owner.com/blog/food-delivery-statistics' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>61%</a> chose to dine in​. The rise of delivery services has ignited a debate among restaurant owners: Does offering delivery cannibalize dine-in sales? While many fear that promoting delivery will deter customers from enjoying the restaurant experience, this concern is often misplaced. Let’s explore why embracing delivery can actually complement and enhance your dine-in service, rather than detract from it.
                                <br></br><br></br>
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={thirtynine}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Understanding Cannibalization
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Cannibalization happens when a new product or service harms the sales of an existing one. For restaurants, this often means worries that diners will choose delivery over dining in. While these concerns are valid, they overlook the broader benefits delivery can offer, such as expanding your customer base and increasing revenue.
                                <br></br> <br></br>
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <ul>
                                    <li style={{ listStyleType: 'square' }}>
                                        <p><b style={{fontWeight:500}}>Expanding Your Customer Base</b>
                                            <br></br>
                                            Delivery services enable you to connect with customers who may find it challenging to visit your restaurant due to distance or busy schedules. By offering delivery, you can reach a broader audience that might not have considered dining in before. Satisfied first-time delivery customers may be inspired to visit your restaurant in person, eager to enjoy the atmosphere and explore your full menu.
                                        </p>
                                    </li>
                                    <li style={{ listStyleType: 'square' }}>
                                        <p>
                                        <b style={{fontWeight:500}}>Utilizing Delivery to Boost Promotions</b>
                                            <br></br>
                                            Delivery platforms present a valuable chance to promote your dine-in experience. <a href='https://www.owner.com/blog/food-delivery-statistics' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>55% of customers</a> pick restaurants that they visit often when ordering food delivery. Implement special offers that entice customers to visit your restaurant, like exclusive discounts for delivery orders redeemable during their next dine-in visit. This approach can successfully increase foot traffic and enhance your overall sales.
                                        </p>
                                    </li>
                                    <li style={{ listStyleType: 'square' }}>
                                        <p>
                                        <b style={{fontWeight:500}}>Unlock Customer Insights Through Delivery Data</b>
                                            <br></br>
                                            Examining delivery order data reveals important insights into customer preferences. By determining which dishes resonate most with delivery customers, you can enhance your in-house menu and design targeted promotions. This alignment with customer tastes will not only refine your offerings but also entice diners to experience your restaurant’s ambiance and service in person.
                                        </p>
                                    </li>
                                    <li style={{ listStyleType: 'square' }}>
                                        <p>
                                        <b style={{fontWeight:500}}>Creating a Flexible Dining Experience</b>
                                            <br></br>
                                            Modern consumers value flexibility. <b style={{fontWeight:500}}>According to a recent survey, 83% of customers appreciate having both dine-in and delivery options.</b> By providing a variety of dining options, you cater to a diverse range of customer needs and lifestyles. Whether celebrating a special occasion or enjoying a cozy night in, your restaurant can accommodate various preferences, enhancing brand loyalty as customers appreciate the versatility.
                                        </p>
                                    </li>

                                    <li style={{ listStyleType: 'square' }}>
                                        <p>
                                        <b style={{fontWeight:500}}>Optimizing Operational Efficiency</b>
                                            <br></br>
                                            Many restaurants that adopt delivery find it simplifies their operations. <b style={{fontWeight:500}}>Research indicates that 71% of customers are more likely to return to a restaurant that has fast and reliable service.</b> By implementing efficient systems to manage both delivery and dine-in orders, you can significantly improve service times and enhance the overall dining experience. This streamlined approach not only boosts efficiency but also elevates customer satisfaction, benefiting both delivery patrons and those dining in.
                                        </p>
                                    </li>
                                </ul>

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={forty}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                               Conclusion: Embrace the Change
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Don’t let fear of cannibalization overshadow the incredible opportunities delivery brings! Instead of viewing it as competition for dine-in sales, see delivery as a powerful service that fuels your growth. Expand your reach, attract new customers, and optimize operations. With delivery, you’re not just boosting revenue; you’re creating a thriving ecosystem that drives foot traffic. Let delivery be your secret weapon for a brighter future!
                                <br></br><br></br>
                                Discover how <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse</a> can help you revolutionize your restaurant by seamlessly integrating delivery into your operations while elevating the dine-in experience. Together, we can drive growth and success!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogtwenty;