import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid } from '@mui/material';
import three from '../../images/blogs/3.png';
import five from '../../images/blogs/5.png';
import seven from '../../images/blogs/7.png';

const FeaturedArticle = () => {
    const articles = [
        {
            image: three,
            title: "From Feedback to Action: Tips for Tracking Restaurant Reviews",
            description: "From Feedback to Action: Tips for Tracking Restaurant Reviews",
            author: "Charmi Jain",
            date: "29/07/2024",
            link: "/blogs/from-feedback-to-action-tips-for-tracking-restaurant-reviews" 
        },
        {
            image: five,
            title: "Unlocking the Potential of Automation in Restaurant Review Management",
            description: "Unlocking the Potential of Automation in Restaurant Review Management",
            author: "Manish Swami",
            date: "28/05/2024",
            link: "/blogs/unlocking-the-potential-of-automation-in-restaurant-review-management"
        },
        {
            image: seven,
            title: "The Impact of Online Reviews on Restaurant Revenue",
            description: "The Impact of Online Reviews on Restaurant Revenue",
            author: "Arsh Khanna",
            date: "19/07/2024",
            link: "/blogs/the-impact-of-online-reviews-on-restaurant-revenue"
        }
    ];

    return (
        <Box sx={{ flex: 1, marginLeft: { xs: 0, sm: '10px' }, marginTop: { xs: 2, sm: 0 } }}>
            <Typography variant="h6" gutterBottom
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Lexend Deca, sans-serif'
                }}
            >
                Featured Articles
            </Typography>
            <Box sx={{ flexGrow: 1, height: '2px', backgroundColor: 'rgb(108, 65, 255)' }} />

            {articles.map((article, index) => (
                <React.Fragment key={index}>
                    <a href={article.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Card
                            sx={{
                                marginTop: 2,
                                backgroundColor: 'rgb(247, 245, 255)',
                                boxShadow: 'none',
                                width: { xs: '100%', sm: '100%', md: '106%' },
                                marginLeft: '-15px',
                                '&:hover': {
                                    '& .article-title, & .date-wrapper': {
                                        textDecoration: 'underline',
                                        textDecorationColor: 'black',
                                    },
                                },
                            }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <CardMedia
                                            sx={{
                                                height: { xs: 118, sm: 100 },
                                                width: { xs: '100%', sm: 140 },
                                            }}
                                            image={article.image}
                                            title={article.title}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2"
                                            sx={{ color: '#213343', marginBottom: '30px', fontFamily: 'Lexend Deca, sans-serif', fontSize: "14px" }}
                                            className="article-title"
                                        >
                                            {article.description}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginTop: '10px'
                                            }}
                                        >
                                            <Typography sx={{ color: '#517596', fontSize: "14px" }}>{article.author}</Typography>
                                            <Typography variant="body2" sx={{ color: '#517596', fontSize: "14px" }}>
                                                {article.date}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </a>


                    {index !== articles.length - 1 && (
                        <Box sx={{ flexGrow: 1, height: '2px', backgroundColor: 'rgb(211,211,211)', marginTop: "10px" }} />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default FeaturedArticle;
