import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import twentyone from '../../images/blogs/21.png';
import twentytwo from '../../images/blogs/22.png';
const Blogeleven = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Revamp Your Review Strategy: Avoid Common Pitfalls and Boost Your Restaurant’s Reputation
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Vikhil Sallagargi"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/vikhli-sallagargi-2358051ab/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Vikhil Sallagargi</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published:  August 31, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Potential diners scrolling through Yelp, Google, or TripAdvisor, hungry to discover their next favorite spot—and your reviews are their first taste of what you offer! But here’s the catch – mismanaging them can spell disaster for your reputation and revenue. Don’t let that happen!
                                <br></br><br></br>
                                To keep your restaurant’s reputation shining, we’ve compiled the ultimate list of review management mistakes you simply must avoid. Let’s dive in and make sure every review works in your favor!
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentyone}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Ignoring Constructive Criticism
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <b style={{fontWeight:500}}>Overlooking negative reviews is a serious misstep.</b> While positive reviews boost your business, those less-than-stellar ones often hold crucial insights that can help you improve. <a href='https://blog.marqii.com/responding-to-negative-reviews/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>45% of consumers</a> are more likely to support businesses if they see that they’ve responded to negative reviews. By tackling these reviews with <b style={{fontWeight:500}}>prompt and professional responses,</b> you’re not only addressing the issue for the individual reviewer but also showing potential customers that you take their concerns seriously and are committed to providing the best experience possible.
                                <br></br><br></br>
                                <span style={{color:"#93C57C"}}>Quick Tip:</span> Even if a review feels <b style={{fontWeight:500}}>harsh or unfair, pause, take a breath, and respond with a calm, thoughtful message.</b> This demonstrates your dedication to customer satisfaction and improvement.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Handling Reviews with Emotion
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <a href='https://rapidr.io/blog/respond-to-negative-customer-feedback/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>56% of consumers</a> changed their perspective of a company based on how they respond to reviews. When a harsh review hits, it’s tempting to react defensively, but <b style={{fontWeight:500}}>emotional responses can make things worse</b> and deter future customers. Instead, focus on keeping your <b style={{fontWeight:500}}>responses professional, calm, and solution-driven.</b> This demonstrates maturity and a commitment to customer satisfaction, both of which are key in preserving your restaurant's reputation.
                                <br></br><br></br>
                                <span style={{color:"#93C57C"}}>Quick Tip:</span> Begin by acknowledging the reviewer’s concerns, and offer a constructive solution—such as inviting them back for a second chance. Turning the situation around can help rebuild trust and encourage customer loyalty.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Missing Out on Positive Reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Neglecting to actively encourage satisfied customers to leave reviews means you could be missing out on valuable positive feedback. Happy diners are often less likely to write reviews on their own, which can lead to a skewed review profile dominated by negative feedback.
                                <br></br><br></br>
                                <span style={{color:"#93C57C"}}>Quick Tip:</span> After a positive dining experience, make it a point to <b style={{fontWeight:500}}>invite customers to share their thoughts.</b> Streamline the process by sending them direct links to your review pages, making it effortless for them to leave feedback.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentytwo}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Delays in Response
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Timely replies are crucial. Taking too long to reply to reviews can signal that you don't prioritize customer feedback. Swift responses, especially to negative reviews, can help address issues quickly and demonstrate your commitment to customer satisfaction.
                                <br></br><br></br>
                                <span style={{color:"#93C57C"}}>Quick Tip:</span> Aim to respond within <b style={{fontWeight:500}}>24 to 48 hours.</b>  When a business begins responding to reviews on average review volume increases by <a href='https://www.widewail.com/blog/google-star-rating' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>12% and ratings</a> <b style={{fontWeight:500}}>increase by 0.12 stars.</b> Allocate time each day to monitor and reply to new reviews, ensuring you stay engaged and proactive.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Generic Replies
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Opting for generic, copy-pasted replies might seem efficient, but it can make your responses seem automated and unengaged. Customers often notice when responses lack personalization, which can make your restaurant appear uninterested in their unique experiences.
                                <br></br><br></br>
                                <span style={{color:"#93C57C"}}>Quick Tip:</span> <a href='https://blog.hubspot.com/service/customer-service-stats' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>65% of consumers</a> are more likely to return to a business that provides personalized responses to reviews. Customize each response by mentioning specific aspects of the customer's experience and addressing them by name. This personal touch shows that you genuinely value their feedback and enhances the overall customer experience.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                6. Neglecting Common Complaints
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <a href='https://www.helpscout.com/75-customer-service-facts-quotes-statistics/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>83% of customers</a> agree that they feel more loyal to brands that respond and resolve their complaints. If you notice recurring complaints about aspects like <b style={{fontWeight:500}}>slow service, cold food, or noisy surroundings, it’s crucial to take action.</b> Ignoring these persistent issues can negatively impact your restaurant’s reputation over time.
                                <br></br><br></br>
                                <span style={{color:"#93C57C"}}>Quick Tip:</span> Regularly review and analyze feedback to identify recurring issues. Make necessary improvements to your operations and update reviewers on the changes to show that you’re committed to resolving issues.
                                <br></br><br></br>
                                
                                
                            </Typography>
                           
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Mastering review management is essential for enhancing your restaurant's reputation and driving growth. By addressing common mistakes and adopting a thoughtful, personalized approach, you can transform feedback into valuable opportunities. Explore how <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse</a> can streamline your review management, improve your ratings, and create a standout digital presence. Take charge of your online presence with Restaverse and watch your restaurant thrive!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogeleven;