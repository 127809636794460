import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import seventeen from '../../images/blogs/17.png';
import eighteen from '../../images/blogs/18.png';
const Blognine = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            The Power of Reviews: How Ratings Can Elevate Your Restaurant's Success
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Pratik Agnihotri"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/pratik-agnihotri-5a24b5222/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Pratik Agnihotri</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 12, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Imagine unlocking a powerful marketing tool that’s right at your fingertips! While navigating the complexities of restaurant marketing and managing various digital strategies, there’s one often-overlooked tool with immense potential: customer ratings and reviews.
                                <br></br><br></br>
                                Customer ratings and reviews are more than just feedback—they can significantly impact the success of your restaurant. If you're not actively engaging with and enhancing your online reviews, you could be missing out on a major competitive advantage. Harnessing this powerful tool can revolutionize how you attract and retain customers.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={seventeen}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Why Your Restaurant Needs to Focus on Ratings and Reviews
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <Typography sx={{
                                    textAlign: 'justify',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                    marginLeft: '10px'
                                }}>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        margin: 0,

                                    }}>
                                        <li style={{ listStyleType:'disc' }}>Increasingly, consumers are turning to the internet to make their dining decisions. They are likely to spend <a href='https://www.takeout7.com/reputation-management-customer-reviews-ratings/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>31% more</a> on a business with excellent reviews.</li>
                                        <li style={{ listStyleType:'disc'  }}>Platforms like Google, Zomato, Swiggy, and Facebook draw millions of visitors daily, making them some of the <b style={{fontWeight:500}}>most impactful and affordable marketing tools</b> for your restaurant.</li>
                                        <li style={{ listStyleType:'disc'  }}><b style={{fontWeight:500}}>Positive reviews can greatly enhance your reputation,</b> while negative ones may deter potential customers.</li>
                                        <li style={{ listStyleType:'disc'  }}>The more positive ratings you gather, the more likely potential customers will <b style={{fontWeight:500}}>trust that your restaurant offers a great dining experience.</b></li>
                                        <li style={{ listStyleType:'disc'  }}>Research underscores the importance of these ratings, revealing their <b style={{fontWeight:500}}>significant impact</b> on your <b style={{fontWeight:500}}>restaurant’s bottom line.</b></li>
                                    </ul>
                                </Typography>
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                High Ratings Drive More Diners to Your Door
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Positive reviews can directly boost your restaurant’s foot traffic, particularly during peak times. A study found that a <b style={{fontWeight:500}}>mere ½-star increase in ratings</b> led to a substantial <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>30-40% boost</a> in <b style={{fontWeight:500}}>7 pm bookings.</b> This demonstrates the power of online rating platforms. While they might not always show direct financial returns on paper, they undoubtedly influence your restaurant's success by <b style={{fontWeight:500}}>attracting more guests </b>and, in turn, <b style={{fontWeight:500}}>increasing your overall revenue.</b>
                                <br></br><br></br>
                                As you gear up for your next lunch or dinner rush, consider what a <b style={{fontWeight:500}}>30-40 percent increase in bookings</b> could look like for your restaurant. Picture more tables occupied, an energized atmosphere, and a steady stream of contented diners. This kind of boost not only <b style={{fontWeight:500}}>strengthens your bottom line</b> but also elevates your restaurant’s standing in the community. Ensuring your customers leave positive reviews can be the key to turning your peak times into a bustling success.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Customer Ratings Drive Revenue Growth
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                There’s a clear link between online ratings and revenue. Research shows that a one-star increase in a restaurant's rating can boost revenue by <a href='https://www.hbs.edu/faculty/Pages/item.aspx?num=41233' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>5 to 9%.</a>
                                <br></br><br></br>
                                Additionally, <b style={{fontWeight:500}}>star ratings</b> tend to be more influential than the volume of reviews because many users feel overwhelmed by numerous comments. In essence, <b style={{fontWeight:500}}>Online review sites provide valuable insights into product quality.</b> This is especially significant for products with less known quality. Simplifying how consumers interpret review signals can make the quality information more impactful.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={eighteen}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Some Interesting Facts
                            </Typography>
                            <Typography sx={{
                                textAlign: 'justify',
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",
                                overflowWrap: "normal",
                                color: "#213343",
                                fontWeight: 300,
                                marginLeft: '10px'
                            }}>
                                <ul style={{
                                    listStyleType: 'none',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    margin: 0,

                                }}>
                                    <li style={{ listStyleType:'disc' }}>Customer reviews have a significant impact on consumer trust and behavior, with <a href='https://www.brightlocal.com/wp-content/uploads/2014/07/Local-Consumer-Review-Survey-2014.pdf' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>72% of consumers</a> more likely to trust a local business with positive reviews.</li>
                                    <li style={{ listStyleType:'disc' }}>Online restaurant reviews are the most frequently read, with <b style={{fontWeight:500}}>61% of consumers</b> actively seeking them out.</li>
                                    <li style={{ listStyleType:'disc' }}>Consumers aged <a href='https://www.brightlocal.com/research/87-of-consumers-aged-18-34-have-reviewed-a-local-business-on-social-media/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>18-34</a> are most likely to trust reviews as much as personal recommendations.</li>
                                    <li style={{ listStyleType:'disc' }}><a href='https://www.takeout7.com/reputation-management-customer-reviews-ratings/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>34% of diners</a> select restaurants based on peer review site information.</li>
                                </ul>
                                <br></br>
                                Improving your ratings is essential for growing your customer base, increasing revenue, and cutting costs, and this trend is likely to persist.
                                <ul style={{
                                    listStyleType: 'none',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    margin: 0,

                                }}>
                                    <li style={{ listStyleType:'disc' }}>While occasional negative reviews are inevitable,<b style={{fontWeight:500}}>handling them effectively can positively impact your business.</b></li>
                                    <li style={{ listStyleType:'disc' }}>By focusing on <b style={{fontWeight:500}}>delivering top-notch service, exceptional food, and a great atmosphere,</b> you pave the way for positive reviews.</li>
                                    <li style={{ listStyleType:'disc' }}>As a result, your restaurant’s <b style={{fontWeight:500}}>rating pages can become a significant revenue driver and a powerful marketing asset.</b></li>
                                    <li style={{ listStyleType:'disc' }}><b style={{fontWeight:500}}>Focus on delivering the best experience,</b> and let the resulting positive ratings drive your restaurant’s success and growth.</li>
                                </ul>
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Ready to elevate your restaurant’s ratings?  Come and join <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse!</a> <br></br>
                                Our solutions are designed to improve your online ratings and drive growth. See how Restaverse can help you attract more diners and boost your revenue. Contact us now!

                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blognine;