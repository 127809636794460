import React from "react";
import "./casestudy.css";
import Infotags from "../../Components/Infotags/infotags";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ChallengeSection from "../../Components/Challenge/challenegesection";
import ImpactSection from "../../Components/Challenge/impactsection";
import SolutionSection from "../../Components/Challenge/solutionsection";
import gomaowner from "../../images/CaseStudy/goma/Goma.webp";
import gomaone from "../../images/CaseStudy/goma/Goma 1.webp";
import gomatwo from "../../images/CaseStudy/goma/Goma 2.webp";
import gomahome from '../../images/CaseStudy/goma/Goma Home.webp';

const Goma = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });



  useEffect(() => {
    document.title = "Case Study | Restaverse";
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);


  const infoAboutCompany = {
    Brand: `Goma - Asian Experience`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Asian",
    Location: "Mumbai ",
    AOV: "High",
    "Target Audience": "Premium",
  };

  const challenge = {
    text1: "Lack Of Online Delivery",
    text2: `Goma - Asian Experience, a distinguished restaurant known for its authentic Asian cuisine with their sole focus on dine-in services. The lack of a delivery option restricted access for potential customers who prefer to dine at home, thus limiting market reach and revenue growth opportunities in a competitive culinary landscape.
`,
  };

  const impact = {
    impactCards: {
      card1: {
        header: "Revenue",
        figures: 400,
        figurePrefix: "",
        figuresPostfix: "%",
        content: "Increase in overall Revenue",
      },
      card2: {
        header: "Conversion",
        figures: 364,
        figuresPostfix: "%",
        content: "Increase in overall Menu Opens",
      },
      card3: {
        header: "Orders Conversion",
        figures: 302,
        figuresPostfix: "%",
        content: "Increase in overall Orders",
      },
    },
    owner_views: {
      title: "Goma - Asian Experience",
      description: `Restaverse has been instrumental in Goma's journey to greater accessibility and growth. Their expertise allowed us to seamlessly transition from a dine-in focus to offering exceptional delivery services. Thanks to Restaverse, Goma has expanded its reach and solidified its reputation as a go-to destination for authentic Asian cuisine, both in-person and delivered to homes across our community.`,
      name: "Santosh Singh",
      designation: "GM at Radisson Hotel Group",
      owner_img: gomaowner
    },
  };


  const solutions = {
    1: {
      header: " Exclusive Online Menu",
      content: `We began by meticulously analysing Goma's existing dine-in menu, optimising it specifically for delivery service. This process involved strategically adjusting menu items to ensure they traveled well and maintained their quality during transit. We also conducted a thorough review of competitors' pricing strategies to position Goma competitively in the delivery market.`,
      src: gomaone,
    },
    2: {
      header: "Streamlined Ordering System",
      content: "Understanding the evolving trends and preferences of our target audience was crucial. By aligning our menu offerings with these insights, we were able to cater more effectively to customer expectations, thereby increasing delivery sales. This expansion not only improved revenue from delivery orders but also complemented the existing dine-in business, reinforcing Goma's position as a preferred destination for authentic Asian cuisine.",
      src: gomatwo,
    },

  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
          <div
            className="casestudy"
            style={{ fontFamily: "Poppins , sans-serif" }}
          >
            <div className="main-section">
              {/* left-main-section */}
              <div className="left-main-section">
                <div className="left-section-desc">
                Goma - Asian Experience: Revenue growth via introducing online delivery
                </div>
                <div className="info-tags">
                  {Object.keys(infoAboutCompany).map((key) => (
                    <Infotags
                      key={key}
                      header={key}
                      value={infoAboutCompany[key]}
                    />
                  ))}
                </div>
              </div>

              {/* image */}
              <img src={gomahome} className="image-main-section"></img>
            </div>

            {/* Challenge Solution Impact */}
            <div className="sections">
              <nav className="section-navbar">
                <a href="#challenge">Challenge</a>
                <a href="#solution">Solution</a>
                <a href="#impact">Impact</a>
              </nav>

              <section id="challenge">
                <ChallengeSection
                  text1={challenge.text1}
                  text2={challenge.text2}
                />
                {/* <Challenge/> */}
              </section>

              <section id="solution">
                <SolutionSection solutions={solutions} />
              </section>

              <section id="impact">
                {/* <Impact /> */}
                <ImpactSection impact={impact} />
              </section>

              <div className="cardcase" ref={ref}>
                {inView && (
                  <div className="cardcasestudyHeader animate-from-top">
                    Success Story
                  </div>
                )}

                <CaseStudyCarousel />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Be ahead of the curve"
          desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
          buttontext="Join Waitlist"
          redirect="/contact-us"
        />
      </div>
    </>
  );
};

export default Goma;
