import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import thirtyseven from '../../images/blogs/37.png';
import thirtyeight from '../../images/blogs/38.png';
const Blognineteen = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Mastering the Art of Strategic Discounts: Transforming Customers into Loyal Patrons While Maximizing Profitability
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Yash Bokadia"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/yashbokadia/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Yash Bokadia</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 16, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Imagine creating offers that not only bring in new customers but also turn them into loyal patrons, all while keeping your profit margins intact. It’s not about slashing prices; it’s about driving growth, building buzz, and ensuring your restaurant stays on the cutting edge.
                                Ready to see your restaurant transform? Let’s dive into a new era of smart, strategic discounts!
                                <br></br><br></br>
                                Here's how to craft an effective discount plan that works for your restaurant.
                            </Typography>
                            <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20px',
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={thirtyseven}
                                            style={{
                                                width: '100%',
                                                maxWidth: '550px',
                                                height: 'auto',
                                            }}
                                            alt="Description of the image"
                                        />
                                    </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <ol>
                                    <li>
                                        <p><b style={{fontWeight:500}}>Understand Your Margins</b> <br></br>
                                            Before rolling out any discounts, it’s imperative to have a firm grasp of your profit margins. This means having a comprehensive understanding of the costs associated with each dish, including ingredients, labor, and overhead. Without this insight, you may unintentionally offer discounts that significantly erode your profits. By analyzing your margins, you can create targeted discounts that entice customers while protecting your financial stability, ensuring that every offer you make supports your restaurant’s profitability.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Identify Your Ideal Customers</b><br></br>
                                            Recognizing that not all customers are influenced by discounts is key to maximizing their effectiveness. Start by segmenting your customer base according to their preferences and dining habits. Are you looking to attract weekday diners, families, or your loyal regulars? Tailor your discount offers to resonate with each group.
                                            <br></br><br></br>
                                            For example, you might introduce a weekday family meal special or provide exclusive loyalty discounts to reward frequent visitors. <b style={{fontWeight:500}}>The Family Meal Study found that 79% of families eat out at least once a week, making family-targeted promotions highly effective, especially on weekdays.</b> By tailoring your promotions, you can forge stronger connections with your audience and enhance customer engagement.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Purpose-Driven Discounts</b>
                                            Discounts should be intentional, serving specific goals that benefit your restaurant. Here are a few effective strategies:
                                            <br></br><br></br>
                                            <ul>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Fill Slow Hours:</b> Use discounts to attract customers during quiet times. For example, offer special pricing on appetizers or drinks during weekday lunches or late-night slots.</p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Promote New Dishes:</b> Introduce new items with limited-time discounts to generate buzz and encourage diners to try something fresh.
                                                    </p>
                                                </li>
                                                <li style={{ listStyleType: 'disc' }}>
                                                    <p><b style={{fontWeight:500}}>Create Value Bundles:</b> Offer combo deals that pair popular dishes at a slight discount, increasing average order value while enhancing the dining experience.                                                    </p>
                                                </li>
                                            </ul>
                                            By implementing purpose-driven discounts, you can effectively engage customers and achieve your business goals.

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Leverage Technology for Personalized Offers</b>
                                            <br></br>
                                            With data-driven insights and restaurant management platforms, you can tailor discounts to individual customers. Personalized promotions—like offering a discount on a customer's favorite dish or a birthday coupon—create a more engaging experience and increase the likelihood of repeat business. <b style={{fontWeight:500}}>Research from McKinsey & Company indicates that targeted promotions can drive 10-30% higher conversion rates than generic offers.</b> AI-driven tools can help analyze customer behavior and send targeted discounts that resonate with each individual, ensuring higher conversion rates and boosting profits.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Time-Based Discounts to Increase Traffic</b>
                                            <br></br>
                                            <b style={{fontWeight:500}}> Research from the University of Chicago found that timing-based discounts can increase traffic by up to 15% during off-peak hours, effectively smoothing out customer flow and kitchen workload.</b> Time-based promotions are a fantastic way to enhance customer visits during slower periods.
                                            <br></br><br></br>
                                            For instance, if you notice a dip in traffic between lunch and dinner, consider launching a “mid-afternoon snack” discount. This strategy not only attracts customers during quieter times but also helps to balance the flow of patrons, reducing pressure on your kitchen staff during peak hours. By strategically timing your discounts, you create an appealing offer that benefits both your restaurant and your guests!
                                        </p>
                                    </li>
                                    <li>
                                        <p><b style={{fontWeight:500}}>Promote Your Discounts Effectively</b>
                                            <br></br>
                                            Once you have a strategy in place, you’ll need to get the word out. Use your restaurant’s social media channels, email newsletters, and partnerships with delivery platforms to promote your offers. <b style={{fontWeight:500}}>According to Sprout Social, 57% of consumers are more likely to make a purchase if they see it on social media. Effective promotion of your discounts can drive significant traffic.</b> Engaging visuals and compelling messaging can help make your discounts irresistible, bringing in more customers and boosting sales.
                                            <br></br><br></br>
                                            Over 70% of consumers are more likely to recommend a brand with a good customer loyalty program. Create a user-friendly loyalty system, such as a points program or discounts after a set number of visits. This makes it easy for customers to engage and feel recognized for their continued support.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Measure and Adjust</b>
                                            <br></br>
                                            Finally, the key to a successful discount strategy is constantly measuring its effectiveness. <b style={{fontWeight:500}}>A survey by HubSpot revealed that 40% of marketers say measuring the effectiveness of discounts is crucial for success. Regularly analyzing data can inform necessary adjustments to your strategy.</b> Track metrics such as foot traffic, average order value, and customer satisfaction during your discount periods. If a particular discount is not driving the expected results, don’t hesitate to tweak it. Success lies in flexibility and adaptation.
                                        </p>
                                    </li>


                                </ol>

                            </Typography>
                            <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20px',
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={thirtyeight}
                                            style={{
                                                width: '100%',
                                                maxWidth: '550px',
                                                height: 'auto',
                                            }}
                                            alt="Description of the image"
                                        />
                                    </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Final Thoughts
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                A successful discount strategy goes beyond slashing prices; it’s about timing and targeting promotions to ensure long-term success. By leveraging the right tools and understanding your customers, you can boost traffic and profits effectively.
                                <br></br><br></br>
                                With <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse's</a> insights and AI-driven solutions, you can implement these strategies with ease. Seize control of your future and start boosting your sales today!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blognineteen;