import React from "react";
import "./casestudy.css";
import Challenge from "../../Components/Challenge/challenge2";
import Infotags from "../../Components/Infotags/infotags";
import Solution from "../../Components/Challenge/solution2";
import Impact from "../../Components/Challenge/impact2";
import CaseStudyCarousel from '../../Components/casestudycarousel/cscarousel';
import SuceessStory from "../../Components/SuccessStory/successstory";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ufcimage from "../../images/CaseStudy/ufcimages/UFCHome.webp";

const UFCCasestudy = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    document.title = 'Case Study | Restaverse';
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  

  const infoAboutCompany = {
    Brand: `United Farmers Creamery(UFC)`,
    Platform: "Zomato & Swiggy",
    Cuisine: "Ice Cream",
    Location: "11+ Cities",
    AOV: "Low",
    "Target Audience": "Mass",
  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f5ff" }}>
        <div
          // className="casestudy "

          className="container-lg"
        >
      <div className="casestudy" style={{ fontFamily: "Poppins , sans-serif" }}>
        <div className="main-section">
          {/* left-main-section */}
          <div className="left-main-section">
            <div className="left-section-desc">
              UFC: Revenue growth via better search engine optimisation
            </div>
            <div className="info-tags">
              {Object.keys(infoAboutCompany).map((key) => (
                <Infotags
                  key={key}
                  header={key}
                  value={infoAboutCompany[key]}
                />
              ))}
            </div>
          </div>

          {/* image */}
          <img src={ufcimage} className="image-main-section"></img>
        </div>

        {/* Challenge Solution Impact */}
        <div className="sections">
          <nav className="section-navbar">
            <a href="#challenge">Challenge</a>
            <a href="#solution">Solution</a>
            <a href="#impact">Impact</a>
          </nav>

          <section id="challenge">
            <Challenge />
          </section>

          <section id="solution">
            <Solution />
          </section>

          <section id="impact">
            <Impact />
          </section>

          <div className="cardcase" ref={ref}>
            {inView && (
              <div className="cardcasestudyHeader animate-from-top">
                Success Story
              </div>
            )}

           <CaseStudyCarousel />
          </div>
        </div>
      </div>
      </div>
      </div>
      <div className="container-lg">
      <SuceessStory
        titletext="Be ahead of the curve"
        desctext="Join our waitlist to pioneer the future of hospitality with Restaverse."
        buttontext="Join Waitlist"
        redirect="/contact-us"
      />
    </div>
    </>
  );
};

export default UFCCasestudy;
