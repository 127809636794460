import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png'; 
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import thirteen from '../../images/blogs/13.png';
import fourteen from '../../images/blogs/14.png';
const Blogseven = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14}, 
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1', 
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Ignite Your Restaurant’s Success: <br></br> Strategies for Rave Reviews and Repeat Business
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Yash Bokadia"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/yashbokadia/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Yash Bokadia</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Oct 13, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In the competitive world of the restaurant industry, sparking rave reviews and driving repeat business is the key to unlocking lasting success. Picture your restaurant filled with happy diners who can't wait to share their experiences online and return for more.
                                The <b style={{fontWeight:500}}>secret lies in creating an unforgettable experience that not only delights the senses but also builds lasting relationships.</b> By diving into these insights, you'll discover creative tactics to generate enthusiastic reviews and encourage repeat business, ensuring your restaurant shines in a competitive market.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={thirteen}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Building an Effective Online Reputation
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                To attract and engage potential diners, your restaurant’s online presence needs to be both accurate and compelling. Outdated listings can make it difficult for potential guests to find you and may deter them from leaving reviews. Ensure that your restaurant’s website, social media profiles, and review sites are consistently updated with current details like contact information, menu offerings, location, and visuals.  A <b style={{fontWeight:500}}>well-maintained online profile</b> not only enhances your restaurant's visibility but also leaves a positive impression, encouraging more reviews and repeat visits.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Invite Your Loyal Patrons to Share Their Experiences
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                A loyal customer base is a restaurant's greatest asset when it comes to generating positive reviews. These regular patrons, who have already formed a connection with your restaurant, are well-positioned to share their positive experiences. To encourage reviews, reach out to them soon after their visit while their experience is still fresh. Encourage reviews by using social media, text, and email reminders. Make it easy by adding review links on your website and using table cards or stickers to direct customers to review sites. These tactics can help convert satisfied diners into enthusiastic reviewers and enhance your restaurant’s online reputation.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Respond to the reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Responding to customer reviews is crucial for showing that you value their feedback and care about your brand’s reputation. Whether the feedback is positive or negative, <b style={{fontWeight:500}}>timely and thoughtful responses</b> can significantly impact your brand’s image. Thanking satisfied customers for their positive reviews can enhance their loyalty and encourage repeat visits. For negative reviews, <b style={{fontWeight:500}}>address grievances promptly</b> to demonstrate your commitment to customer satisfaction. <b style={{fontWeight:500}}>Keep a close watch on review sites</b> such as Tripadvisor, Zomato,Swiggy, and google, and make sure to acknowledge and address reviews. Additionally, <b style={{fontWeight:500}}>track social media comments</b> about your restaurant to stay engaged with customer experiences and maintain a positive online presence.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                    marginBottom: '10px'
                                }}
                            >
                                <img src={signup} style={{
                                    width: '100%',
                                    maxWidth: '550px',
                                    height: 'auto',
                                }} alt="Description of the image" />
                                <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Unlock the power of your restaurant’s reviews with Restaverse to boost your reputation, delight your customers, and drive revenue growth. Sign up now to turn feedback into your greatest asset!

                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Highlight Positive Reviews
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Offering incentives can be a strategic way to encourage reviews, but it must be handled carefully. Address negative feedback by <b style={{fontWeight:500}}>offering a complimentary meal or discount,</b> which helps resolve issues and retain customer loyalty. For positive reviews, reward customers with <b style={{fontWeight:500}}>coupons or meal vouchers.</b> This approach not only encourages more reviews but also promotes repeat business. By using incentives wisely, you can improve customer satisfaction and strengthen your restaurant's online reputation.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={fourteen}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                5. Showcase Customer Reviews on Social Media
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Customers feel valued when their feedback is recognized and shared. Amplify your <b style={{fontWeight:500}}>restaurant's credibility by sharing positive reviews</b> on your social media channels. <b style={{fontWeight:500}}>Repost user-generated content, including reviews and photos,</b> with credit to encourage more customers to share their experiences. Showcase <b style={{fontWeight:500}}>exceptional reviews and testimonials on your website</b> using engaging visuals or screenshots. When customers post impressive photos of your food or atmosphere, share these on your social media and tag them. Responding to reviews with gratitude not only fosters a positive image but also encourages continued customer engagement.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                CONCLUSION
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                In the competitive restaurant industry, creating a standout experience involves more than just exceptional food. Energize your restaurant by keeping your online presence dynamic, engaging passionately with loyal patrons, and responding to feedback with flair. Dive into these strategies and watch your restaurant transform into a vibrant hotspot, brimming with rave reviews and a fervent, loyal fan base eager for their next visit.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogseven;