import React from "react";
import "./challenge.css";
import AnimatedNumber from "../Team/animatednumber";
import persianowner from "../../images/CaseStudy/persianimages/persianowner.webp";

const Impact = () => {
  const impactCards = {
    card1: {
      header: "Revenue",
      figures: 75,
      figuresPostfix: "%",
      content: "growth in Appetizer Revenue Share",
    },
    card2: {
      header: "Conversion",
      figures: 34,
      figuresPostfix: "%",
      content: "increase in Menu-to-Order conversions",
    },
    card3: {
      header: "Ads Conversion",
      figures: 57,
      figuresPostfix: "%",
      content: "increase in Ads Menu-to-Order conversions",
    },
  };

  return (
    <div className="challenge">
      <div>
        <h3> Impact</h3>
        <hr></hr>
      </div>
      <div className="impact-cards">
        {Object.keys(impactCards).map((key) => {
          const card = impactCards[key];
          return (
            <div className="impact-single-card">
              <div className="impact-card-header">{card.header}</div>
              <div className="impact-card-figure">
                {" "}
                <AnimatedNumber
                  end={card.figures}
                  duration={3}
                  unit={card.figuresPostfix}
                />{" "}
              </div>
              <div className="impact-card-content">{card.content}</div>
            </div>
          );
        })}
      </div>

      <div className="impact-section">
        <div className="impact-left-section">
          <h1>Persian Darbar</h1>
          <p>
            The Restaverse team has been instrumental in transforming our
            business. Their innovative strategies elevated our appetizers'
            visibility, leading to increased sales on aggregator platforms. In a
            span of one year engagement, we've doubled our online revenues on a
            strong 8 digit monthly revenue and now our stores rank in top
            revenue generating outlets in the cities we're present in.
          </p>
          {/*
          <p>
            Generative AI allows us to offer personalized travel recommendations
            on our digital channels with human-like interactions while analyzing
            user preferences and historical data to suggest destinations,
            accommodations, and activities tailored to individual travelers’
            tastes, enhancing the overall travel experience.”
          </p> */}

          <div className="writer-name">
            <h3>Mr. Talha Upletawala</h3>
            <span>Co-Founder, Persian Darbar</span>
          </div>
        </div>

        <img src={persianowner} className="impact-img"></img>
      </div>
    </div>
  );
};

export default Impact;
