import "./pagetitle.css";

const PageTitle = ({title}) => {
  return (
    <section className="section-1" style={{fontFamily:'Poppins, sans-serif'}}>
      <h1>{title}</h1>
      <div className="bar" style={{backgroundColor:'6C41FF'}}></div>
    </section>
  );
};

export default PageTitle;