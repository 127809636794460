import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import thirtyfive from '../../images/blogs/35.png';
import thirtysix from '../../images/blogs/36.png';
const Blogeighteen = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            6 Tactics Every Restaurant Should Implement Today to Boost Sales
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Amar Savla"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/amarr-prabodh-savla-5a533060/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Amar Savla</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: June 22, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Ready to take your restaurant’s sales to the next level and become the talk of the town? It’s time to go beyond serving great food and create an unforgettable dining experience that keeps customers coming back for more! With the right strategies and a touch of creativity, you’ll draw in new guests, keep your loyal regulars, and watch your business flourish.  Let’s dive into six dynamic tactics guaranteed to fuel your restaurant’s growth and success!
                            </Typography>
                            <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20px',
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={thirtyfive}
                                            style={{
                                                width: '100%',
                                                maxWidth: '550px',
                                                height: 'auto',
                                            }}
                                            alt="Description of the image"
                                        />
                                    </Box>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <ol>
                                    <li>
                                        <p><b style={{fontWeight:500}}>Optimize Your Menu for Profitability </b><br></br>
                                            Your menu is a powerful tool to increase sales. <b style={{fontWeight:500}}>Analyze</b> which <b style={{fontWeight:500}}>items have the highest profit margins and highlight them on the menu.</b> This subtle nudge can encourage customers to order items that are more profitable for your business. According to a report, menu optimization has the potential to increase profit by <a href='https://www.elluminatiinc.com/restaurant-menu-optimization/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>10-12%.</a>
                                            <br></br><br></br>
                                            Keeping things fresh encourages repeat customers and sparks interest in new visitors. <b style={{fontWeight:500}}>Consider a seasonal refresh</b> or an annual overhaul to keep customers excited. Consider adding photos or brief descriptions to spotlight your high-profit items, and experiment with pricing to <b style={{fontWeight:500}}>increase sales volume.</b>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Maximize Your Restaurant’s Social Media Presence</b> <br></br>
                                            About <a href='https://gitnux.org/restaurant-social-media-statistics/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>74% of consumers</a> rely on social media to guide their dining choices, indicating that a strong social media presence is crucial for attracting customers​. Regularly engage your audience by highlighting your standout dishes, sharing glowing customer reviews, and offering a behind-the-scenes peek at your team and kitchen.
                                            <br></br><br></br>
                                            Strengthen your online presence by creating a sense of community through <b style={{fontWeight:500}}>interactive posts, live videos, and engaging stories.</b> <a href='https://gitnux.org/restaurant-social-media-statistics/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>86% of diners</a> will post about a meal if it is visually appealing, and <a href='https://www.linkedin.com/in/urmi-dangi-5a549b255/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>40%</a> have tried a new restaurant after seeing enticing food photos online. Encourage immediate action with <b style={{fontWeight:500}}>exclusive offers, such as discount codes or limited-time deals,</b> available only to your social media followers.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Ignite Interest with Unique Events and Promotions </b><br></br>
                                            Generating excitement around your restaurant through special events can lead to a significant sales boost. From lively live music nights and themed dining experiences to enticing happy hour specials, events provide customers with exciting reasons to choose your restaurant.
                                            <br></br><br></br>
                                            Additionally, consider running promotions like <b style={{fontWeight:500}}>“Buy One, Get One Free” or exclusive discounts</b> for group bookings to fill your tables during slower times. <a href='https://famesters.com/blog/top-restaurant-influencers-to-partner-with/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>49% of consumers</a> depend on influencer recommendations for their dining decisions. Team up with local influencers or event organizers to expand your reach and boost visibility for your events. Their support can help attract new patrons and create a buzz that keeps your restaurant top-of-mind in the community.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Elevate Your Sales with Smart Upselling</b> <br></br>
                                            Upselling is a powerful technique for enhancing sales and can increase profits by <a href='https://www.chiefoutsiders.com/blog/boost-sales-cross-sell' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>20-30%.</a> By guiding customers to <b style={{fontWeight:500}}>upgrade their orders or sample additional dishes,</b> you can drive significant revenue growth with minimal effort. However, effective upselling requires a <b style={{fontWeight:500}}>well-informed and trained staff.</b>
                                            <br></br><br></br>
                                            Equip your team with a thorough understanding of the menu so they can offer tailored recommendations and build authentic connections with customers. When done skillfully, upselling feels less like a sales pitch and more like a valuable suggestion, inviting diners to discover new favorites and indulge in premium options.
                                        </p>
                                    </li>

                                    <li>
                                        <p>
                                        <b style={{fontWeight:500}}>Innovate Your Packaging for Lasting Impact</b> <br></br>
                                            Eye-catching packaging can build anticipation before they even taste your food. Brands like McDonald’s and Subway use custom packaging to <b style={{fontWeight:500}}>enhance their identity and create memorable impressions.</b> Research shows that <a href='https://disk.com/resources/packaging-customer-unboxing-experience/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>72% of consumers</a> say packaging design influences their buying choices. You don’t need a large budget to achieve this effect. <b style={{fontWeight:500}}>Simple, creative elements—</b>such as branded stickers, custom-designed boxes, or sustainable materials—can significantly elevate your packaging and delight your customers.
                                            <br></br><br></br>
                                            Thoughtfully designed packaging not only makes your food look appealing but also fosters a sense of professionalism that customers will remember and talk about long after their meal.
                                        </p>
                                    </li>
                                    <li>
                                        <p><b style={{fontWeight:500}}>Offer Loyalty Programs</b> <br></br>
                                            Prioritizing the happiness of your regular customers is essential for sustained success. One of the most effective ways to <b style={{fontWeight:500}}>encourage repeat visits is by introducing a loyalty program.</b> Customers appreciate rewards for their patronage, and such programs can enhance retention and increase average order values.
                                            <br></br><br></br>
                                            Over <a href='https://www.coniq.com/resources/customer-loyalty-program-statistics-trends/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>70% of consumers</a> are more likely to recommend a brand with a good customer loyalty program. Create a user-friendly loyalty system, such as a points program or discounts after a set number of visits. This makes it easy for customers to engage and feel recognized for their continued support.
                                        </p>
                                    </li>
                                    

                                </ol>

                            </Typography>
                            <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20px',
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={thirtysix}
                                            style={{
                                                width: '100%',
                                                maxWidth: '550px',
                                                height: 'auto',
                                            }}
                                            alt="Description of the image"
                                        />
                                    </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                             CONCLUSION
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Revving up your restaurant sales is all about combining smart strategies with unforgettable customer experiences! Harness online platforms, boost your social media presence, reward loyal customers, optimize your menu, and host exciting events to unlock incredible growth.
                                <br></br><br></br>
                                Take the leap to extraordinary success with <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse!</a> Discover innovative tools to enhance your dining experience, captivate customers, and drive sales. Start implementing these tactics today, and watch your sales soar! Your path to success begins now—let’s embark on this exciting journey together!
                            </Typography>

                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogeighteen;