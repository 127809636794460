import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, Divider, Stack } from '@mui/material';
import nine from '../../images/blogs/9.png';
import eleven from '../../images/blogs/11.png';
import thirteen from '../../images/blogs/13.png';
import fifteen from '../../images/blogs/15.png';
import seventeen from '../../images/blogs/17.png';
import nineteen from '../../images/blogs/19.png';



const articles = [
  {
    title: "Turning Negative Reviews into Positive Change: A Restaurant’s Guide...",
    author: "Rishon Dharia",
    date: "06/10/2024",
    imageUrl: nine,
    link: "/blogs/turning-negative-reviews-into-positive-change-a-restaurants-guide-to-reputation-management"
  },
  {
    title: "Maximize Your Success: The Ultimate Guide to Mastering Customer Review Management",
    author: "Amar Savla",
    date: "26/06/2024",
    imageUrl: eleven,
    link: "/blogs/maximize-your-success-the-ultimate-guide-to-mastering-customer-review-management"
  },
  {
    title: "Ignite Your Restaurant’s Success: Strategies for Rave Reviews and Repeat Business",
    author: "Yash Bokadia",
    date: "13/10/2024",
    imageUrl: thirteen,
    link: "/blogs/ignite-your-restaurants-success-strategies-for-rave-reviews-and-repeat-business"
  },
  {
    title: "How to Respond to Restaurant Reviews to Build Trust and Loyalty",
    author: "Valeron Toscano",
    date: "13/07/2024",
    imageUrl: fifteen,
    link: "/blogs/how-to-respond-to-restaurant-reviews-to-build-trust-and-loyalty"
  },
  {
    title: "The Power of Reviews: How Ratings Can Elevate Your Restaurant's Success",
    author: "Pratik Agnihotri",
    date: "12/05/2024",
    imageUrl: seventeen,
    link: "/blogs/the-power-of-reviews-how-ratings-can-elevate-your-restaurants-success"
  },
  {
    title: "Revamp Your Review Strategy: Avoid Common Pitfalls and...",
    author: "Vikhil Sallagargi",
    date: "31/08/2024",
    imageUrl: nineteen,
    link: "/blogs/revamp-your-review-strategy-avoid-common-pitfalls-and-boost-your-restaurants-reputation"
  },
];

const LatestArticles = () => {
  return (
    <Box sx={{ marginTop: "50px", marginBottom: "50px" }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ marginBottom: '20px' }}>
        <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Lexend Deca, sans-serif',  fontSize: {sm:'24px', xs:'16px'}, fontWeight: 500 }}>
          Latest articles
        </Typography>
        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: 'rgb(108, 65, 255)' }} />
      </Stack>
      <Grid container columnSpacing={15}>
        {articles.map((article, index) => (
          <Grid item xs={12} md={6} key={index}>
            <a href={article.link} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Card sx={{
              display: 'flex',
              height: '100%',
              backgroundColor: 'rgb(247, 245, 255)',
              boxShadow: 'none',
              width: '100%',
              maxWidth: '800px',
              '&:hover': {
                '& .article-title, & .date-wrapper': {
                  textDecoration: 'underline',
                  textDecorationColor: 'black',
                },
              },
            }}

            >
              <CardMedia
                component="img"
                sx={{
                  height: { xs: 118, sm: 100 },
                  width: { xs: 105, sm: 160 },
                }}
                image={article.imageUrl}
                alt=""
              />
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: { sm: '-20px', xs: '-17px' },

                }}>
                <Typography variant="subtitle1" noWrap
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                    width: 320,
                    maxHeight: '4.5em',
                    lineClamp: 3,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#213343',
                    fontFamily: 'Lexend Deca, sans-serif',
                    fontSize: "14px"
                  }}
                  className="article-title"
                >
                  {article.title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 2,
                    marginBottom: 1
                  }}
                >
                  <Typography variant="subtitle2" color="text.secondary" sx={{ flex: '1 1 auto' }}>
                    {article.author}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" sx={{ flexShrink: 0 }}>
                    {article.date}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, height: '2px', backgroundColor: 'rgb(211,211,211)', marginTop: { sm: "10px", xs: "21px" } }} />
              </CardContent>
            </Card>
            </a>

          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LatestArticles;
