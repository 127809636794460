import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import twentythree from '../../images/blogs/23.png';
import twentyfour from '../../images/blogs/24.png';
const Blogtweleve = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: { sm: 65, xs: 56 },
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: { sm: 8, xs: 14 },
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: { sm: 2, xs: 1 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize: { xs: '35px', sm: '47px' }
                            }}
                        >
                            The Power of Data-Driven Insights in Analyzing Customer Reviews
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                  
                                alt="Urmi Dangi"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px', }}
                            />
                            <Box sx={{ display: 'flex', alignItems: { sm: 'center', xs: 'left' }, flexDirection: { sm: 'row', xs: 'column' }, gap: { sm: 87, xs: 2 } }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/urmi-dangi-5a549b255/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Urmi dangi</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Sep 17, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: { sm: 2, xs: 0 },
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: { sm: 2, xs: 0 },
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Are you aware that <a href='https://www.forbes.com/sites/shephyken/2022/04/24/fifty-eight-percent-of-customers-will-pay-more-for-better-customer-service/?sh=84f2ab013f15' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>58% of customers</a> are willing to pay a premium for an improved customer experience? This fact emphasizes the pivotal role of understanding and addressing customer reviews in driving business success.
                                <br></br><br></br>
                                However, simply collecting reviews is not enough. To truly benefit, businesses need to dig deeper and uncover actionable insights from the wealth of information they receive. This is where customer review analysis becomes essential.
                                <br></br><br></br>
                                Customer reviews have become one of the most influential factors in shaping a restaurant’s reputation. The challenge for restaurateurs isn’t just collecting these reviews but effectively analyzing them to unlock meaningful insights. This is where data-driven insights play a crucial role.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Why Customer Reviews Matter More Than Ever
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <Typography sx={{
                                    textAlign: 'justify',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                    marginLeft: '10px'
                                }}>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        margin: 0,

                                    }}>
                                        <li style={{ listStyleType: 'disc' }}>With the growing prominence of platforms like <b style={{ fontWeight: 500 }}>Google, Yelp, and aggregator apps, customer reviews have become more visible and influential than ever.</b> </li>
                                        <li style={{ listStyleType: 'disc' }}>Reviews play a <b style={{ fontWeight: 500 }}>significant role in shaping diner decisions,</b> making it vital for restaurant owners to stay informed about what’s being said.</li>
                                        <li style={{ listStyleType: 'disc' }}><b style={{ fontWeight: 500 }}>Positive reviews</b> can enhance your reputation and attract new customers, while <b style={{ fontWeight: 500 }}>negative reviews </b>can quickly turn people away.</li>
                                        Sifting through hundreds or thousands of reviews manually is unrealistic for most restaurant managers. That’s where <b style={{ fontWeight: 500 }}>data-driven analysis steps in,</b> turning large amounts of review data into <b style={{ fontWeight: 500 }}>actionable insights that can drive better business decisions.</b>
                                    </ul>
                                </Typography>
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentythree}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                How Data-Driven Insights Elevate Review Analysis
                            </Typography>

                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                1. Identifying Trends and Patterns
                            </Typography>


                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                Data analysis tools allow restaurant owners to aggregate reviews across various platforms, revealing key trends and patterns that may not be immediately apparent. By analyzing recurring themes, these tools provide valuable insights into both operational challenges and opportunities for enhancement. This process transforms vast amounts of review data into actionable insights, enabling restaurants to enhance their operations and better align with customer preferences.

                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                2. Understanding Sentiment in Real Time

                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Sentiment analysis tools evaluate customer reviews and classify them into emotional categories—positive, neutral, or negative. <b style={{ fontWeight: 500 }}>79% of companies using real-time sentiment analysis tools see a 15% increase in customer satisfaction (Source: business Insider).</b>This immediate feedback allows restaurants to track how customers are reacting to their experience. By monitoring sentiment trends, restaurants can respond to potential problems before they escalate, ensuring a more proactive approach to maintaining a positive reputation.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={twentyfour}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                3. Measuring Performance Over Time
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Consistency is crucial for restaurant success, and data-driven insights provide a way to monitor customer satisfaction trends over time. <b style={{ fontWeight: 500 }}>Organizations monitoring customer satisfaction trends over time experience a 25% higher retention rate and a 10-15% increase in customer loyalty(Source: Gartner).</b> By evaluating the impact of changes—such as new menu items or shifts in management—you can assess their effect on your reputation. This ongoing analysis helps you refine your strategies and ensure a consistently high level of quality and service.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                4. Uncovering Location-Specific Insights
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                For restaurants with several locations, data analysis provides a way to assess the performance of each branch individually. This insight helps identify which locations are thriving and which are struggling. <b style={{ fontWeight: 500 }}>Multi-location restaurants that analyze performance by location see a 20% improvement in overall operational efficiency and a 15% increase in customer satisfaction scores at underperforming sites (Source: Restaurant Business Online).</b> By analyzing these location-specific insights, you can address issues more precisely and implement tailored strategies to enhance performance at each site.
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    marginTop: "30px",
                                    lineHeight: "1.77777778",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "24px"
                                }}
                            >
                                Turning Insights Into Action
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                The true value of data-driven insights is realized when they are applied to drive improvements in your business.
                                <br></br>
                                <Typography sx={{
                                    textAlign: 'justify',
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,
                                    marginLeft: '10px'
                                }}>
                                    <ul style={{
                                        listStyleType: 'none',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        margin: 0,

                                    }}>
                                        <li style={{ listStyleType: 'disc' }}>For instance, if <b style={{ fontWeight: 500 }}>data shows that customers frequently mention long wait times,</b> you can <b style={{ fontWeight: 500 }}>adjust staffing or reservation procedures.</b></li>
                                        <li style={{ listStyleType: 'disc' }}>Conversely, if <b style={{ fontWeight: 500 }}>customers frequently praise your restaurant's ambiance,</b> you can <b style={{ fontWeight: 500 }}>highlight this in your marketing strategies.</b></li>
                                    </ul>
                                </Typography>
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                <br></br><br></br>
                                By using these insights effectively, you can <b style={{ fontWeight: 500 }}>enhance the customer experience and drive business success.</b>
                                <br></br><br></br>
                                As artificial intelligence and machine learning evolve, analyzing customer reviews will become increasingly advanced, offering deeper insights and predictive capabilities.  Predictive analytics will allow you to anticipate trends in customer preferences, enabling you to innovate before your competitors. At <a href='https://restaverse.com/services/know-more' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Restaverse,</a> we utilize these cutting-edge technologies to deliver actionable insights and forecast future trends, ensuring your restaurant stays ahead of the curve. Get in touch with us today to see how our solutions can help you innovate and lead in the ever-evolving restaurant industry!

                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogtweleve;