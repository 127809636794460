import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import './footer.css'
import LinkedIn from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export default function Footern() {
  return (
    <MDBFooter
      bgColor=""
      className="text-center text-lg-start text-muted"
      style={{ fontFamily: "'Sen', sans-serif" }}
    >
      <section className="border-bottom"></section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6
                className="mb-4 footer-subheaders"
                style={{
                  fontFamily: "'BC Novatica CYR Regular', sans-serif",
                  fontSize: "1.6rem",
                  color: "white",
                }}
              >
                restaverse
              </h6>

              <p>
                Growth Partner for Restaurants - We help restaurants manage,
                operate and grow their business on aggregator platforms!
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="fw-bold mb-4" style={{ color: "white" }}>
                Learn More
              </h6>
              <p>
                <a
                  href="/services/know-more"
                  className="text-reset"
                >
                  Services
                </a>
              </p>
              <p>
                <a href="/policies/privacy-policy" className="text-reset">
                  Policies
                </a>
              </p>
              <p>
                <a
                  href="/case-studies/Persian-Darbar/"
                  className="text-reset"
                >
                  Case Study
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="fw-bold mb-4" style={{ color: "white" }}>
                Company
              </h6>
              <p>
                <a href="/about-us/our-team" className="text-reset">
                  Our Team
                </a>
              </p>
              <p>
                <a href="/about-us/join-us" className="text-reset">
                  Join Us
                </a>
              </p>
              <p>
                <a href="/contact-us" className="text-reset">
                  Contact Us
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="fw-bold mb-4" style={{ color: "white" }}>
                Follow Us
              </h6>

              <div className="social-links">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/restaverse?originalSubdomain=in"
                  className="me-4 text-reset"
                >
                  <MDBIcon color="white" fab icon="linkedin" size="2x" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/restaverse.in/"
                  className="me-4 text-reset"
                >
                  <MDBIcon color="white" fab icon="instagram" size="2x" />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/people/Restaverse/100095145087012/"
                  className="text-reset"
                >
                  <MDBIcon color="white" fab icon="facebook-f" size="2x" />
                </a>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div
        className="text-center p-4"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
      >
        All rights are reserved copyright © 2024 Restaverse Pvt Ltd
        {/* <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
          restaverse.com
        </a> */}
      </div>
    </MDBFooter>
  );
}
