import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Avatar, LinearProgress, Button } from '@mui/material';
import signup from '../../images/blogs/Sign_up.png';
import { Link } from 'react-router-dom';
import ArticleCarousel from './articlecarousel';
import SocialMediaIcons from './socialmediabox';
import thirtyone from '../../images/blogs/31.png';
import thirtytwo from '../../images/blogs/32.png';
const Blogsixteen = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const scrollPosition = window.scrollY;
            const progress = (scrollPosition / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <LinearProgress
                variant="determinate"
                value={scrollProgress}
                sx={{
                    position: 'fixed',
                    top: {sm:65, xs:56},
                    left: 0,
                    width: '100%',
                    zIndex: 9999,
                    height: {sm:8, xs:14},
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0068b1',
                    },
                    backgroundColor: '#D3D3D3',

                }}
            />

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: {sm:2, xs:1},
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1100px',
                        width: '100%',
                        padding: 2
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'left',

                        }}

                    >

                        <Typography
                            color="black"
                            gutterBottom={true}
                            variant="h3"
                            component="p"
                            sx={{
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontWeight: 600,
                                color: "#213343",
                                fontSize:{xs:'35px', sm:'47px'}
                            }}
                        >
                            Seasonal Menus: How Limited-Time Offers Can Maximize Profits
                        </Typography>
                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "20px" }} />

                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Avatar
                                alt="Arsh Khanna"
                                sx={{ width: 46, height: 46, marginRight: '10px', fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: {sm:'center', xs:'left'}, flexDirection: {sm:'row', xs:'column'}, gap: {sm:87, xs: 2} }}>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', fontSize: '16px' }}><a href='https://www.linkedin.com/in/arshkhanna1/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Arsh Khanna</a></Typography>
                                <Typography variant="body2" sx={{ fontFamily: 'Lexend Deca, sans-serif', color: "#213343", fontWeight: 'bold', fontSize: '16px' }}>Published: Oct 10, 2024</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: '#0068b1', marginTop: "5px" }} />


                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100vh',
                            padding: {sm:2, xs:0},
                            color: "#213343",
                            fontSize: "1.125rem",
                            lineHeight: "1.77777778"
                        }}
                    >

                        <Box
                            sx={{
                                maxWidth: '700px',
                                width: '80%',
                                padding: {sm:2, xs:0},
                                fontFamily: 'Lexend Deca, sans-serif',
                                fontSize: "1.25rem",
                                lineHeight: "1.77777778",


                            }}
                        >

                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Imagine turning your restaurant into the talk of the town with dishes that customers can’t wait to try! Seasonal menus and limited-time offers (LTOs) are your ticket to creating a buzz that has people lining up to get a taste before it’s gone! With a fresh, ever-changing menu, you’ll not only keep diners on their toes but also open up new avenues for maximizing profits. By tapping into the allure of fleeting, special items, you can turn casual diners into loyal fans eager to savor what’s new before it’s gone.
                                <br></br>
                                Let’s explore how a well-crafted seasonal menu can make all the difference!
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    marginBottom: "10px",
                                }}
                            >
                                <img
                                    src={thirtyone}
                                    style={{
                                        width: '100%',
                                        maxWidth: '550px',
                                        height: 'auto',
                                    }}
                                    alt="Description of the image"
                                />
                            </Box>

                            <Typography
                                variant="body1"
                                sx={{
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    overflowWrap: "normal",
                                    color: "#213343",
                                    fontWeight: 300,

                                }}

                            >
                                <ul>
                                    <li style={{ listStyleType: 'disc' }}>
                                        <p><b style={{fontWeight:500}}>Maximize the use of locally sourced and seasonal ingredients</b> <br></br>
                                            Seasonal ingredients are not only fresher and more flavorful but also often more cost-effective. By aligning your <b style={{fontWeight:500}}>limited-time offers with the season's best produce, you can reduce costs and serve exceptional dishes.</b> For example, having hot chocolate and strawberries during winters enhances both the taste and profitability of your seasonal specials.
                                            <a href='https://www.foodiv.com/hottest-food-trends/' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>70% of customers</a> visiting your restaurant prefer to eat food prepared using local ingredients. Opting for locally sourced ingredients provides numerous advantages. It helps reduce your restaurant’s carbon footprint, supports local farmers and producers, and typically results in fresher, more flavorful dishes.
                                        </p>
                                    </li>
                                    <li style={{ listStyleType: 'disc' }}>
                                        <p><b style={{fontWeight:500}}>Encourage Culinary Experimentation</b> <br></br>
                                            Give your chefs the time they need to try out different recipes and find the best ones. <b style={{fontWeight:500}}>Keep your entire team informed and involved,</b> as servers must be well-versed in describing these new dishes enticingly to customers. Additionally, ensure that each new dish is accompanied by engaging and detailed descriptions in both your physical menus and on your website. Using descriptive words on your menu can <a href='https://www.usfoods.com/our-services/business-trends/the-science-of-menu-design.html' style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>boost sales by 27%.</a>These <b style={{fontWeight:500}}>descriptions</b> should vividly highlight the <b style={{fontWeight:500}}>dish's unique ingredients, flavors, and preparation methods, creating a mouthwatering image for diners.</b> Use vivid language to evoke the sensory experience of the dish, including its texture, taste, and aroma.
                                        </p>
                                    </li>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: '20px',
                                            marginBottom: "10px",
                                        }}
                                    >
                                        <img
                                            src={thirtytwo}
                                            style={{
                                                width: '100%',
                                                maxWidth: '550px',
                                                height: 'auto',
                                            }}
                                            alt="Description of the image"
                                        />
                                    </Box>
                                    <li style={{ listStyleType: 'disc' }}>
                                        <p><b style={{fontWeight:500}}>Test New Dishes and Assess Customer Reactions</b> <br></br>
                                        <b style={{fontWeight:500}}>Seasonal menu items allow for experimentation with minimal risk, as 65% of restaurants report successful trials of new dishes when offered temporarily. (Source: Restaurant Business Magazine, 2023).</b> Launching new items as part of your seasonal menu provides a valuable opportunity to experiment without the commitment of permanent changes. These limited-time dishes allow you to explore innovative flavors and combinations and gauge customer reactions.
                                            Track the performance of these seasonal items using customer reviews and sales data to shape your future menu choices. If a dish becomes a favorite, you can consider making it a permanent fixture or rotating it annually to keep your offerings exciting and attract returning customers.
                                        </p>
                                    </li>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '20px',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        <img src={signup} style={{
                                            width: '100%',
                                            maxWidth: '550px', 
                                            height: 'auto',    
                                        }} alt="Description of the image" />
                                        <Link to="/contact-us" style={{ textDecoration: 'underline', textDecorationColor: '#0068b1', textDecorationThickness: '2px', color: '#0068b1', fontWeight: 500 }}>Sign up for Restaverse</Link>
                                    </Box>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            marginTop: "5px",
                                            fontFamily: 'Lexend Deca, sans-serif',
                                            fontSize: "1.25rem",
                                            lineHeight: "1.77777778",
                                            color: "#213343",
                                            fontWeight: 300
                                        }}
                                    >
                                        Looking for expert insights on how to create effective seasonal menus and maximize profits? Contact Restaverse today to learn how we can help you optimize your offerings and stand out in the competitive restaurant landscape.
                                    </Typography>
                                    <li style={{ listStyleType: 'disc' }}>
                                        <p><b style={{fontWeight:500}}>Capitalize on Seasonal Celebrations and Themes</b> <br></br>
                                            Seasonal events and holidays present a fantastic opportunity to craft themed menus that engage your customers. For example, a Valentine’s Day special menu can attract diners seeking unique and festive experiences. <b style={{fontWeight:500}}>Limited-time offers (LTOs) tied to seasonal events can increase customer engagement by up to 30%. (Source: Restaurant Business Magazine, 2022)</b>
                                            Consider also tapping into niche occasions like Earth Day or local festivals. This strategy helps your restaurant stand out from the competition and draws in customers looking for distinctive dining experiences.

                                        </p>
                                    </li>
                                    <li style={{ listStyleType: 'disc' }}>
                                        <p><b style={{fontWeight:500}}>Leverage social media buzz</b> <br></br>
                                            Before launching your seasonal menu, it’s crucial to have a well-thought-out promotional strategy.  Effective tactics include showcasing promotional flyers and posters in your restaurant, <b style={{fontWeight:500}}>utilizing your social media platforms can increase engagement and drive traffic by up to 35% when promoting new menu items. (Source: Digital Restaurant Insights, 2023)</b>
                                            Attract your restaurant’s guests and social media followers to try the new seasonal offerings. These individuals are your most receptive audience, likely to visit specifically for the new items and share their experience with others.
                                        </p>
                                    </li>
                                    <li style={{ listStyleType: 'disc' }}>
                                        <p><b style={{fontWeight:500}}>Create Urgency</b> <br></br>
                                            Limited-time offers (LTOs) spark excitement by tapping into the fear of missing out. When customers realize a dish is available for only a short period, they’re motivated to act fast, driving more visits, especially during quieter seasons. This sense of exclusivity encourages customers to visit more often, eager to experience the dish before it disappears. It’s a simple yet effective way to boost sales and keep your restaurant buzzing with excitement. <b style={{fontWeight:500}}>The excitement generated by LTOs can encourage repeat visits, with up to 18% more customers returning to try new offers before they end. (Source: Digital Restaurant Insights, 2023)</b>
                                        </p>
                                    </li>
                                </ul>
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    marginTop: "5px",
                                    fontFamily: 'Lexend Deca, sans-serif',
                                    fontSize: "1.25rem",
                                    lineHeight: "1.77777778",
                                    color: "#213343",
                                    fontWeight: 300
                                }}
                            >
                                Implementing seasonal menus or limited-time offers can significantly enhance your restaurant's profitability by driving customer traffic, increasing spending, and creating buzz. These temporary yet timely menus are a dynamic way to keep your brand fresh, experiment with new dishes, and boost engagement both in-store and online.
                                Start planning your next seasonal menu today, and watch how a touch of seasonal magic can transform your restaurant’s success!
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <SocialMediaIcons />
            </Box>

            <Box
                sx={{
                    backgroundColor: "rgb(247, 245, 255)",
                    width: "100%",
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ArticleCarousel />
            </Box>


        </>

    )
};

export default Blogsixteen;