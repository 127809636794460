import React from 'react';
import { Card, CardMedia, CardContent, Typography, Box, Grid, Stack } from '@mui/material';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom Next Arrow
const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <FaChevronRight />
    </div>
  );
};

// Custom Previous Arrow
const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <FaChevronLeft />
    </div>
  );
};

const ArticleSection = ({ title, articles }) => {
  // Slick Carousel Settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,  // Display 3 articles at once on large screens
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: '80%', textAlign: 'center', marginTop: 5, marginBottom: '30px', maxWidth: '1100px', width: '100%' }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom sx={{
          fontSize: { sm: '24px', xs: '16px' },
          fontFamily: 'Lexend Deca, sans-serif'
        }}>
          {title}
        </Typography>
        <Box sx={{ flexGrow: 1, height: '3px', backgroundColor: 'rgb(108, 65, 255)' }} />
      </Stack>
      <Slider {...settings}>
        {articles.map((article, index) => (
          
            <Grid item key={index} sx={{ padding: { sm: '10px', xs: '30px', md: '30px' } }}>
              <a href={article.link} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Card
                sx={{
                  '&:hover': {
                    backgroundColor: '#EAF0F6',
                    '& .MuiCardContent-root, & .date-wrapper': {
                      backgroundColor: '#EAF0F6',
                    },
                    '& .article-title': {
                      textDecoration: 'underline', // Apply underline on hover
                      textDecorationColor: 'black',
                    },
                  },
                  height: '350px',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  backgroundColor: 'rgb(247, 245, 255)',
                  boxShadow: 'none'
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={article.imageUrl}
                  alt={article.title}
                  style={{ width: '100%' }}
                />
                <CardContent
                  sx={{
                    textAlign: 'left',
                    height: '100%',
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontFamily: 'Lexend Deca, sans-serif',
                      fontSize: '16px',
                      transition: 'all 0.3s ease',
                      marginLeft: '-10px'
                    }}
                    className="article-title"
                  >
                    {article.title}
                  </Typography>
                  <div
                    className="date-wrapper"
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      left: '10px',

                      backgroundColor: 'transparent',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Lexend Deca, sans-serif',
                        fontSize: '14px',
                        fontWeight: 300,
                        marginBottom: '10px'
                      }}
                      className="article-date"
                    >
                      {article.author}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Lexend Deca, sans-serif',
                        fontSize: '14px',
                        fontWeight: 300
                      }}
                      className="article-date"
                    >
                      {article.date}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
              </a>
            </Grid>
        ))}
          </Slider>
    </Box>
  );
};

export default ArticleSection;
