import PageTitle from "../../Components/PageTitle/pagetitles";
import React from "react";
import Grid from "@mui/material/Grid";
import useTheme from "@mui/system/useTheme";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  faPlay,
  faHouseSignal,
  faHouseLock,
  faMobile,
  faHeadphones,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  faAward,
  faLayerGroup,
  faRectangleAd,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import CardContent from "@mui/material/CardContent";
import TestimonialsGrid from "../../Components/Home/Testimonials/testimonials";
import "./home.css";
import IndustryLeaders from "../../Components/Home/Testimonials/IndustryLeaders/industryleaders";
import HomeSection4 from "../../Components/Home/HomeSection4/HomeSection4";
import { useEffect } from "react";
import SuceessStory from "../../Components/SuccessStory/successstory";

import pioneer from "../../images/home/Pioneering.webp";
import pioneer1 from "../../images/home/Pioneering.png";
import home1 from "../../images/home/Homepage1.webp";
import home2 from "../../images/home/Homepage2.webp";
import googleLogo from "../../images/home/google.png";
import facebookLogo from "../../images/home/facebook.png";
import instaLogo from "../../images/home/insta.png";
import petpoojaLogo from "../../images/home/petpooja.svg";
import ristaLogog from "../../images/home/rista.png";
import zomatoLogo from "../../images/home/zomato.png";
import swiggyLogo from "../../images/home/swiggy.png";
import { styled } from "@mui/system";
import InfiniteSlider from "../InfiniteSlider/infiniteslider";

export const StyledButton = styled(Button)({
  backgroundColor: "#6C41FF",
  variant: "contained",
  // fontWeight: "bold",
  padding: "15px 25px", // Adjust the padding values as needed
  color: "white",
  fontFamily: "Poppins , sans-serif",
  textTransform: "none",
  width: "98%", // Default width
  "&:hover": {
    backgroundColor: "#6C41FF", // Set it to the same color as the default state
  },

  "@media (min-width: 600px)": {
    width: "auto", // Reset width to 'auto' for screens wider than 600px
  },
});

const HomeCard = ({ title, servicelogo, description, isSmallScreen }) => {
  const cardStyle = {
    padding: "16px",
    borderRadius: "12px",
    boxShadow: "none",
    backgroundColor: "#F7F5FF",
    transition: "background-color 0.3s ease-in-out",
    ...(isSmallScreen && { padding: "0px" }),
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card style={cardStyle}>
        <CardContent style={{ ...(isSmallScreen && { padding: "0px" }) }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <FontAwesomeIcon
                icon={servicelogo}
                aria-hidden="true"
                style={{
                  fontSize: "2rem",
                  color: "white",
                  width: "40px",
                  height: "40px",
                  padding: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#6c41ff",
                }}
              />
            </Grid>
          </Grid>
          <br></br>
          <Typography
            variant="h6"
            component="div"
            align="center"
            style={{
              paddingBottom: "8px",
              fontWeight: "bold",
              fontSize: "1.7rem",
              fontFamily: "Poppins , sans-serif",
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body1"
            paragraph
            align="centre"
            style={{
              paddingBottom: "16px",
              color: "#506690",
              fontFamily: "Poppins , sans-serif",
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Home2 = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <div className="services-title pt-4">
        <Container
          component="main"
          maxWidth="lg"
          style={{
            display: "flex",
            alignItems: "center",
            height: isSmallScreen ? "auto" : "90vh",
            padding: `0 ${isSmallScreen ? theme.spacing(2) : theme.spacing(2)}`,
            position: "relative",
            ...(isSmallScreen && { paddingBottom: "30px" }),
          }}
        >
          <Grid
            container
            spacing={3}
            style={{
              width: isSmallScreen ? "auto" : "100%",
              transform: "scale(0.95)",
            }}
          >
            <Grid item xs={12} md={isMediumScreen ? 12 : 6}>
              <div
                style={{
                  paddingRight: isSmallScreen
                    ? theme.spacing(0)
                    : theme.spacing(4),
                }}
              >
                <Typography
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: theme.spacing(3),
                    fontFamily: "Poppins , sans-serif",
                    width: "fit-content",
                    fontSize: isSmallScreen ? "2rem" : "4.5rem",
                  }}
                  variant={isSmallScreen ? "h4" : "h2"}
                >
                  Growth Partner for Restaurants
                </Typography>
                <Typography
                  variant="h5"
                  paragraph
                  style={{
                    color: "white",
                    marginBottom: theme.spacing(4),
                    fontFamily: "Poppins , sans-serif",
                  }}
                >
                  <div
                    className="flipper-container-parent"
                  // style={{ textAlign: "left" }}
                  >
                    <div
                      className="flipper-container"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        // alignItems: "flex-start",
                        // alignItems: "left",
                        // width: "90%",
                      }}
                    >
                      <p className="text-sm mr-4 mb-0">
                        You focus on the operations, leave the
                      </p>
                      <br></br>
                      <div
                        className="cubespinner animate-flipper Al pb-2"
                        style={{
                          fontSize: "1.25rem",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            //
                            width: "100%",
                          }}
                        >
                          aggregator management to us
                        </div>
                        <div
                          style={{
                            marginRight: "auto",
                          }}
                        >
                          platform management to us
                        </div>
                        <div
                          style={{
                            marginRight: "auto",
                          }}
                        >
                          customer acquisition costs to us
                        </div>
                        <div
                          style={{
                            marginRight: "auto",
                          }}
                        >

                        </div>
                        <div
                          style={{
                            marginRight: "auto",
                          }}
                        >
                          online reputation management to us
                        </div>
                        <div
                          style={{
                            marginRight: "auto",
                          }}
                        >
                          aggregator management to us
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/contact-us"
                  style={{ color: "white" }}
                >
                  Join Waitlist
                </StyledButton>
              </div>
            </Grid>

            {!isMediumScreen && (
              <Grid item xs={12} md={6}>
                <div
                  style={{
                    // overflow: "hidden",
                    borderRadius: "40px 80px 40px 80px",
                    // backgroundColor: "#6C41FF",
                    position: "relative",
                    zIndex: 1,
                    padding: theme.spacing(4),
                  }}
                >
                  <img
                    // src="https://templatekit.tokomoo.com/providerkit/wp-content/uploads/sites/37/2021/11/handsome-black-guy-having-video-call-using-laptop-2021-10-30-17-47-11-utc.png"
                    src={home1}
                    alt="Your Image"
                    style={{
                      width: "100%",
                      height: "auto",
                      transform: "scale(1.5)",
                      marginTop: "1%",
                      borderRadius: "40px 80px 40px 80px",
                    }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>

      <div>
        <section className="career-body homesection2">
          <div className="container-lg">
            {/* <div className="row"> */}
            <div className="text-center">
              <div className="career-section3-cards">
                <Container>
                  <Grid container spacing={0}>
                    {[
                      {
                        title: (
                          <>
                            Visual <br /> Analytics
                          </>
                        ),
                        servicelogo: faChartPie,
                        description:
                          "Transform boring excels into visual insights",
                      },
                      {
                        title: "Reputation Management",
                        servicelogo: faAward,
                        description:
                          "Manage and analyse your reviews with us",
                      },
                      {
                        title: "Aggregator Management",
                        servicelogo: faLayerGroup,
                        description:
                          "Streamline operations with a centralised dashboard",
                      },
                      {
                        title: "Ads Management",
                        servicelogo: faRectangleAd,
                        description:
                          "Attract customers with targeted promotions and smart advertising.",
                      },
                    ].map((job, index) => (
                      <HomeCard
                        key={index}
                        {...job}
                        isSmallScreen={isSmallScreen}
                      />
                    ))}
                  </Grid>
                </Container>
              </div>
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
      <div
        className="homeindusrtyleaders"
        style={{ backgroundColor: "#F7F5FF" }}
      >
        <div>
          <IndustryLeaders />
          {/* <InfiniteSlider/> */}
        </div>
      </div>
      <div>
        <section
          className="homesection4"
          style={{
            backgroundColor: "#F7F5FF",
            paddingTop: isSmallScreen ? "15%" : "0%",
          }}
        >
          <div className="container-lg">
            <HomeSection4 />
          </div>
          <br></br>
        </section>
      </div>

      <div style={{ background: "#F7F5FF" }} className="hometestimonialgrid">
        <TestimonialsGrid theme={theme} />
      </div>
      <div className="container-lg">
        <SuceessStory
          titletext="Explore our success stories"
          desctext="Dive into the narratives of innovation and triumph with Restaverse's
          detailed case studies."
          buttontext="Discover More"
          redirect="/case-studies/Persian-Darbar/"
        />
      </div>
    </div>
  );
};

export default Home2;